import React from 'react'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

const styles = (theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 20,
  },
})
const ButtonIcon = ({ classes, icon: Icon, size, children, ...rest }) => {
  const buttonWidth =
    size === 'small' ? '8rem' : size === 'large' ? '24rem' : size === 'medium' ? '16rem' : 'auto'
  const StyledButton = styled(Button)`
    && {
      width: ${buttonWidth};
      font-weight: 600;
      font-size: 1rem;
      text-transform: none;
      margin: 0.1rem 1rem;
    }
  `
  return (
    <StyledButton variant="contained" color="primary" size={size} {...rest}>
      <Icon className={classes.icon} />
      {children}
    </StyledButton>
  )
}

export default withStyles(styles)(ButtonIcon)
