import React from 'react'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import styled from 'styled-components'
import { FlexDiv } from 'components/common'
import { FormControl } from '@material-ui/core'
import { FormLabel } from '@material-ui/core'
import { FormGroup } from '@material-ui/core'
import { injectIntl } from 'react-intl'

const FilterDialog = ({ filters, intl, onApply, onCancel, onFilterChange, style }) => {
  return (
    <ArrowBox id="arrow_box">
      <FlexDivBordered
        id="filers_dialog"
        direction="column"
        align="start"
        style={{ ...style }} //extends style to enable hiding component
      >
        <FlexDiv id="filters_selection">
          <StyledTable width="100%">
            <tbody>
              <tr>
                <td>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Show activity</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.activity.act1}
                            onChange={onFilterChange({ group: 'activity', name: 'act1' })}
                            value="act1"
                          />
                        }
                        label="Last 7 days"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.activity.act2}
                            onChange={onFilterChange({ group: 'activity', name: 'act2' })}
                            value="act2"
                          />
                        }
                        label="Last 30 days"
                      />
                      {/* <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={filters.activity.act3}
                          onChange={onFilterChange({ group: 'activity', name: 'act3' })}
                          value="act3"
                        />
                      }
                      label="From"
                    /> */}
                    </FormGroup>
                  </FormControl>
                </td>
                <td>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Status</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.status.sta1}
                            onChange={onFilterChange({ group: 'status', name: 'sta1' })}
                            value="sta1"
                          />
                        }
                        label="Pending for review"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.status.sta2}
                            onChange={onFilterChange({ group: 'status', name: 'sta2' })}
                            value="sta2"
                          />
                        }
                        label="Pending for assignment"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.status.sta3}
                            onChange={onFilterChange({ group: 'status', name: 'sta3' })}
                            value="sta3"
                          />
                        }
                        label="Assigned"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.status.sta4}
                            onChange={onFilterChange({ group: 'status', name: 'sta4' })}
                            value="sta4"
                          />
                        }
                        label="Closed"
                      />
                    </FormGroup>
                  </FormControl>
                </td>
                <td>
                  <FormControl component="fieldset">
                    <FormLabel component="strategy">Strategy</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.strategy.accept}
                            onChange={onFilterChange({
                              group: 'strategy',
                              name: 'accept',
                            })}
                            value="accept"
                          />
                        }
                        label="Accept"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.strategy.avoid}
                            onChange={onFilterChange({
                              group: 'strategy',
                              name: 'avoid',
                            })}
                            value="avoid"
                          />
                        }
                        label="Avoid"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.strategy.transfer}
                            onChange={onFilterChange({ group: 'strategy', name: 'transfer' })}
                            value="transfer"
                          />
                        }
                        label="Transfer"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.strategy.reduce}
                            onChange={onFilterChange({ group: 'strategy', name: 'reduce' })}
                            value="reduce"
                          />
                        }
                        label="Reduce"
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.strategy.exploit}
                            onChange={onFilterChange({ group: 'strategy', name: 'exploit' })}
                            value="exploit"
                          />
                        }
                        label="Exploit"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.strategy.share}
                            onChange={onFilterChange({ group: 'strategy', name: 'share' })}
                            value="share"
                          />
                        }
                        label="Share"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.strategy.enhance}
                            onChange={onFilterChange({ group: 'strategy', name: 'enhance' })}
                            value="enhance"
                          />
                        }
                        label="Enhance"
                      />
                    </FormGroup>
                  </FormControl>
                </td>

                {/* <td>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Rejected</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.status.sta5}
                            onChange={onFilterChange({ group: 'status', name: 'sta5' })}
                            value="sta5"
                          />
                        }
                        label="Yes"
                      />
                    </FormGroup>
                  </FormControl>
                </td> */}
              </tr>
              <tr>
                <td>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Impact on schedule</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.impact1.low}
                            onChange={onFilterChange({ group: 'impact1', name: 'low' })}
                            value="low"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.imp.schedule.item.label.imp11',
                        })}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.impact1.medium}
                            onChange={onFilterChange({
                              group: 'impact1',
                              name: 'medium',
                            })}
                            value="medium"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.imp.schedule.item.label.imp12',
                        })}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.impact1.high}
                            onChange={onFilterChange({ group: 'impact1', name: 'high' })}
                            value="high"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.imp.schedule.item.label.imp13',
                        })}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.impact1.vhigh}
                            onChange={onFilterChange({ group: 'impact1', name: 'vhigh' })}
                            value="vhigh"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.imp.schedule.item.label.imp14',
                        })}
                      />
                    </FormGroup>
                  </FormControl>
                </td>
                <td>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Impact on costs</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.impact2.low}
                            onChange={onFilterChange({ group: 'impact2', name: 'low' })}
                            value="low"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.imp.costs.item.label.imp21',
                        })}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.impact2.medium}
                            onChange={onFilterChange({ group: 'impact2', name: 'medium' })}
                            value="medium"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.imp.costs.item.label.imp22',
                        })}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.impact2.high}
                            onChange={onFilterChange({ group: 'impact2', name: 'high' })}
                            value="high"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.imp.costs.item.label.imp23',
                        })}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.impact2.vhigh}
                            onChange={onFilterChange({ group: 'impact2', name: 'vhigh' })}
                            value="vhigh"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.imp.costs.item.label.imp24',
                        })}
                      />
                    </FormGroup>
                  </FormControl>
                </td>
                <td>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Impact on quality</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.impact3.low}
                            onChange={onFilterChange({
                              group: 'impact3',
                              name: 'low',
                            })}
                            value="low"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.imp.quality.item.label.imp31',
                        })}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.impact3.medium}
                            onChange={onFilterChange({
                              group: 'impact3',
                              name: 'medium',
                            })}
                            value="medium"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.imp.quality.item.label.imp32',
                        })}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.impact3.high}
                            onChange={onFilterChange({
                              group: 'impact3',
                              name: 'high',
                            })}
                            value="high"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.imp.quality.item.label.imp33',
                        })}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.impact3.vhigh}
                            onChange={onFilterChange({
                              group: 'impact3',
                              name: 'vhigh',
                            })}
                            value="vhigh"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.imp.quality.item.label.imp34',
                        })}
                      />
                    </FormGroup>
                  </FormControl>
                </td>
                <td>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Impact on HSSE</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.impact4.low}
                            onChange={onFilterChange({
                              group: 'impact4',
                              name: 'low',
                            })}
                            value="low"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.imp.hsse.item.label.imp41',
                        })}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.impact4.medium}
                            onChange={onFilterChange({
                              group: 'impact4',
                              name: 'medium',
                            })}
                            value="medium"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.imp.hsse.item.label.imp42',
                        })}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.impact4.high}
                            onChange={onFilterChange({
                              group: 'impact4',
                              name: 'high',
                            })}
                            value="high"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.imp.hsse.item.label.imp43',
                        })}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.impact4.vhigh}
                            onChange={onFilterChange({
                              group: 'impact4',
                              name: 'vhigh',
                            })}
                            value="vhigh"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.imp.hsse.item.label.imp44',
                        })}
                      />
                    </FormGroup>
                  </FormControl>
                </td>

                <td>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Probability of occurence</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.occurence.low}
                            onChange={onFilterChange({
                              group: 'occurence',
                              name: 'low',
                            })}
                            value="low"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.occ.item.label.o1',
                        })}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.occurence.medium}
                            onChange={onFilterChange({
                              group: 'occurence',
                              name: 'medium',
                            })}
                            value="medium"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.occ.item.label.o2',
                        })}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.occurence.high}
                            onChange={onFilterChange({
                              group: 'occurence',
                              name: 'high',
                            })}
                            value="high"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.occ.item.label.o3',
                        })}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.occurence.vhigh}
                            onChange={onFilterChange({
                              group: 'occurence',
                              name: 'vhigh',
                            })}
                            value="vhigh"
                          />
                        }
                        label={intl.formatMessage({
                          id: 'utc.occ.item.label.o4',
                        })}
                      />
                    </FormGroup>
                  </FormControl>
                </td>
              </tr>
            </tbody>
          </StyledTable>
        </FlexDiv>
        <FlexDiv id="filters_footer" alignSelf="flex-end">
          <StyledButton color="primary" onClick={onApply}>
            Apply filters
          </StyledButton>
          <StyledButton onClick={onCancel}>Cancel filters</StyledButton>
        </FlexDiv>
      </FlexDivBordered>
    </ArrowBox>
  )
}

const StyledButton = styled(Button)`
  && {
    width: 'auto';
    font-weight: 600;
    font-size: 1rem;
    text-transform: none;
    margin: 0.5rem 1rem;
  }
`
const FlexDivBordered = styled(FlexDiv)`
  padding: 1rem;
`
const StyledTable = styled.table`
  border-collapse: collapse;
  td {
    padding: 1rem;
    text-align: left;
    vertical-align: top;
  }
`

const ArrowBox = styled.div`
  position: relative;
  border: 1px solid #bdc3c7;
  border-radius: 2px;

  &::after,
  ::before {
    bottom: 100%;
    left: 5%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-bottom-color: white;
    border-width: 12px;
    margin-left: -12px;
  }
  &::before {
    border-bottom-color: #bdc3c7;
    border-width: 13px;
    margin-left: -13px;
  }
`
export default injectIntl(FilterDialog)
