import React from 'react'
import { Button } from '@material-ui/core'
import { AddCircleOutline } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const DeclareButton = props => {
  const StyledButton = styled(Button)`
    && {
      width: 16rem;
      font-weight: 600;
      font-size: 1rem;
      text-transform: none;
    }
  `
  return (
    <StyledButton variant="contained" color="primary" component={Link} to="/declare">
      <AddCircleOutline style={{ marginRight: '0.5rem', fontSize: 20 }} />
      Declare an uncertainty
    </StyledButton>
  )
}

export default DeclareButton
