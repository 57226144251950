import axios from 'axios'
import { push } from 'connected-react-router'
import { change, formValueSelector } from 'redux-form'

/** UNCERTAINTIES (UCTS/UCT) */
const FETCH_UCTS = 'FETCH_UCTS'
const FETCH_UCTS_SUCCESS = 'FETCH_UCTS_SUCCESS'
const FETCH_UCTS_FAILURE = 'FETCH_UCTS_FAILURE'

const FETCH_UCT = 'FETCH_UCT'
const FETCH_UCT_SUCCESS = 'FETCH_UCT_SUCCESS'
const FETCH_UCT_FAILURE = 'FETCH_UCT_FAILURE'

const SAVE_UCT_SUCCESS = 'SAVE_UCT_SUCCESS'
const SAVE_UCT_FAILURE = 'SAVE_UCT_FAILURE'

const DECLARE_UCT = 'DECLARE_UCT'
const DECLARE_UCT_SUCCESS = 'DECLARE_UCT_SUCCESS'
const DECLARE_UCT_FAILURE = 'DECLARE_UCT_FAILURE'

const UPLOAD_DOC = 'UPLOAD_DOC'
const UPLOAD_DOC_SUCCESS = 'UPLOAD_DOC_SUCCESS'
const UPLOAD_DOC_FAILURE = 'UPLOAD_DOC_FAILURE'

const initialState = {
  data: [],
  loading: false,
}

export default function uncertaintiesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_UCT:
    case FETCH_UCTS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_UCTS_SUCCESS:
      return {
        ...state,
        data: action.result.data,
        loading: false,
      }
    case FETCH_UCTS_FAILURE:
    case DECLARE_UCT_SUCCESS:
    case SAVE_UCT_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

const ROOT_URL = process.env.REACT_APP_API_URL

export function fetchUncertainties() {
  return {
    types: [FETCH_UCTS, FETCH_UCTS_SUCCESS, FETCH_UCTS_FAILURE],
    promise: client => client.get(`${ROOT_URL}/uncertainties/`),
  }
}

export function fetchUncertainty(id) {
  return {
    types: [FETCH_UCT, FETCH_UCT_SUCCESS, FETCH_UCT_FAILURE],
    promise: client => client.get(`${ROOT_URL}/uncertainties/${id}`),
  }
}

export function declareUncertainty(values) {
  return {
    types: [DECLARE_UCT, DECLARE_UCT_SUCCESS, DECLARE_UCT_FAILURE],
    promise: client => client.post(`${ROOT_URL}/uncertainties/declare`, values),
    afterSuccess: dispatch => dispatch(push('/confirm')),
  }
}

export function saveUncertainty(values) {
  return function(dispatch, getState) {
    const {
      form: {
        DeclareForm: { values },
      },
    } = getState()
    axios
      .post(`${ROOT_URL}/uncertainties/save`, values)
      .then(response => {
        dispatch({ type: SAVE_UCT_SUCCESS, payload: response.data })
        dispatch(push('/dashboard'))
      })
      .catch(error => {
        dispatch({ type: SAVE_UCT_FAILURE, payload: error })
      })
  }
}

export function uploadDocument(file) {
  return {
    types: [UPLOAD_DOC, UPLOAD_DOC_SUCCESS, UPLOAD_DOC_FAILURE],
    promise: client => {
      const data = new FormData()
      data.append('file', file)
      return client.put(`${ROOT_URL}/uncertainties/document`, data)
    },
    afterSuccess: (dispatch, getState, result) => {
      const documents = formValueSelector('DeclareForm')(getState(), 'documents')
      documents.push(result.data)
      dispatch(change('DeclareForm', 'documents', [...documents]))
    },
  }
}

/* unused at the moment */
// const DELETE_DOC = 'DELETE_DOC'
// const DELETE_DOC_SUCCESS = 'DELETE_DOC_SUCCESS'
// const DELETE_DOC_FAILURE = 'DELETE_DOC_FAILURE'

// export function removeDocument(doc) {
//   return {
//     types: [DELETE_DOC, DELETE_DOC_SUCCESS, DELETE_DOC_FAILURE],
//     promise: client => client.delete(`${ROOT_URL}/uncertainties/document/${doc.id}`),
//     afterSuccess: (dispatch, getState, result) => {
//       console.warn(result)
//       const documents = formValueSelector('DeclareForm')(getState(), 'documents')
//       documents.splice(documents.indexOf(doc), 1)
//       console.warn('documents:')
//       console.warn(documents)
//       dispatch(change('DeclareForm', 'documents', [...documents]))
//     },
//   }
// }

export const actions = {
  fetchUncertainties,
  declareUncertainty,
  saveUncertainty,
  uploadDocument,
  // removeDocument,
}
