import React, { useEffect, useState } from 'react'
import { Pie } from 'react-chartjs-2'

import {
  Grid,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
} from '@material-ui/core'
import { scoreColors } from 'utils/constants'
import { DateTime } from 'luxon'
import MatrixLegend from '../MatrixLegend'
import MatrixTable from '../Matrix/MatrixTable'
import { fetchWeeklyReportStats } from 'services/api'
import StatBloc from '../StatBloc.synergy'
import WeeklyReportTable from './WeeklyReportTable'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  outlinedInput: {
    padding: '5px 10px',
    minWidth: 150,
  },
  select: {
    width: 300,
  },
}))

const generateSelectableWeeks = () => {
  const weeks = []
  for (let i = 18; i >= 1; i--) {
    weeks.push(DateTime.utc().minus({ week: i }))
  }
  return weeks
}

export default () => {
  const classes = useStyles()

  const [pieData, setPieData] = useState({ label: [], datasets: [] })
  const [currentWeekData, setCurrentWeekData] = useState({})
  const [previousWeekData, setPreviousWeekData] = useState({})
  const [selectableWeeks] = useState(generateSelectableWeeks())
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(selectableWeeks.length - 1)

  useEffect(() => {
    const selectedWeekDate = selectableWeeks[selectedWeekIndex]
    ;(async () => {
      try {
        const { data } = await fetchWeeklyReportStats(
          selectedWeekDate.startOf('week').toISODate(),
          selectedWeekDate.endOf('week').toISODate()
        )
        setCurrentWeekData({
          ...data.current,
          uncertainties: data.declarations,
          weights: data.weights,
        })
        setPreviousWeekData(data.previous)
        const dataForPie = {
          labels: ['critical', 'moderate', 'low'],
          datasets: [
            {
              data: [data.current.criticalCount, data.current.moderateCount, data.current.lowCount],
              backgroundColor: [scoreColors.critical, scoreColors.moderate, scoreColors.low],
              hoverOffset: 8,
            },
          ],
        }
        setPieData(dataForPie)
      } catch (e) {}
    })()
  }, [selectableWeeks, selectedWeekIndex])

  const handleChangeSelectedWeek = (evt) => {
    const selectedWeek = evt.target.value
    setSelectedWeekIndex(selectedWeek)
  }

  const generateSelectableWeeksItems = () => {
    return selectableWeeks.map((w, idx) => (
      <MenuItem value={idx} key={idx}>{`Week ${w.weekNumber} (${w.startOf('week').toISODate()} - ${w
        .endOf('week')
        .toISODate()})`}</MenuItem>
    ))
  }

  return (
    <Grid container item xs={10} justifyContent="space-around" spacing={2}>
      <Grid container item alignItems="baseline" xs={12} spacing={2}>
        <Grid item>
          <Typography variant="h6">Weekly Risk Management indicators</Typography>
        </Grid>
        <Grid item>
          <Select
            value={selectedWeekIndex}
            onChange={handleChangeSelectedWeek}
            className={classes.select}
            input={
              <OutlinedInput
                id="period-selector"
                notched={false}
                classes={{ input: classes.outlinedInput }}
              />
            }
          >
            {generateSelectableWeeksItems()}
          </Select>
        </Grid>
      </Grid>
      <Grid item container xs={12} md={6} spacing={2}>
        <Grid item xs={6}>
          <StatBloc
            currentData={currentWeekData.declarations}
            previousData={previousWeekData.declarations}
            title="Uncertainties declared"
          />
        </Grid>
        <Grid item xs={6}>
          <StatBloc
            currentData={currentWeekData.declarationsClosed}
            previousData={previousWeekData.declarationsClosed}
            title="Uncertainties closed"
          />
        </Grid>
        <Grid item xs={6}>
          <StatBloc
            currentData={currentWeekData.closedTasksCount}
            previousData={previousWeekData.closedTasksCount}
            title="Tasks Completed"
          />
        </Grid>
        <Grid item xs={6}>
          <StatBloc
            currentData={currentWeekData.averageCriticality}
            previousData={previousWeekData.averageCriticality}
            title="Average criticality"
            postAdornment="%"
          />
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Paper>
          <Pie
            data={pieData}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              aspectRatio: 1.5,
              plugins: {
                legend: {
                  position: 'right',
                },
                labels: {
                  // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
                  render: 'full',
                  fontSize: 14,
                  fontColor: '#fff',
                  fontStyle: 'bold',
                },
              },
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container justifyContent="space-around" spacing={4}>
            <Grid item xs={10}>
              <Typography variant="h6">selected overview of declared uncertainties</Typography>
            </Grid>
            <Grid item>
              <MatrixTable data={currentWeekData} />
            </Grid>
            <Grid item>
              <MatrixLegend data={currentWeekData} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">New uncertainties declared - Details</Typography>
            </Grid>
            <Grid item xs={12}>
              <WeeklyReportTable uncertainties={currentWeekData.uncertainties} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
