import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  withStyles,
} from '@material-ui/core'

import { FlexDiv } from 'components/common'

function generateListItems(invalidLines) {
  return invalidLines.map(({ line, lineNumber, reason }, index) => (
    <ListItem key={index}>
      <ListItemText primary={line} secondary={`Line number: ${lineNumber} - ${reason}`} />
    </ListItem>
  ))
}

const UsersImportResultDialog = ({ open, onClose, data, classes }) => {
  const { originalFileName, totalNumberOflines, invalidLines } = data
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      id="users-import-result"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="users-import-result-dialog-title">Import summary</DialogTitle>
      <DialogContent>
        <FlexDiv margins="10px">File processed: {originalFileName}</FlexDiv>
        <FlexDiv margins="10px">Total line(s) processed: {totalNumberOflines}</FlexDiv>
        {invalidLines && invalidLines.length > 0 && (
          <FlexDiv margins="10px" direction="column" align="start">
            The {invalidLines.length} following line(s) were not successfully imported:
            <List className={classes.root} disablePadding={true} dense={true}>
              {generateListItems(invalidLines)}
            </List>
          </FlexDiv>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '960px',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: '400px',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
})

export default withStyles(styles)(UsersImportResultDialog)
