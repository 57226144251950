import React from 'react'
import { teal } from '@material-ui/core/colors'
import { FlexDiv } from 'components/common'

const P_REVIEW = { name: 'PENDING_REVIEW', score: 1 }
const P_ASSIGNMENT = { name: 'PENDING_ASSIGNMENT', score: 2 }
const ASSIGNED = { name: 'ASSIGNED', score: 3 }
const CLOSED = { name: 'CLOSED', score: 4 }
const REJECTED = { name: 'REJECTED', score: 5 }

const STATUS_ARRAY = [P_REVIEW, P_ASSIGNMENT, ASSIGNED, CLOSED, REJECTED]

function backgroundColor(barSectionStatus, currentStatusScore) {
  if (currentStatusScore > barSectionStatus.score) {
    return '#2980b9'
  } else if (currentStatusScore === barSectionStatus.score) {
    return teal['A400']
  } else {
    return '#eeeeee'
  }
}

function fontColor(barSectionStatus, currentStatusScore) {
  if (currentStatusScore < barSectionStatus.score) {
    return '#6e6e6e'
  } else {
    return 'white'
  }
}

function getStatusScore(statusName) {
  const status = STATUS_ARRAY.find(el => el.name === statusName)
  if (status) {
    return status.score
  } else {
    return 0
  }
}

class StatusBar extends React.PureComponent {
  render() {
    const currentStatusScore = getStatusScore(this.props.status)
    return (
      <FlexDiv justifyContent="center">
        <StatusBarStart
          text="Pending for Review"
          textColor={fontColor(P_REVIEW, currentStatusScore)}
          background={backgroundColor(P_REVIEW, currentStatusScore)}
          style={styles.start}
        />
        <StatusBarMid
          text="Pending for Assignment"
          textColor={fontColor(P_ASSIGNMENT, currentStatusScore)}
          background={backgroundColor(P_ASSIGNMENT, currentStatusScore)}
          style={styles.mid}
        />
        <StatusBarMid
          text="Assigned"
          textColor={fontColor(ASSIGNED, currentStatusScore)}
          background={backgroundColor(ASSIGNED, currentStatusScore)}
          style={styles.mid}
        />
        <StatusBarEnd
          text="Closed"
          textColor={fontColor(CLOSED, currentStatusScore)}
          background={backgroundColor(CLOSED, currentStatusScore)}
          style={styles.end}
        />
      </FlexDiv>
    )
  }
}

const styles = {
  start: {
    // marginLeft: '1rem',
    fontWeight: '600',
  },
  mid: {
    marginLeft: '-10px',
    fontWeight: '600',
  },
  end: {
    marginLeft: '-13px',
    fontWeight: '600',
  },
}

const StatusBarMid = ({ background, text, textColor, ...rest }) => (
  <svg height={40} width={240} viewBox="0 0 245 40" {...rest}>
    <g fillRule="evenodd">
      <path d="M4.996 0h220l20 20-20 20h-220l20-20z" fill="#fff" />
      <path d="M-.004 0h220l20 20-20 20h-220l20-20z" fill={background} />
      <text
        style={{ textAlign: 'center' }}
        fontSize={14}
        y={25}
        x={120}
        fill={textColor}
        fontFamily="'Open Sans'"
        textAnchor="middle"
      >
        {text}
      </text>
    </g>
  </svg>
)

const StatusBarStart = ({ background, text, textColor, ...rest }) => (
  <svg height={40} width={240} viewBox="0 0 245 40" {...rest}>
    <path
      d="M5 20C4.847.252 25 0 25 0h200l20 20-20 20H25s-19.843.252-20-20z"
      fillRule="evenodd"
      fill="#fff"
    />
    <path
      d="M0 20C-.153.252 20 0 20 0h200l20 20-20 20H20S.157 40.252 0 20z"
      fillRule="evenodd"
      fill={background}
    />
    <text
      style={{ textAlign: 'center' }}
      fontSize={14}
      y={25}
      x={100}
      fill={textColor}
      fontFamily="'Open Sans'"
      textAnchor="middle"
    >
      {text}
    </text>
  </svg>
)

const StatusBarEnd = ({ background, text, textColor, ...rest }) => (
  <svg height={40} width={240} viewBox="0 0 235 40" {...rest}>
    <path
      d="M225 20c.156-19.748-20.417-20-20.417-20H.003l20 20-20 20h204.58s20.257.252 20.417-20z"
      fillRule="evenodd"
      fill={background}
    />
    {/* <path
      d="M245 20c.156-19.748-20.417-20-20.417-20H.003l19.963 20L.003 40h224.58s20.257.252 20.417-20z"
      fillRule="evenodd"
      fill="#969696"
    /> */}
    <text
      style={{ textAlign: 'center' }}
      fontSize={14}
      y={25}
      x={100}
      fill={textColor}
      fontFamily="'Open Sans'"
      textAnchor="middle"
    >
      {text}
    </text>
  </svg>
)

export default StatusBar
