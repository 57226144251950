import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { DeclareButton, FlexDiv } from 'components/common'
import DoughnutChart from '../common/Doughnut/DoughnutChart'
import DoughnutLegend from '../common/Doughnut/DoughnutLegend'
import Contact from './Contact'
import avatar from '../../assets/portrait.jpg'
import { fetchUncertainties } from 'services/api'
import { calculateActivityStats } from 'services/statistics'
import { colors } from 'layouts/colors'

const styles = {
  borderRounded: {
    backgroundColor: 'white',
    padding: '1rem',
    marginLeft: '1rem',
    border: '1px solid #ecf0f1',
    borderRadius: '4px',
  },
}

const List = styled.ul`
  list-style-type: none;
  margin: 1rem;
  padding: 0;
  color: ${(props) => props.color};
`
const ListItem = styled.li`
  &:before {
    content: '- ';
  }
  font-size: 1.2rem;
`
const Paragraph = styled.div`
  max-width: 38rem;
  font-weight: 300;
  font-size: 1.2rem;
  margin-bottom: 1rem;
`

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      statistics: {},
      activity: {},
    }
  }

  componentDidMount() {
    this.fetchStatistics(this.props.userid).then((stats) => {
      const activity = calculateActivityStats(stats.data)
      this.setState({ activity })
    })
  }

  fetchStatistics = async (userid) => {
    return fetchUncertainties(userid)
  }

  render() {
    return (
      <React.Fragment>
        <h1 style={{ marginBottom: '0.5rem' }}>Welcome</h1>
        <h2>to your Project Risk Management tool</h2>
        <Paragraph>
          You can find thorough description of the Risk Management practices and standards by
          following the links below:
          <List color={colors.main}>
            <ListItem>
              <a
                href="https://www.iso.org/fr/iso-31000-risk-management.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Link to ISO 31000
              </a>
            </ListItem>
            <ListItem>
              <a
                href="https://www.coso.org/Pages/default.aspx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Link to COSO Standards
              </a>
            </ListItem>
            <ListItem>
              {/* eslint-disable-next-line  */}
              <a style={{ color: '#bdc3c7' }}>Link to the company rules </a>
            </ListItem>
          </List>
        </Paragraph>
        <Paragraph>
          <h3>Our primary objectives</h3>
          <List>
            <ListItem>
              {/* eslint-disable-next-line  */}
              <a>Finish on time</a>
            </ListItem>
            <ListItem>
              {/* eslint-disable-next-line  */}
              <a>Within costs</a>
            </ListItem>
            <ListItem>
              {/* eslint-disable-next-line  */}
              <a>High quality</a>
            </ListItem>
            <ListItem>
              {/* eslint-disable-next-line  */}
              <a>Respect HSE guidelines</a>
            </ListItem>
          </List>
        </Paragraph>
        <Paragraph>
          <h3>Your statistics</h3>
          <div id="activity_charts" style={{ height: '250px', width: '75%' }}>
            <FlexDiv id="activity_stats">
              <DoughnutChart data={this.state.activity} />
              <DoughnutLegend data={this.state.activity} />
            </FlexDiv>
          </div>
          <FlexDiv id="activity_stats">
            <FlexDiv direction="column" style={styles.borderRounded} margins="1rem" height="100px">
              <FlexDiv>{this.state.activity.risk}</FlexDiv>
              <FlexDiv>Risk declared</FlexDiv>
            </FlexDiv>
            <FlexDiv direction="column" style={styles.borderRounded} margins="1rem" height="100px">
              <FlexDiv>{this.state.activity.opp}</FlexDiv>
              <FlexDiv>Opportunities declared</FlexDiv>
            </FlexDiv>
          </FlexDiv>
        </Paragraph>
        <Paragraph>
          <h3>Your contacts</h3>
          {/* <Contact
            picture={avatar}
            name="Yvette Orban"
            position="Project Risk Manager"
            email="yvetteo@syncons.com"
          /> */}
          <Contact
            picture={avatar}
            name="Bogdan Pletea"
            position="Risk Manager"
            email="bodgan.pletea@magnifor.com"
          />
        </Paragraph>
        <DeclareButton />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  userid: state.auth.user.id,
})

export default connect(mapStateToProps)(Home)
