export function selectBestUserRole(userRoles) {
  const bestRole = userRoles
    ? userRoles
        .map(role => {
          switch (role) {
            case 'TEAM_MANAGER':
              return { role: role, score: 1 }
            case 'RISK_MANAGER':
              return { role: role, score: 2 }
            case 'COMPANY_MANAGER':
              return { role: role, score: 3 }
            default:
              return { role: role, score: 0 }
          }
        })
        .sort((r1, r2) => r2.score - r1.score)
        .map(({ role }) => role)[0]
    : null
  return bestRole
}

export function hasRole(role, user) {
  return user.roles && user.roles.indexOf(role) !== -1
}

export function hasAtLeastOneRole(roles, user) {
  const rolesArr = Array.isArray(roles) ? roles : Array.of(roles)
  return rolesArr.filter(role => hasRole(role, user)).length > 0
}

export function isTeamManager(userRoles) {
  return !!userRoles.filter(role => role === 'TEAM_MANAGER').length
}

export function isCompanyManager(userRoles) {
  return !!userRoles.filter(role => role === 'COMPANY_MANAGER').length
}

export function getTeamManagers(members) {
  return members.filter(m => isTeamManager(m.roles))
}

export function stopEvent(cb) {
  return function(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    try {
      cb(evt)
    } catch (e) {
      console.error(e)
    }
    return false
  }
}

export function isObjectEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function contains(value, array) {
  return array && array.indexOf(value) !== -1
}

export function changeAlpha(colour, alpha) {
  if (colour && alpha) {
    const colourPart = colour
      .trim()
      .substring(0, colour.lastIndexOf(','))
      .trim()
    return `${colourPart}, ${alpha})`
  }
}

/**
 * Ignore and prevent event from any child at any level to bubble up
 * @param {Event} e
 */
export function stopChildEventPropagation(e) {
  if (e.currentTarget !== e.target) e.stopPropagation()
}
