import { push } from 'connected-react-router'
import { arrayPush, arrayRemove, SubmissionError, setSubmitFailed, untouch } from 'redux-form'

const FETCH_UCT = 'FETCH_UCT'
const FETCH_UCT_SUCCESS = 'FETCH_UCT_SUCCESS'
const FETCH_UCT_FAILURE = 'FETCH_UCT_FAILURE'

const SAVE_UCT = 'SAVE_UCT'
const SAVE_UCT_SUCCESS = 'SAVE_UCT_SUCCESS'
const SAVE_UCT_FAILURE = 'SAVE_UCT_FAILURE'

const DECLARE_UCT = 'DECLARE_UCT'
const DECLARE_UCT_SUCCESS = 'DECLARE_UCT_SUCCESS'
const DECLARE_UCT_FAILURE = 'DECLARE_UCT_FAILURE'

const UPLOAD_DOC = 'UPLOAD_DOC'
const UPLOAD_DOC_SUCCESS = 'UPLOAD_DOC_SUCCESS'
const UPLOAD_DOC_FAILURE = 'UPLOAD_DOC_FAILURE'

const DELETE_DOC = 'DELETE_DOC'
const DELETE_DOC_SUCCESS = 'DELETE_DOC_SUCCESS'
const DELETE_DOC_FAILURE = 'DELETE_DOC_FAILURE'

const initialState = {
  data: {},
  loading: false,
  error: null,
}

export default function declareReducer(state = initialState, action) {
  switch (action.type) {
    case DECLARE_UCT:
    case SAVE_UCT:
    case FETCH_UCT:
      return {
        ...state,
        loading: true,
      }
    case FETCH_UCT_SUCCESS:
      return {
        ...state,
        data: action.result.data,
        loading: false,
      }
    case DECLARE_UCT_SUCCESS:
    case SAVE_UCT_SUCCESS:
    case FETCH_UCT_FAILURE:
    case SAVE_UCT_FAILURE:
    case UPLOAD_DOC_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case UPLOAD_DOC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

const ROOT_URL = process.env.REACT_APP_API_URL

export function fetchUncertainty(id) {
  return {
    types: [FETCH_UCT, FETCH_UCT_SUCCESS, FETCH_UCT_FAILURE],
    promise: client => client.get(`${ROOT_URL}/uncertainties/${id}`),
  }
}

export function declareUncertainty(values) {
  return {
    types: [DECLARE_UCT, DECLARE_UCT_SUCCESS, DECLARE_UCT_FAILURE],
    promise: client => client.post(`${ROOT_URL}/uncertainties/declare`, values),
    afterSuccess: dispatch => dispatch(push('/confirm')),
  }
}

export function saveUncertainty() {
  return {
    types: [SAVE_UCT, SAVE_UCT_SUCCESS, SAVE_UCT_FAILURE],
    promise: (client, getState, dispatch) => {
      const {
        form: {
          DeclareForm: { values, registeredFields },
        },
      } = getState()
      if (!values.title) {
        dispatch(untouch('DeclareForm', ...Object.keys(registeredFields)))
        dispatch(setSubmitFailed('DeclareForm', 'title'))
        return Promise.reject(new SubmissionError({ title: 'Required' }))
      } else {
        return client.post(`${ROOT_URL}/uncertainties/save`, values)
      }
    },
    afterSuccess: dispatch => dispatch(push('/dashboard')),
  }
}

export function uploadDocument(file) {
  return {
    types: [UPLOAD_DOC, UPLOAD_DOC_SUCCESS, UPLOAD_DOC_FAILURE],
    promise: client => {
      const data = new FormData()
      data.append('file', file)
      return client.put(`${ROOT_URL}/documents/`, data)
    },
    afterSuccess: (dispatch, getState, result) =>
      dispatch(arrayPush('DeclareForm', 'documents', result.data)),
  }
}

export function removeDocument(index, doc) {
  return {
    types: [DELETE_DOC, DELETE_DOC_SUCCESS, DELETE_DOC_FAILURE],
    promise: client => client.delete(`${ROOT_URL}/documents/${doc.id}`),
    afterSuccess: dispatch => dispatch(arrayRemove('DeclareForm', 'documents', index)),
  }
}

export const actions = {
  declareUncertainty,
  fetchUncertainty,
  saveUncertainty,
  uploadDocument,
  removeDocument,
}
