export const UCT_TYPE_TH = 'threat'
export const UCT_TYPE_OP = 'opportunity'

// as well called package
export const COMPANY_FUNCTIONS = ['hr', 'lc', 'mk', 'ac']

export const COMPANY_PROJECTS = ['p-nestle']

export const RISK_TYPES_TECHNICAL = [
  'tec1',
  'tec2',
  'tec3',
  'tec4',
  'tec5',
  'tec6',
  'tec7',
  'tec8',
  'tec9',
  'tec10',
  'tec11',
  'tec12',
]
export const RISK_TYPES_MANAGEMENT = [
  'man1',
  'man2',
  'man3',
  'man4',
  'man5',
  'man6',
  'man7',
  'man8',
  'man9',
  'man10',
]
export const RISK_TYPES_COMMERCIAL = ['com1', 'com2', 'com3', 'com4', 'com5', 'com6']
export const RISK_TYPES_EXTERNAL = [
  'ext1',
  'ext2',
  'ext3',
  'ext4',
  'ext5',
  'ext6',
  'ext7',
  'ext8',
  'ext9',
  'ext10',
  'ext11',
]
