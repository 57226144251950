import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Tabs, Tab, Button } from '@material-ui/core'
import { Bloc, MainTitle } from 'components/common'
import TimelineList from './Timeline/TimelineList'
import DedicatedTasksContainer from './DedicatedTasksContainer'
import DeclaredRiskContainer from './DeclaredRisk/DeclaredRiskContainer'
import StatusBar from './StatusBar'
import * as api from 'services/api'
import { withRouter } from 'react-router-dom'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

class UncertaintyConsultPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uncertainty: {},
      timeline: [],
      tabId: 0,
    }
    this.fetchTimeline.bind(this)
    this.fetchUncertainty.bind(this)
  }

  async fetchUncertainty() {
    const uncertainty = (await api.fetchUncertainty(this.props.match.params.id)).data
    this.setState({ uncertainty: uncertainty })
  }

  async fetchTimeline() {
    const timeline = (await api.fetchTimeline(this.props.match.params.id)).data
    this.setState({ timeline: timeline })
  }

  handleTabChange = (evt, value) => {
    this.setState((state) => {
      if (state.tabId !== value) return { ...state, tabId: value }
      else return null
    })
  }

  componentDidMount() {
    this.fetchUncertainty()
    this.fetchTimeline()
  }

  render() {
    const {
      tabId,
      timeline,
      uncertainty,
      uncertainty: { reference, status, title },
    } = this.state
    const { location } = this.props
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            startIcon={<KeyboardBackspaceIcon />}
            onClick={() =>
              this.props.history.push(location.state.from, {
                tab: location.state && location.state.tab,
              })
            }
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={12}>
          <MainTitle size="medium">
            {reference}: {title}
          </MainTitle>
        </Grid>
        <Grid item xs={12}>
          <StatusBar status={status} />
        </Grid>
        <Grid item xs={12}>
          <Tabs value={tabId} onChange={this.handleTabChange} indicatorColor="primary">
            <Tab label="Risk details" />
            <Tab label="Tasks" disabled={status && status.indexOf('PENDING') === 0} />
            <Tab style={{ textTransform: 'none !important' }} label="Timeline" />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          {tabId === 0 && (
            <DeclaredRiskContainer data={uncertainty} readOnly={true} forConsultation={true} />
          )}
          {tabId === 1 && <DedicatedTasksContainer data={uncertainty.tasks} />}
          {tabId === 2 && (
            <Bloc contentProps={{ height: '600px', overflowY: 'auto' }}>
              <TimelineList timeline={timeline} />
            </Bloc>
          )}
        </Grid>
        <Grid item xs={6} />
      </Grid>
    )
  }
}

export default withRouter(UncertaintyConsultPage)
