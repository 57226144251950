const GET_DETAILS = 'ud/detail/GET_DETAILSS'
const GET_DETAILS_SUCCESS = 'ud/detail/GET_DETAILS_SUCCESS'
const GET_DETAILS_FAILURE = 'ud/detail/GET_DETAILS_FAILURE'

const UPDATE_DETAILS = 'ud/detail/UPDATE_DETAILSS'
const UPDATE_DETAILS_SUCCESS = 'ud/detail/UPDATE_DETAILS_SUCCESS'
const UPDATE_DETAILS_FAILURE = 'ud/detail/UPDATE_DETAILS_FAILURE'

const GET_UCTS = 'ud/detail/GET_UCTS'
const GET_UCTS_SUCCESS = 'ud/detail/GET_UCTS_SUCCESS'
const GET_UCTS_FAILURE = 'ud/detail/GET_UCTS_FAILURE'

const initialState = {
  details: {},
  uncertainties: [],
  error: null,
  loading: false,
}

export default function usersDetailReducer(state = initialState, action) {
  if (action.type.indexOf('ud/') !== 0) {
    return state
  }
  switch (action.type) {
    case GET_DETAILS:
    case GET_UCTS:
      return {
        ...state,
        loading: true,
      }
    case GET_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.result.data,
        loading: false,
        error: null,
      }
    case UPDATE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.result.data,
        loading: false,
        error: null,
      }
    case GET_UCTS_SUCCESS:
      return {
        uncertainties: action.result.data,
        error: null,
      }
    case GET_DETAILS_FAILURE:
    case UPDATE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

const ROOT_URL = process.env.REACT_APP_API_URL

function fetchDetails(id) {
  return {
    types: [GET_DETAILS, GET_DETAILS_SUCCESS, GET_DETAILS_FAILURE],
    promise: client => client.get(`${ROOT_URL}/user/${id}/details`),
  }
}

function updateDetails(id, values) {
  return {
    types: [UPDATE_DETAILS, UPDATE_DETAILS_SUCCESS, UPDATE_DETAILS_FAILURE],
    promise: client => client.post(`${ROOT_URL}/user/${id}/details`, { ...values }),
  }
}

function fetchUncertainties(id) {
  return {
    types: [GET_UCTS, GET_UCTS_SUCCESS, GET_UCTS_FAILURE],
    promise: client => client.get(`${ROOT_URL}/user/${id}/uncertainties`),
  }
}

const revokeUserAccess = id => {
  return {
    types: [UPDATE_DETAILS, UPDATE_DETAILS_SUCCESS, UPDATE_DETAILS_FAILURE],
    promise: client => client.get(`${ROOT_URL}/user/${id}/revoke`),
  }
}

const grantUserAccess = id => {
  return {
    types: [UPDATE_DETAILS, UPDATE_DETAILS_SUCCESS, UPDATE_DETAILS_FAILURE],
    promise: client => client.get(`${ROOT_URL}/user/${id}/grant`),
  }
}

export const actions = {
  fetchUncertainties,
  fetchDetails,
  updateDetails,
  revokeUserAccess,
  grantUserAccess,
}
