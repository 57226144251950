import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import compose from 'utils/compose'

const styles = {
  subMenuItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}

const SubMenuItem = ({ caption, children, menuItems, classes }) => {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const [anchorElement, setAnchorElement] = React.useState(null)

  const handleItemClick = event => {
    event.stopPropagation()
    event.preventDefault()
    if (!anchorElement) {
      setAnchorElement(event.currentTarget)
    }
    setMenuOpen(!menuOpen)
  }

  const handleSubMenuClose = () => {
    setMenuOpen(false)
  }

  return (
    <React.Fragment>
      <MenuItem onClick={handleItemClick} className={classNames(classes.subMenuItem)}>
        {caption}
        <ArrowRightIcon />
      </MenuItem>
      <Menu
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        anchorEl={anchorElement}
        onClose={handleSubMenuClose}
      >
        {children}
      </Menu>
    </React.Fragment>
  )
}

SubMenuItem.propTypes = {
  caption: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
}

export default compose(
  React.memo,
  withStyles(styles)
)(SubMenuItem)
