import React from 'react'
import TaskForm from './TaskForm.synergy'
import CloseUncertaintyForm from './CloseUncertaintyForm'

const AssignedFormContainer = ({ uncertainty, onSubmitTask, onCloseUncertainty }) => {
  return (
    <React.Fragment>
      <TaskForm uncertainty={uncertainty} onSubmit={onSubmitTask} />
      <CloseUncertaintyForm onSubmit={onCloseUncertainty} />
    </React.Fragment>
  )
}

export default AssignedFormContainer
