import React from 'react'
import { Body, Container, Header } from 'components/common'
import ErrorBoundary from 'components/common/ErrorBoundary'

export default ({ render, ...props }) => {
  return (
    <>
      <Header currentLocation={window.location.pathname} />
      <Container maxWidth="1200px" margins="80px auto 0">
        <ErrorBoundary>
          <Body direction="column">{render(props)}</Body>
        </ErrorBoundary>
      </Container>
    </>
  )
}
