import { DateTime } from 'luxon'

/**
 * Calculates in percent, the change between 2 numbers.
 * e.g from 1000 to 500 = 50%
 *
 * @param o The initial value
 * @param n The value that changed
 */
export function calculatePercentageChange(n, o) {
  // console.debug('calculatePercentageChange', 'n', n, 'o', o)
  if (n === o) {
    return 0
  }
  // return 100 * Math.abs((n - o) / ((n + o) / 2))
  return ((o - n) / (o ? o : 1)) * 100
}

export function absoluteFloor(val) {
  return Math.floor(Math.abs(val))
}

export function compare({ n, o }, lessIsBetter = false) {
  return n > o ? (lessIsBetter ? -1 : 1) : n < o ? (lessIsBetter ? 1 : -1) : 0
}

export const formatPercentageEvolution = (v) => {
  if (v > 0) return `+ ${absoluteFloor(v)}%`
  else if (v < 0) return `- ${absoluteFloor(v)}%`
  else return '='
}

export const formatDecimals = (number, decimals) => {
  if (number !== 0 && decimals >= 0) {
    return number.toFixed(decimals)
  } else {
    return number
  }
}

//TODO improve implementation
export const getCurrentWeekNumber = () => {
  // returns at least weekNumber
  return DateTime.utc().weekNumber - 1 || 1
}
