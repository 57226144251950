import React from 'react'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'
import { Container } from 'components/common'
import MySettingsForm from './MySettingsForm'
import { actions } from 'reducers/dashboardReducer'
import { selectBestUserRole } from 'utils/utils'

class MySettings extends React.PureComponent {
  handleSubmit = values => {
    this.props.saveSettings(values)
  }
  loadValues = () => {
    const {
      email,
      firstname,
      lastname,
      mobile,
      landline,
      location,
      roles,
      team,
      lastConnection,
    } = this.props.user
    const lastConnectionFormatted = DateTime.fromISO(lastConnection).toLocaleString(
      DateTime.DATETIME_MED_WITH_SECONDS
    )
    return {
      email,
      firstname,
      lastname,
      landline,
      mobile,
      location,
      status: selectBestUserRole(roles),
      team: team ? team.name : '',
      lastConnection: lastConnectionFormatted,
    }
  }

  componentDidMount() {
    this.props.fetchSettings()
  }

  render() {
    return (
      <Container id="mySettings">
        <MySettingsForm onSubmit={this.handleSubmit} initialValues={this.loadValues()} />
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return { user: state.auth.user }
}

export default connect(
  mapStateToProps,
  actions
)(MySettings)
