import React from 'react'
import { Field, Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { TextField, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { Buttons, ErrorMessage, Form as StyledForm } from './common'

const validate = values => {
  const errors = {}
  const requiredFields = ['username', 'password']
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  if (values.username && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
    errors.username = 'Invalid email address'
  }
  return errors
}

const renderTextField = ({ input, label, meta, ...custom }) => {
  const { touched, error } = meta
  return (
    <TextField
      {...input}
      label={label}
      error={touched && error ? true : false}
      helperText={touched && error ? error.toString() : ''}
      {...custom}
    />
  )
}

const LoginForm = ({ onSubmit }) => {
  const handleSubmit = async values => {
    const error = await onSubmit(values)
    return { [FORM_ERROR]: error && error.message }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={fProps => {
        if (fProps.submitFailed && fProps.dirtySinceLastSubmit) fProps.form.reset(fProps.values)
        return (
          <StyledForm style={{ minHeight: '300px' }} onSubmit={fProps.handleSubmit}>
            {/* Display error message */}
            {fProps.hasSubmitErrors && <ErrorMessage>{fProps.submitError}</ErrorMessage>}
            <Field
              name="username"
              type="email"
              label="EMAIL ADDRESS"
              autoComplete="username"
              component={renderTextField}
              placeholder="user@yourcompany.com"
              InputLabelProps={{ shrink: true }}
            />
            <Field
              name="password"
              type="password"
              label="PASSWORD"
              autoComplete="current-password"
              component={renderTextField}
            />

            <Buttons>
              <Button
                id="login-button"
                variant="contained"
                color="primary"
                type="submit"
                disabled={fProps.submitting || fProps.hasValidationErrors}
              >
                LOG IN
              </Button>
            </Buttons>
            <Link to="/reset/request" style={{ color: '#7f8c8d', fontStyle: 'italic' }}>
              Reset your password
            </Link>
          </StyledForm>
        )
      }}
    />
  )
}

export default LoginForm
