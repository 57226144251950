import { combineReducers } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'
import { connectRouter } from 'connected-react-router'
import authenticationReducer from './authenticationReducer'
import uncertaintiesReducer from './uncertaintiesReducer'
import usersReducer from './usersReducer'
import teamsReducer from './teamsReducer'
import teamsDetailReducer from './teamsDetailReducer'
import usersDetailReducer from './usersDetailReducer'
import registerReducer from './registerReducer'
import registerDetailsReducer from './registerDetailsReducer'
import declareReducer from './declareReducer'
import dashboardReducer from './dashboardReducer'

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: authenticationReducer,
    dashboard: dashboardReducer,
    declare: declareReducer,
    uncertainties: uncertaintiesReducer,
    teams: teamsReducer,
    teamsDetail: teamsDetailReducer,
    users: usersReducer,
    usersDetail: usersDetailReducer,
    register: registerReducer,
    registerDetails: registerDetailsReducer,
    form: reduxFormReducer,
  })
