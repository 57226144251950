import React from 'react'
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { Delete, InsertDriveFile } from '@material-ui/icons'
import PropTypes from 'prop-types'
import ClickableComponent from 'components/common/ClickableComponent'
import styled from 'styled-components'

const DocumentList = ({ documents, onRemove, onClick, hoverable, dense = false }) => {
  return (
    <div>
      {documents ? (
        <List dense={dense}>
          {documents.map((doc, index) =>
            React.cloneElement(
              <StyledClickableComponent
                component={ListItem}
                key={doc.id}
                onClick={onClick(doc.id)}
                hoverable={hoverable}
              >
                <ListItemAvatar>
                  <Avatar>
                    <InsertDriveFile />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={doc.name} />
                <ListItemText primary={`(${formatBytes(doc.size)})`} />
                <ListItemSecondaryAction>
                  {onRemove ? (
                    <IconButton aria-label="Remove">
                      <Delete onClick={onRemove(index, doc)} />
                    </IconButton>
                  ) : (
                    ''
                  )}
                </ListItemSecondaryAction>
              </StyledClickableComponent>,
              { key: doc.id }
            )
          )}
        </List>
      ) : (
        ''
      )}
    </div>
  )
}

const StyledClickableComponent = styled(ClickableComponent)`
  :hover {
    background-color: ${props => (props.hoverable ? '#ecf0f1' : 'inherit')};
  }
`

DocumentList.propTypes = {
  documents: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
  onClick: PropTypes.func,
  hoverable: PropTypes.bool,
}
DocumentList.defaultProps = {
  documents: [],
  onClick: () => null,
  onRemove: () => null,
  hoverable: false,
}

function formatBytes(a, b) {
  if (0 === parseInt(a, 10)) return '0 Bytes'
  var c = 1024,
    d = b || 2,
    e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    f = Math.floor(Math.log(a) / Math.log(c))
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f]
}

export default DocumentList
