import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

const UnauthorizedAccessAlert = ({ openIf, onClose }) => {
  return (
    <div>
      <Dialog
        open={openIf}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Unauthorized access'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Access to this content is denied due to invalid credentials.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default UnauthorizedAccessAlert
