import React from 'react'
import { Field, Form } from 'react-final-form'
import { FormGroup, MenuItem } from '@material-ui/core'
import { FlexDiv, Bloc } from 'components/common'
import {
  renderTextField,
  renderFormControlSelect,
  StyledButton,
  StyledFormControl,
  StyledFormControlLabel,
  StyledFormLabel,
} from 'components/Uncertainty/Details/FormComponents'
import { ErrorMessage } from 'components/Staff/common/FormComponents'
import styled from 'styled-components'
import { renderCheckbox } from 'components/Uncertainty/Declare/common/FormComponents'
import { FormattedMessage, injectIntl } from 'react-intl'
import { COMPANY_PROJECTS } from 'components/Uncertainty/constants'

const validate = (values) => {
  const errors = {}
  const requiredFields = ['title', 'description', 'cause', 'suggestion']

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  return errors
}

const FormButtonWrapper = styled.div`
  padding: 0 10px;
`

class DeclaredRiskForm extends React.Component {
  generateSelectItems = (items) => {
    return items.map((i) => i)
  }

  asDeclaredValueReadOnly = (status, readOnly) => {
    return status !== 'PENDING_REVIEW' || readOnly
  }

  _generateCompanyProjectItems = (compProjects = []) =>
    compProjects.map((v, index) => (
      <MenuItem value={v} key={index}>
        <FormattedMessage id={`uct.projectPackage.label.${v}`} />
      </MenuItem>
    ))

  render() {
    const {
      onSubmit,
      initialValues,
      readOnly,
      forConsultation,
      toggleEditMode = () => null,
      intl,
    } = this.props
    return (
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        render={({
          submitError,
          handleSubmit,
          submitting,
          pristine,
          invalid,
          dirtySinceLastSubmit,
          submitFailed,
          form,
          values,
        }) => {
          // Reset form with current values to reset form "submitFailed"
          // status and remove error message
          if (dirtySinceLastSubmit) form.reset(values)
          return (
            <form id="declarations_form" name="declarations" onSubmit={handleSubmit}>
              {/* Display error message */}
              {submitFailed && submitError && <ErrorMessage>{submitError}</ErrorMessage>}
              <FlexDiv width="100%" direction="column" align="start">
                <FlexDiv justifyContent="space-around" width="100%">
                  <FlexDiv margins="4px 10px 0" maxWidth={forConsultation ? '280px' : '298px'}>
                    <Field
                      name="title"
                      component={renderTextField}
                      placeholder="Ex: Subcontractor not able to deliver on time"
                      label="Title"
                      margin="dense"
                      multiline={false}
                      disabled={readOnly}
                    />
                  </FlexDiv>
                  <FlexDiv>
                    <FlexDiv margins="0 5px 0 10px">
                      <Field
                        name="responsability"
                        component={renderFormControlSelect}
                        label="Project Package"
                        controlProps={{
                          component: 'div',
                          margin: 'dense',
                          fullWidth: true,
                        }}
                        margin="dense"
                        disabled={readOnly}
                      >
                        {this._generateCompanyProjectItems(COMPANY_PROJECTS)}
                      </Field>
                    </FlexDiv>
                    <FlexDiv margins="0 10px 0 5px">
                      <Field
                        name="type"
                        component={renderFormControlSelect}
                        label="Type"
                        controlProps={{
                          component: 'div',
                          margin: 'dense',
                          fullWidth: true,
                        }}
                        disabled
                        margin="dense"
                      >
                        <MenuItem value="threat">
                          <FormattedMessage id={`uct.type.label.threat`} />
                        </MenuItem>
                        <MenuItem value="opportunity">
                          <FormattedMessage id={`uct.type.label.opportunity`} />
                        </MenuItem>
                      </Field>
                    </FlexDiv>
                  </FlexDiv>
                  <FlexDiv margins="0 2px 0 0" />
                </FlexDiv>
                <FlexDiv fullWidth={true}>
                  <FlexDiv margins="0 10px">
                    <Field
                      name="description"
                      component={renderTextField}
                      placeholder={intl.formatMessage({
                        id: `forms.utc.declare.description.placeholder.${this.props.type}`,
                      })}
                      minRows="6"
                      maxRows="7"
                      label="Description"
                      margin="dense"
                      multiline={true}
                      disabled={readOnly}
                    />
                  </FlexDiv>
                  <FlexDiv margins="0 10px">
                    <Field
                      name="cause"
                      component={renderTextField}
                      placeholder=""
                      minRows="6"
                      maxRows="7"
                      label="Cause"
                      margin="dense"
                      multiline={true}
                      disabled={readOnly}
                    />
                  </FlexDiv>
                  <FlexDiv margins="0 10px">
                    <Field
                      name="suggestion"
                      component={renderTextField}
                      placeholder=""
                      minRows="6"
                      maxRows="7"
                      label="Proposed response(s) for mitigation"
                      margin="dense"
                      multiline={true}
                      disabled={readOnly}
                    />
                  </FlexDiv>
                </FlexDiv>

                <FlexDiv margins="0.5rem" paddings="0 10px">
                  <StyledFormControl margin="normal" fullWidth>
                    <StyledFormLabel
                      component="label"
                      disabled={readOnly}
                      style={{ marginLeft: '-6px' }}
                    >
                      Impacted objective(s)
                    </StyledFormLabel>
                    <FlexDiv align="center">
                      <FormGroup row={true}>
                        <StyledFormControlLabel
                          control={
                            <Field
                              name="obTime"
                              color="primary"
                              component={renderCheckbox}
                              disabled={readOnly}
                            />
                          }
                          label="Finish on time"
                        />
                        <StyledFormControlLabel
                          control={
                            <Field
                              name="obCosts"
                              color="primary"
                              component={renderCheckbox}
                              disabled={readOnly}
                            />
                          }
                          label="Budget"
                        />
                        <StyledFormControlLabel
                          control={
                            <Field
                              name="obQuality"
                              color="primary"
                              component={renderCheckbox}
                              disabled={readOnly}
                            />
                          }
                          label="Quality"
                        />
                        <StyledFormControlLabel
                          control={
                            <Field
                              name="obGuidelines"
                              color="primary"
                              component={renderCheckbox}
                              disabled={readOnly}
                            />
                          }
                          label="Respect HSE guidelines"
                        />
                      </FormGroup>
                      <FormGroup />
                    </FlexDiv>
                  </StyledFormControl>
                </FlexDiv>
                <FlexDiv fullWidth={true}>
                  <FlexDiv direction="column" align="stretch" width="100%" margins="0 10px">
                    <Bloc title="As Declared">
                      <FlexDiv margins="0 2px 0 0">
                        <Field
                          name="impact1"
                          component={renderFormControlSelect}
                          label="Impact on schedule"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={this.asDeclaredValueReadOnly(initialValues.status, readOnly)}
                        >
                          <MenuItem value="imp11">
                            <FormattedMessage id={'utc.imp.schedule.item.label.imp11'} />
                          </MenuItem>
                          <MenuItem value="imp12">
                            <FormattedMessage id={'utc.imp.schedule.item.label.imp12'} />
                          </MenuItem>
                          <MenuItem value="imp13">
                            <FormattedMessage id={'utc.imp.schedule.item.label.imp13'} />
                          </MenuItem>
                          <MenuItem value="imp14">
                            <FormattedMessage id={'utc.imp.schedule.item.label.imp14'} />
                          </MenuItem>
                        </Field>
                      </FlexDiv>
                      <FlexDiv margins="0 0 0 2px">
                        <Field
                          name="impact2"
                          component={renderFormControlSelect}
                          label="Impact on cost"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={this.asDeclaredValueReadOnly(initialValues.status, readOnly)}
                        >
                          <MenuItem value="imp21">
                            <FormattedMessage id={'utc.imp.costs.item.label.imp21'} />
                          </MenuItem>
                          <MenuItem value="imp22">
                            <FormattedMessage id={'utc.imp.costs.item.label.imp22'} />
                          </MenuItem>
                          <MenuItem value="imp23">
                            <FormattedMessage id={'utc.imp.costs.item.label.imp23'} />
                          </MenuItem>
                          <MenuItem value="imp24">
                            <FormattedMessage id={'utc.imp.costs.item.label.imp24'} />
                          </MenuItem>
                        </Field>
                      </FlexDiv>
                      <FlexDiv margins="0 2px 0 0">
                        <Field
                          name="impact3"
                          component={renderFormControlSelect}
                          label="impact on quality"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={this.asDeclaredValueReadOnly(initialValues.status, readOnly)}
                        >
                          <MenuItem value="imp31">
                            <FormattedMessage id={'utc.imp.quality.item.label.imp31'} />
                          </MenuItem>
                          <MenuItem value="imp32">
                            <FormattedMessage id={'utc.imp.quality.item.label.imp32'} />
                          </MenuItem>
                          <MenuItem value="imp33">
                            <FormattedMessage id={'utc.imp.quality.item.label.imp33'} />
                          </MenuItem>
                          <MenuItem value="imp34">
                            <FormattedMessage id={'utc.imp.quality.item.label.imp34'} />
                          </MenuItem>
                        </Field>
                      </FlexDiv>
                      <FlexDiv margins="0 2px 0 0">
                        <Field
                          name="impact4"
                          component={renderFormControlSelect}
                          label="impact on HSSE"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={this.asDeclaredValueReadOnly(initialValues.status, readOnly)}
                        >
                          <MenuItem value="imp41">
                            <FormattedMessage id={'utc.imp.quality.item.label.imp41'} />
                          </MenuItem>
                          <MenuItem value="imp42">
                            <FormattedMessage id={'utc.imp.quality.item.label.imp42'} />
                          </MenuItem>
                          <MenuItem value="imp43">
                            <FormattedMessage id={'utc.imp.quality.item.label.imp43'} />
                          </MenuItem>
                          <MenuItem value="imp44">
                            <FormattedMessage id={'utc.imp.quality.item.label.imp44'} />
                          </MenuItem>
                        </Field>
                      </FlexDiv>
                      <FlexDiv margins="0 0 0 2px">
                        <Field
                          name="occurence"
                          component={renderFormControlSelect}
                          label="Probability of occurence"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={this.asDeclaredValueReadOnly(initialValues.status, readOnly)}
                        >
                          <MenuItem value="o1">
                            <FormattedMessage id={'utc.occ.item.label.o1'} />
                          </MenuItem>
                          <MenuItem value="o2">
                            <FormattedMessage id={'utc.occ.item.label.o2'} />
                          </MenuItem>
                          <MenuItem value="o3">
                            <FormattedMessage id={'utc.occ.item.label.o3'} />
                          </MenuItem>
                          <MenuItem value="o4">
                            <FormattedMessage id={'utc.occ.item.label.o4'} />
                          </MenuItem>
                        </Field>
                      </FlexDiv>
                    </Bloc>
                  </FlexDiv>
                  <FlexDiv direction="column" align="stretch" width="100%" margins="0 10px">
                    <Bloc title="Current">
                      <FlexDiv margins="0 2px 0 0">
                        <Field
                          name="impact1Current"
                          component={renderFormControlSelect}
                          label="Impact on schedule"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={readOnly}
                        >
                          <MenuItem value="imp11">
                            <FormattedMessage id={'utc.imp.schedule.item.label.imp11'} />
                          </MenuItem>
                          <MenuItem value="imp12">
                            <FormattedMessage id={'utc.imp.schedule.item.label.imp12'} />
                          </MenuItem>
                          <MenuItem value="imp13">
                            <FormattedMessage id={'utc.imp.schedule.item.label.imp13'} />
                          </MenuItem>
                          <MenuItem value="imp14">
                            <FormattedMessage id={'utc.imp.schedule.item.label.imp14'} />
                          </MenuItem>
                        </Field>
                      </FlexDiv>
                      <FlexDiv margins="0 0 0 2px">
                        <Field
                          name="impact2Current"
                          component={renderFormControlSelect}
                          label="Impact on cost"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={readOnly}
                        >
                          <MenuItem value="imp21">
                            <FormattedMessage id={'utc.imp.costs.item.label.imp21'} />
                          </MenuItem>
                          <MenuItem value="imp22">
                            <FormattedMessage id={'utc.imp.costs.item.label.imp22'} />
                          </MenuItem>
                          <MenuItem value="imp23">
                            <FormattedMessage id={'utc.imp.costs.item.label.imp23'} />
                          </MenuItem>
                          <MenuItem value="imp24">
                            <FormattedMessage id={'utc.imp.costs.item.label.imp24'} />
                          </MenuItem>
                        </Field>
                      </FlexDiv>
                      <FlexDiv margins="0 2px 0 0">
                        <Field
                          name="impact3Current"
                          component={renderFormControlSelect}
                          label="impact on quality"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={readOnly}
                        >
                          <MenuItem value="imp31">
                            <FormattedMessage id={'utc.imp.quality.item.label.imp31'} />
                          </MenuItem>
                          <MenuItem value="imp32">
                            <FormattedMessage id={'utc.imp.quality.item.label.imp32'} />
                          </MenuItem>
                          <MenuItem value="imp33">
                            <FormattedMessage id={'utc.imp.quality.item.label.imp33'} />
                          </MenuItem>
                          <MenuItem value="imp34">
                            <FormattedMessage id={'utc.imp.quality.item.label.imp34'} />
                          </MenuItem>
                        </Field>
                      </FlexDiv>
                      <FlexDiv margins="0 2px 0 0">
                        <Field
                          name="impact4Current"
                          component={renderFormControlSelect}
                          label="impact on HSSE"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={this.asDeclaredValueReadOnly(initialValues.status, readOnly)}
                        >
                          <MenuItem value="imp41">
                            <FormattedMessage id={'utc.imp.quality.item.label.imp41'} />
                          </MenuItem>
                          <MenuItem value="imp42">
                            <FormattedMessage id={'utc.imp.quality.item.label.imp42'} />
                          </MenuItem>
                          <MenuItem value="imp43">
                            <FormattedMessage id={'utc.imp.quality.item.label.imp43'} />
                          </MenuItem>
                          <MenuItem value="imp44">
                            <FormattedMessage id={'utc.imp.quality.item.label.imp44'} />
                          </MenuItem>
                        </Field>
                      </FlexDiv>
                      <FlexDiv margins="0 0 0 2px">
                        <Field
                          name="occurenceCurrent"
                          component={renderFormControlSelect}
                          label="Probability of occurence"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={readOnly}
                        >
                          <MenuItem value="o1">
                            <FormattedMessage id={'utc.occ.item.label.o1'} />
                          </MenuItem>
                          <MenuItem value="o2">
                            <FormattedMessage id={'utc.occ.item.label.o2'} />
                          </MenuItem>
                          <MenuItem value="o3">
                            <FormattedMessage id={'utc.occ.item.label.o3'} />
                          </MenuItem>
                          <MenuItem value="o4">
                            <FormattedMessage id={'utc.occ.item.label.o4'} />
                          </MenuItem>
                        </Field>
                      </FlexDiv>
                    </Bloc>
                  </FlexDiv>
                  {!forConsultation && (
                    <FlexDiv direction="column" align="stretch" width="100%" margins="0 10px">
                      <Bloc title="Target">
                        <FlexDiv margins="0 2px 0 0">
                          <Field
                            name="impact1Target"
                            component={renderFormControlSelect}
                            label="Impact on schedule"
                            controlProps={{
                              component: 'div',
                              margin: 'dense',
                              fullWidth: true,
                            }}
                            margin="dense"
                            disabled={readOnly}
                          >
                            <MenuItem value="imp11">
                              <FormattedMessage id={'utc.imp.schedule.item.label.imp11'} />
                            </MenuItem>
                            <MenuItem value="imp12">
                              <FormattedMessage id={'utc.imp.schedule.item.label.imp12'} />
                            </MenuItem>
                            <MenuItem value="imp13">
                              <FormattedMessage id={'utc.imp.schedule.item.label.imp13'} />
                            </MenuItem>
                            <MenuItem value="imp14">
                              <FormattedMessage id={'utc.imp.schedule.item.label.imp14'} />
                            </MenuItem>
                          </Field>
                        </FlexDiv>
                        <FlexDiv margins="0 0 0 2px">
                          <Field
                            name="impact2Target"
                            component={renderFormControlSelect}
                            label="Impact on cost"
                            controlProps={{
                              component: 'div',
                              margin: 'dense',
                              fullWidth: true,
                            }}
                            margin="dense"
                            disabled={readOnly}
                          >
                            <MenuItem value="imp21">
                              <FormattedMessage id={'utc.imp.costs.item.label.imp21'} />
                            </MenuItem>
                            <MenuItem value="imp22">
                              <FormattedMessage id={'utc.imp.costs.item.label.imp22'} />
                            </MenuItem>
                            <MenuItem value="imp23">
                              <FormattedMessage id={'utc.imp.costs.item.label.imp23'} />
                            </MenuItem>
                            <MenuItem value="imp24">
                              <FormattedMessage id={'utc.imp.costs.item.label.imp24'} />
                            </MenuItem>
                          </Field>
                        </FlexDiv>
                        <FlexDiv margins="0 2px 0 0">
                          <Field
                            name="impact3Target"
                            component={renderFormControlSelect}
                            label="impact on quality"
                            controlProps={{
                              component: 'div',
                              margin: 'dense',
                              fullWidth: true,
                            }}
                            margin="dense"
                            disabled={readOnly}
                          >
                            <MenuItem value="imp31">
                              <FormattedMessage id={'utc.imp.quality.item.label.imp31'} />
                            </MenuItem>
                            <MenuItem value="imp32">
                              <FormattedMessage id={'utc.imp.quality.item.label.imp32'} />
                            </MenuItem>
                            <MenuItem value="imp33">
                              <FormattedMessage id={'utc.imp.quality.item.label.imp33'} />
                            </MenuItem>
                            <MenuItem value="imp34">
                              <FormattedMessage id={'utc.imp.quality.item.label.imp34'} />
                            </MenuItem>
                          </Field>
                        </FlexDiv>
                        <FlexDiv margins="0 2px 0 0">
                          <Field
                            name="impact4Target"
                            component={renderFormControlSelect}
                            label="impact on HSSE"
                            controlProps={{
                              component: 'div',
                              margin: 'dense',
                              fullWidth: true,
                            }}
                            margin="dense"
                            disabled={this.asDeclaredValueReadOnly(initialValues.status, readOnly)}
                          >
                            <MenuItem value="imp41">
                              <FormattedMessage id={'utc.imp.quality.item.label.imp41'} />
                            </MenuItem>
                            <MenuItem value="imp42">
                              <FormattedMessage id={'utc.imp.quality.item.label.imp42'} />
                            </MenuItem>
                            <MenuItem value="imp43">
                              <FormattedMessage id={'utc.imp.quality.item.label.imp43'} />
                            </MenuItem>
                            <MenuItem value="imp44">
                              <FormattedMessage id={'utc.imp.quality.item.label.imp44'} />
                            </MenuItem>
                          </Field>
                        </FlexDiv>
                        <FlexDiv margins="0 0 0 2px">
                          <Field
                            name="occurenceTarget"
                            component={renderFormControlSelect}
                            label="Probability of occurence"
                            controlProps={{
                              component: 'div',
                              margin: 'dense',
                              fullWidth: true,
                            }}
                            margin="dense"
                            disabled={readOnly}
                          >
                            <MenuItem value="o1">
                              <FormattedMessage id={'utc.occ.item.label.o1'} />
                            </MenuItem>
                            <MenuItem value="o2">
                              <FormattedMessage id={'utc.occ.item.label.o2'} />
                            </MenuItem>
                            <MenuItem value="o3">
                              <FormattedMessage id={'utc.occ.item.label.o3'} />
                            </MenuItem>
                            <MenuItem value="o4">
                              <FormattedMessage id={'utc.occ.item.label.o4'} />
                            </MenuItem>
                          </Field>
                        </FlexDiv>
                      </Bloc>
                    </FlexDiv>
                  )}
                </FlexDiv>
                <FlexDiv justifyContent="flex-end" paddings="15px 0" fullWidth>
                  {!forConsultation && !readOnly && (
                    <FormButtonWrapper>
                      <StyledButton
                        type="button"
                        disabled={submitting}
                        variant="contained"
                        onClick={toggleEditMode}
                      >
                        Cancel
                      </StyledButton>
                    </FormButtonWrapper>
                  )}
                  {!readOnly && (
                    <FormButtonWrapper>
                      <StyledButton
                        type="submit"
                        disabled={pristine || submitting || invalid}
                        variant="contained"
                        color="primary"
                      >
                        Save changes
                      </StyledButton>
                    </FormButtonWrapper>
                  )}
                  {!forConsultation && readOnly && (
                    <FormButtonWrapper>
                      <StyledButton
                        type="button"
                        disabled={submitting}
                        variant="contained"
                        onClick={toggleEditMode}
                      >
                        Edit
                      </StyledButton>
                    </FormButtonWrapper>
                  )}
                </FlexDiv>
              </FlexDiv>
            </form>
          )
        }}
      />
    )
  }
}

export default injectIntl(DeclaredRiskForm)
