import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router'
import { Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

import { actions } from 'reducers/authenticationReducer'

import { GlobalLayout, UserLayout, ManagerLayout } from 'layouts'
import {
  AccountActivationPage,
  LoginPage,
  ResetPasswordPage,
  ResetPasswordRequestPage,
} from 'components/Login'
import {
  ConfirmationPage,
  DeclarePage,
  RegisterPage,
  UncertaintyDetailsPage,
} from 'components/Uncertainty'
import Home from 'components/Home'
import MyDashboard from 'components/Dashboard'
import StaffPage from 'components/Staff'
import UserDetailsPage from 'components/Staff/Users/Detail'
import TeamDetailPage from 'components/Staff/Teams/Detail'

import { KPIDashboardPage, TrmKpiDashboardPage } from 'components/Kpi'
import UncertaintyConsultPage from 'components/Uncertainty/Details/UncertaintyConsultPage'
import TaskConsultationPage from 'components/Task/TaskConsultationPage'
import AuthenticationRequiredDialog from 'components/common/AuthenticationRequiredDialog'
import UnauthorizedAccessAlert from 'components/common/UnauthorizedAccessAlert'
import FullPageOverlayLoader from 'components/common/FullPageOverlayLoader'
import { hasRole } from 'utils/utils'
import ErrorBoundary from 'components/common/ErrorBoundary'

const PrivateRoute = ({ render, authenticated, expiredSession, ready, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!ready) return <FullPageOverlayLoader />
      return authenticated ? (
        <ScrollToTopOnMount>{render(props)}</ScrollToTopOnMount>
      ) : !expiredSession ? (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      ) : (
        <AuthenticationRequiredDialog display={true} />
      )
    }}
  />
)

class App extends React.Component {
  constructor(props) {
    super(props)
    this.props.validateUser()
  }

  render() {
    const { history } = this.props
    return (
      <React.Fragment>
        <UnauthorizedAccessAlert
          openIf={!this.props.hasCredentials}
          onClose={this.props.resetCredentialsError}
        />
        <ConnectedRouter onUpdate={() => window.scrollTo(0, 0)} history={history}>
          <GlobalLayout>
            <Switch>
              <PrivateRoute
                exact
                path="/"
                {...this.props}
                render={props => (
                  <UserLayout
                    {...props}
                    render={props => {
                      return <Home {...props} />
                    }}
                  />
                )}
              />
              <Route
                path="/login"
                render={props => <LoginPage authenticated={this.props.authenticated} {...props} />}
              />
              <Route path="/activation" component={AccountActivationPage} />
              <Route path="/reset/request" component={ResetPasswordRequestPage} />
              <Route exact path="/reset" component={ResetPasswordPage} />
              <PrivateRoute
                exact
                path="/declare"
                {...this.props}
                render={props => (
                  <UserLayout {...props} render={props => <DeclarePage {...props} />} />
                )}
              />
              <PrivateRoute
                path="/declare/:id"
                {...this.props}
                render={props => (
                  <UserLayout {...props} render={props => <DeclarePage {...props} />} />
                )}
              />
              <PrivateRoute
                path="/confirm"
                {...this.props}
                render={props => (
                  <UserLayout {...props} render={props => <ConfirmationPage {...props} />} />
                )}
              />
              <PrivateRoute
                exact
                path="/dashboard"
                {...this.props}
                render={props => (
                  <UserLayout {...props} render={props => <MyDashboard {...props} />} />
                )}
              />
              <PrivateRoute
                exact
                path="/uncertainties"
                {...this.props}
                render={props => (
                  <ManagerLayout
                    {...props}
                    render={props => {
                      return <RegisterPage {...props} />
                    }}
                  />
                )}
              />
              <PrivateRoute
                path="/uncertainties/:id/details"
                {...this.props}
                render={props => (
                  <ManagerLayout
                    {...props}
                    render={props => {
                      return <UncertaintyDetailsPage {...props} />
                    }}
                  />
                )}
              />
              <PrivateRoute
                exact
                path="/dashboard/uncertainties/:id"
                {...this.props}
                render={props => (
                  <UserLayout
                    {...props}
                    render={props => {
                      return <UncertaintyConsultPage {...props} />
                    }}
                  />
                )}
              />

              <PrivateRoute
                exact
                path="/dashboard/tasks/:id"
                {...this.props}
                render={props => (
                  <UserLayout
                    {...props}
                    render={props => {
                      return <TaskConsultationPage {...props} />
                    }}
                  />
                )}
              />
              <PrivateRoute
                exact
                path="/users"
                {...this.props}
                render={props => (
                  <ManagerLayout {...props} render={props => <StaffPage {...props} />} />
                )}
              />
              <PrivateRoute
                path="/users/:id/details"
                {...this.props}
                render={props => (
                  <ManagerLayout
                    {...props}
                    render={props => {
                      return <UserDetailsPage {...props} />
                    }}
                  />
                )}
              />
              <PrivateRoute
                path="/teams/:id/details"
                {...this.props}
                render={props => (
                  <ManagerLayout
                    {...props}
                    render={props => {
                      return <TeamDetailPage {...props} />
                    }}
                  />
                )}
              />
              <PrivateRoute
                path="/kpi"
                {...this.props}
                render={props => {
                  return (
                    <ErrorBoundary>
                      <ManagerLayout
                        {...props}
                        render={props => {
                          if (hasRole('COMPANY_MANAGER', this.props.user))
                            return <TrmKpiDashboardPage {...props} />
                          else return <KPIDashboardPage {...props} />
                        }}
                      />
                    </ErrorBoundary>
                  )
                }}
              />
            </Switch>
          </GlobalLayout>
        </ConnectedRouter>
      </React.Fragment>
    )
  }
}

class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return this.props.children
  }
}

function mapStateToProps(state) {
  return {
    ready: state.auth.ready,
    authenticated: state.auth.authenticated,
    expiredSession: state.auth.expiredSession,
    hasCredentials: state.auth.hasCredentials,
    user: state.auth.user,
  }
}
export default connect(
  mapStateToProps,
  actions
)(App)
