import React from 'react'
import { injectIntl } from 'react-intl'
import { DateTime } from 'luxon'
import { connect } from 'react-redux'

import StatsTab from './StatsTab'
import { fetchDetailedUncertaintiesStatistics } from 'services/api'
import { prepareDataSets } from 'services/statistics'

const colors = [
  'rgba(52, 152, 219, 0.8)',
  'rgba(243, 156, 18,1.0)',
  'rgba(155, 89, 182, 0.8)',
  'rgba(46, 204, 113, 0.8)',
  'rgba(241, 196, 15, 0.8)',
  'rgba(26, 188, 156, 0.8)',
  'rgba(52, 73, 94, 0.8)',
  'rgba(231, 76, 60,1.0)',
  'rgba(230, 126, 34,1.0)',
]

const chartOptions = {
  legend: {
    display: false,
  },
}

const ITEM_UCTS_DECLARED = 'dec'
const ITEM_UCTS_CLOSED = 'clo'
const ITEM_UCTS_CRITICALITY = 'cri'

class UncertaintiesTab extends React.Component {
  selectItems = [
    {
      value: ITEM_UCTS_DECLARED,
      label: this.props.intl.formatMessage({
        id: `kpi.ucts.items.${ITEM_UCTS_DECLARED}`,
      }),
    },
    {
      value: ITEM_UCTS_CLOSED,
      label: this.props.intl.formatMessage({
        id: `kpi.ucts.items.${ITEM_UCTS_CLOSED}`,
      }),
    },
    {
      value: ITEM_UCTS_CRITICALITY,
      label: this.props.intl.formatMessage({
        id: `kpi.ucts.items.${ITEM_UCTS_CRITICALITY}`,
      }),
    },
  ]

  constructor(props) {
    super(props)
    let projects = [new Structure(props.company)]
    projects = projects.concat(props.company.organisations.map(orga => new Structure(orga)))
    this.state = {
      chartData: {},
      projects: projects,
    }
  }

  handleStatUpdate = (periodStart, periodEnd, projects) => {
    console.debug('handleStatUpdate - periodStart', periodStart.toISODate())
    console.debug('handleStatUpdate - periodEnd', periodEnd.toISODate())

    fetchDetailedUncertaintiesStatistics(
      periodStart.toISODate(),
      periodEnd.toISODate(),
      projects.map(({ id }) => id)
    )
      .then(res => {
        return prepareDataSets(res.data)
      })
      .then(chartData => this.setState({ chartData: chartData }))
  }

  componentDidMount() {
    this.handleStatUpdate(
      DateTime.local().minus({ days: 30 }),
      DateTime.local(),
      this.state.projects
    )
  }

  componentWillUnmount() {
    console.debug('UncertaintiesTab will unmount')
  }

  render() {
    return (
      <StatsTab
        colors={colors}
        handleStatsUpdate={this.handleStatUpdate}
        initialItem={ITEM_UCTS_DECLARED}
        items={this.selectItems}
        options={chartOptions}
        data={this.state.chartData}
        projects={this.state.projects}
        increment={this.state.increment}
      />
    )
  }
}

const mapStateToProps = state => ({
  company: state.auth.user.company,
})
export default connect(
  mapStateToProps,
  null
)(injectIntl(UncertaintiesTab))

class Structure {
  id = null
  name = ''
  constructor(orga) {
    this.id = orga.id
    this.name = orga.name
  }
}
