import React from 'react'
import styled from 'styled-components'
import { scoreColors } from 'utils/constants'
import arrow from 'assets/right_arrow.svg'
import { Grid } from '@material-ui/core'

const MatrixChart = ({ data }) => {
  const { weights } = data
  return (
    <Grid container direction="column" alignItems="center">
      <div id="container" style={{ position: 'relative', height: '200px', width: '200px' }}>
        <div style={{ position: 'absolute' }}>
          <StyledTable>
            <tbody>
              <tr key="pl4" className="prob_level-4">
                <td className="moderate">{weights && weights[3][0] !== 0 ? weights[3][0] : ''}</td>
                <td className="critical">{weights && weights[3][1] !== 0 ? weights[3][1] : ''}</td>
                <td className="critical">{weights && weights[3][2] !== 0 ? weights[3][2] : ''}</td>
                <td className="critical">{weights && weights[3][3] !== 0 ? weights[3][3] : ''}</td>
              </tr>
              <tr key="pl3" className="prob_level-3">
                <td className="low">{weights && weights[2][0] !== 0 ? weights[2][0] : ''}</td>
                <td className="moderate">{weights && weights[2][1] !== 0 ? weights[2][1] : ''}</td>
                <td className="critical">{weights && weights[2][2] !== 0 ? weights[2][2] : ''}</td>
                <td className="critical">{weights && weights[2][3] !== 0 ? weights[2][3] : ''}</td>
              </tr>
              <tr key="pl2" className="prob_level-2">
                <td className="low">{weights && weights[1][0] !== 0 ? weights[1][0] : ''}</td>
                <td className="moderate">{weights && weights[1][1] !== 0 ? weights[1][1] : ''}</td>
                <td className="moderate">{weights && weights[1][2] !== 0 ? weights[1][2] : ''}</td>
                <td className="critical">{weights && weights[1][3] !== 0 ? weights[1][3] : ''}</td>
              </tr>
              <tr key="pl1" className="prob_level-1">
                <td className="low">{weights && weights[0][0] !== 0 ? weights[0][0] : ''}</td>
                <td className="low">{weights && weights[0][1] !== 0 ? weights[0][1] : ''}</td>
                <td className="moderate">{weights && weights[0][2] !== 0 ? weights[0][2] : ''}</td>
                <td className="moderate">{weights && weights[0][3] !== 0 ? weights[0][3] : ''}</td>
              </tr>
            </tbody>
          </StyledTable>
        </div>
        <AxisY>
          <span>Impact</span>
        </AxisY>
        <AxisX>
          <span>Probability</span>
        </AxisX>
      </div>
    </Grid>
  )
}

const Axis = styled.div`
  position: absolute;
  bottom: 10px;
  left: 20px;
  width: calc(100% - 40px);
  margin-right: 20px;
  text-align: right;
  color: #474747;
  &::after {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    right: -5px;
    background: url(${arrow}) -5px no-repeat;
    background-size: 20px;
  }
  span {
    font-size: 12px;
    position: relative;
  }
`

const AxisY = styled(Axis)`
  bottom: 202px;
  border-bottom: 1px solid #bdc3c7;
  padding-bottom: 10px;
  -webkit-transform: rotate(270deg) translate(0, -190px);
  transform-origin: bottom right;
  text-align: center;
  text-indent: 50%;
  &::after {
    bottom: -5px;
  }
`

const AxisX = styled(Axis)`
  left: -10px;
  border-top: 1px solid #bdc3c7;
  padding-top: 10px;
  &::after {
    top: -5px;
  }
  span {
    top: -5px;
  }
`

const StyledTable = styled.table`
  border-collapse: collapse;
  td {
    height: 36px;
    width: 36px;
    border: 1px solid grey;
    text-align: center;
    vertical-align: middle;
    font-size: 12px;
  }
  .critical {
    background-color: ${scoreColors.critical};
  }
  .moderate {
    background-color: ${scoreColors.moderate};
  }
  .low {
    background-color: ${scoreColors.low};
  }
`

export default MatrixChart
