import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { actions as teamsActions } from 'reducers/teamsReducer'
import { SortArrow, ClickableComponent, StyledLink } from 'components/common'
import PropTypes from 'prop-types'
import { stopChildEventPropagation } from 'utils/utils'

const HeaderCell = props => (
  <th {...props}>
    <div>{props.children}</div>
  </th>
)
const FlexHeaderCell = styled(HeaderCell)`
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  width: ${props => (props.width ? props.width : 'auto')};
  font-size: 14px;
  font-weight: 600;
  div {
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
  }
`

class TeamsList extends React.PureComponent {
  static propTypes = {
    data: PropTypes.array.isRequired,
    onClickRow: PropTypes.func.isRequired,
    onSort: PropTypes.func.isRequired,
    sorted: PropTypes.any,
  }

  generateTableRow = data => {
    if (data && data.length > 0) {
      return data.map(t => {
        return (
          <ClickableComponent
            component={TableRow}
            key={t.id}
            onClick={this.props.onClickRow(t.id)}
            hover
          >
            {/* <TableRow key={t.id} hover onClick={this.props.onClickRow}> */}
            <TableCell>{t.name}</TableCell>
            <TableCell onClick={stopChildEventPropagation}>
              {t.manager && (
                <StyledLink to={`/users/${t.manager.id}/details`}>
                  {t.manager && `${t.manager.firstname} ${t.manager.lastname}`}
                </StyledLink>
              )}
            </TableCell>
            <TableCell>{t.membersCount}</TableCell>
          </ClickableComponent>
        )
      })
    } else {
      return <TableRow />
    }
  }

  render() {
    const { data: teams, sorted, onSort } = this.props
    return (
      <Table>
        <thead>
          <tr style={{ height: '55px' }}>
            <FlexHeaderCell onClick={onSort('name')}>
              Name <SortArrow property="name" sorted={sorted} />
            </FlexHeaderCell>
            <FlexHeaderCell
              onClick={onSort('manager', 'text', team => team.manager && team.manager.lastname)}
            >
              Team Manager
              <SortArrow property="manager" sorted={sorted} />
            </FlexHeaderCell>
            <FlexHeaderCell onClick={onSort('membersCount', 'number')}>
              Nb members
              <SortArrow property="membersCount" sorted={sorted} />
            </FlexHeaderCell>
          </tr>
        </thead>
        <TableBody>{this.generateTableRow(teams)}</TableBody>
      </Table>
    )
  }
}

export default connect(null, teamsActions)(TeamsList)
