import React, { Component } from 'react'
import styled from 'styled-components'
import { Button, Table, TableBody, TableCell, TableRow } from '@material-ui/core'

import { SortArrow } from 'components/common'
import { FormattedMessage } from 'react-intl'
import { selectBestUserRole } from 'utils/utils'
import { CompareArrows } from '@material-ui/icons'

const FlexHeaderCell = styled((props) => (
  <th {...props}>
    <div>{props.children}</div>
  </th>
))`
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  width: ${(props) => (props.width ? props.width : 'auto')};
  font-size: 14px;
  font-weight: 600;
  div {
    display: flex;
    justify-content: start;
    align-items: center;
  }
`

export default class MembersList extends Component {
  state = {
    dialogs: {
      importResult: false,
      addUser: false,
    },
  }

  generateTableRow = (data) => {
    if (data && data.length > 0) {
      return data.map((u, index) => (
        <TableRow key={index} hover>
          <TableCell padding="normal" sortDirection={false}>
            {u.lastname}
          </TableCell>
          <TableCell padding="none" sortDirection={false}>
            {u.firstname}
          </TableCell>
          <TableCell padding="none" sortDirection={false}>
            {u.team && u.team.name}
          </TableCell>
          <TableCell padding="none" sortDirection={false}>
            <FormattedMessage id={`user.role.${selectBestUserRole(u.roles)}`} defaultMessage=" " />
          </TableCell>
          <TableCell padding="none" sortDirection={false}>
            <Button variant="fab" mini aria-label="Replace">
              <CompareArrows onClick={this.props.onSelectMember(u.id)} />
            </Button>
          </TableCell>
        </TableRow>
      ))
    } else {
      return <TableRow />
    }
  }

  render() {
    const { data: users, sorted, onSort } = this.props
    return (
      <Table>
        <thead>
          <tr style={{ height: '55px' }}>
            <FlexHeaderCell onClick={onSort('lastname')} width="180px">
              Last Name <SortArrow property="lastname" sorted={sorted} />
            </FlexHeaderCell>
            <FlexHeaderCell onClick={onSort('firstname')} width="150px">
              First Name <SortArrow property="firstname" sorted={sorted} />
            </FlexHeaderCell>
            <FlexHeaderCell
              onClick={onSort('team', 'text', (user) => user.team && user.team.name)}
              width="150px"
            >
              Team <SortArrow property="team" sorted={sorted} />
            </FlexHeaderCell>
            <FlexHeaderCell width="80px" onClick={onSort('role')}>
              Role <SortArrow property="role" sorted={sorted} />
            </FlexHeaderCell>
            <FlexHeaderCell padding="none" style={{ width: '40px' }} />
          </tr>
        </thead>
        <TableBody>{this.generateTableRow(users)}</TableBody>
      </Table>
    )
  }
}
