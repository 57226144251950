import { changeAlpha } from 'utils/utils'

export function calculateActivityStats(data) {
  let activity = {
    threat: 0,
    opp: 0,
    pending: 0,
    assigned: 0,
    rejected: 0,
    closed: 0,
  }
  activity = data
    .map((uct) => ({ type: uct.type, status: uct.status }))
    .reduce((acc, curr) => {
      switch (curr.type) {
        case 'threat':
          acc.threat += 1
          break
        case 'opportunity':
          acc.opp += 1
          break
        default:
          break
      }
      switch (curr.status) {
        case 'PENDING_REVIEW':
        case 'PENDING_ASSIGNMENT':
          acc.pending += 1
          break
        case 'ASSIGNED':
          acc.assigned += 1
          break
        case 'CLOSED':
          acc.closed += 1
          break
        default:
          break
      }
      return acc
    }, activity)
  return activity
}

export async function prepareDataSets(serverData) {
  const { labels } = serverData
  const datasets = new Map(Object.entries(serverData.datasets))
  let items = new Map()
  for (const entry of datasets.entries()) {
    const item = entry[0]
    const datas = entry[1]
    const chartData = new ChartData(
      labels,
      datas.map((data, index) => new DataSet(colors[index], data.values, data.projectName))
    )
    items.set(item, chartData)
  }

  return items
}

class ChartData {
  labels = []
  datasets = []
  constructor(labels, datasets) {
    this.labels = labels
    if (datasets) {
      this.datasets = datasets
    }
  }
}

class DataSet {
  fill = false
  lineTension = 0.1
  borderWidth = 4
  borderCapStyle = 'butt'
  borderDash = []
  borderDashOffset = 0.0
  borderJoinStyle = 'miter'
  pointBackgroundColor = '#fff'
  pointBorderWidth = 1
  pointHoverRadius = 5
  pointHoverBorderWidth = 2
  pointRadius = 1
  pointHitRadius = 10
  label
  backgroundColor
  pointBorderColor
  pointHoverBackgroundColor
  data

  constructor(mainColor, values, label) {
    this.backgroundColor = mainColor
    this.borderColor = mainColor
    this.pointBorderColor = mainColor
    this.pointHoverBackgroundColor = changeAlpha(mainColor, 0.5)
    this.pointHoverBorderColor = mainColor
    if (label) {
      this.label = label
    }
    if (values) {
      this.data = values
    }
  }
}

const colors = [
  'rgba(52, 152, 219, 0.8)',
  'rgba(243, 156, 18,1.0)',
  'rgba(155, 89, 182, 0.8)',
  'rgba(46, 204, 113, 0.8)',
  'rgba(241, 196, 15, 0.8)',
  'rgba(26, 188, 156, 0.8)',
  'rgba(52, 73, 94, 0.8)',
  'rgba(231, 76, 60,1.0)',
  'rgba(230, 126, 34,1.0)',
]
