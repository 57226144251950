import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  ClickAwayListener,
  Collapse,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  withStyles,
  Button,
} from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import SubMenuItem from './SubMenuItem'
import { isCompanyManager } from 'utils/utils'
import compose from 'utils/compose'
import clientLogo from 'assets/magnifor-logo-small.png'
import { FlexDiv } from 'components/common'
import { colors } from 'layouts/colors'

class ProfilMenu extends React.PureComponent {
  state = {
    open: false,
    anchorEl: null,
  }

  handleClick = (event) => {
    const { currentTarget } = event
    this.setState((state) => ({
      ...state,
      open: !state.open,
      anchorEl: currentTarget,
    }))
  }

  handleClose = (e) => {
    this.setState({ open: false })
  }

  render() {
    const { open, anchorEl } = this.state
    const { classes, company, roles } = this.props
    return (
      <>
        <Button onClick={this.handleClick} className={classNames(classes.button)}>
          <div>
            {this.props.firstname} {this.props.lastname}
          </div>
          <FlexDiv align="center">
            <img src={clientLogo} height="20px" alt="synergy_logo" />
            <div style={{ marginLeft: 10 }}>{company && company.name}</div>
          </FlexDiv>
        </Button>

        <Popper
          placement="bottom"
          open={open}
          anchorEl={anchorEl}
          transition
          // disablePortal
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={this.handleClose}>
              <Collapse {...TransitionProps} id="menu-list-collapse">
                <Paper style={{ margin: 3 }}>
                  <MenuList role="menu">
                    <Link
                      to={{
                        pathname: '/dashboard',
                        state: { tab: 'settings' },
                      }}
                    >
                      <MenuItem onClick={this.handleClose} className={classNames(classes.menuItem)}>
                        <FormattedMessage id="nav.menu.drop.settings" />
                      </MenuItem>
                    </Link>
                    {isCompanyManager(roles) && (
                      <SubMenuItem caption="Organizations">
                        {company.organisations &&
                          company.organisations.map((orga, index) => {
                            return (
                              <MenuItem key={index} className={classNames(classes.menuItem)}>
                                {orga.name}
                              </MenuItem>
                            )
                          })}
                      </SubMenuItem>
                    )}
                    <MenuItem
                      className={classNames(classes.menuItem)}
                      onClick={(e) => {
                        this.handleClose(e)
                        this.props.onSignout()
                      }}
                    >
                      <FormattedMessage id="nav.menu.drop.logout" />
                    </MenuItem>
                  </MenuList>
                </Paper>
              </Collapse>
            </ClickAwayListener>
          )}
        </Popper>
      </>
    )
  }
}

const styles = {
  menuItem: {
    '&:hover': {
      // Augment text opacity when input is disabled
      color: colors.main,
    },
  },
  button: {
    display: 'block',
    color: colors.text.main,
    textTransform: 'none',
    fontSize: '16px',
    textAlign: 'start',
    '&:hover': {
      color: colors.main,
    },
  },
}

const mapStateToProps = (state) => ({
  firstname: state.auth.user.firstname,
  lastname: state.auth.user.lastname,
  company: state.auth.user.company,
  roles: state.auth.user.roles,
})

export default compose(connect(mapStateToProps), withStyles(styles))(ProfilMenu)
