import React from 'react'
import PropTypes from 'prop-types'
import { teal } from '@material-ui/core/colors'
import Slider from 'rc-slider/lib/Slider'

export default class TaskProgressSlider extends React.PureComponent {
  static propTypes = {
    progress: PropTypes.number,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
  }
  static defaultProps = {
    progress: 0,
    readOnly: false,
    onChange: () => null,
  }
  marks = {
    0: <strong>0</strong>,
    25: '25',
    50: '50',
    75: '75',
    100: {
      // style: {
      //   color: 'green',
      // },
      label: <strong>100%</strong>,
    },
  }

  sliderElementStyle = ({ readOnly }) => ({
    backgroundColor: teal['A400'],
    borderColor: teal['A400'],
    cursor: readOnly ? 'not-allowed' : '',
  })

  render() {
    const { progress, onChange, readOnly } = this.props
    return (
      <Slider
        dots
        min={0}
        marks={this.marks}
        step={25}
        value={progress}
        onChange={onChange}
        trackStyle={[this.sliderElementStyle({ readOnly })]}
        handleStyle={[this.sliderElementStyle({ readOnly })]}
        activeDotStyle={this.sliderElementStyle({ readOnly })}
      />
    )
  }
}
