import { colors } from 'layouts/colors'
import styled from 'styled-components'

const Wing = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: ${props => props.alignItems || 'center'};
  height: 100vh;
  width: 50vw;
  padding: 3rem;
  background-color: ${props => props.color || 'white'};
`
const WingContent = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  justify-content: ${props => props.justifyContent || 'space-between'};
  height: 100%;
  max-height: 700px;
  max-width: 700px;
`
const Container = styled.div`
  display: flex;
`
const Title = styled.h1`
  color: ${colors.main};
  font-size: 1.2rem;
  text-align: left;
`
const Baseline = styled.p`
  color: white;
  font-size: 2rem;
  text-align: left;
  max-width: 320px;
  line-height: 2.5rem;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  max-height: 200px;
  height: 50%;
  justify-content: space-around;
`
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`
const leftFooterStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
}

const rightFooterStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}

const ErrorMessage = styled.div`
  color: #e74c3c;
  background-color: #ecf0f1;
  box-shadow: 1px 1px 2px #7f8c8d;
  font-weight: 400;
  padding: 1rem;
  margin-bottom: 8px;
`

export {
  Baseline,
  Buttons,
  Container,
  ErrorMessage,
  leftFooterStyle,
  rightFooterStyle,
  Form,
  Title,
  Wing,
  WingContent,
}
