import React, { useEffect, useMemo, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import throttle from 'lodash.throttle'
import { searchUsers } from 'services/api'
import { makeStyles } from '@material-ui/core'
import { teal } from '@material-ui/core/colors'

const isPopulated = (value) => !!value

export default ({ input, meta, ...rest }) => {
  const { onChange, value } = input
  const [options, setOptions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        searchUsers(request).then(callback)
      }, 400),
    []
  )
  const classes = useStyles()

  const handleAutocompleteChange = (event, newValue, reason) => {
    setOptions(newValue ? [newValue, ...options] : options)
    onChange && onChange(newValue)
  }

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue)
  }

  useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return
    }

    fetch(inputValue.replace(' ', ','), ({ data }) => {
      console.debug('result', data)
      if (active) {
        let newOptions = []

        if (value) {
          newOptions = [value]
        }

        if (data) {
          newOptions = [...newOptions, ...data]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  return (
    <Autocomplete
      id="asynchronous-demo"
      style={{ width: 300 }}
      getOptionLabel={(option) => [option.firstname, option.lastname].filter(isPopulated).join(' ')}
      options={options}
      disableClearable={!value || value.length === 0}
      noOptionsText={'No user found'}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={handleAutocompleteChange}
      onInputChange={handleInputChange}
      size="small"
      classes={{ root: classes.textFieldRoot }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            size="small"
            variant="outlined"
            fullWidth
            label={rest.label}
            InputLabelProps={{
              ...params.InputLabelProps,
              shrink: true,
            }}
          />
        )
      }}
    />
  )
}

const inputFontSize = '12px'
const labelFontSize = '14px'
const useStyles = makeStyles((theme) => ({
  controlRoot: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  selectRoot: {
    marginTop: theme.spacing(0),
  },
  textFieldRoot: {
    fontFamily: "'Open Sans', sans-serif",
    'label + &': {
      marginTop: theme.spacing(1),
    },
    padding: 0,
  },
  textFieldInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #bdc3c7',
    fontSize: inputFontSize,
    padding: '5px 6px',
    // width: 'calc(100% - 12px)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: teal['A200'],
    },
  },
  textFieldInputError: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid ' + theme.palette.error.main,
    fontSize: inputFontSize,
    padding: '5px 6px',
    // width: 'calc(100% - 12px)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
  label: {
    fontSize: labelFontSize * 4,
  },
  labelFormControl: {
    position: 'relative',
  },
  labelShrink: {
    transform: 'none', //Prevent text from being shrunk
  },
}))
