export const GET_TEAMS = 'mgmt/team/GET_TEAMS'
export const GET_TEAMS_SUCCESS = 'mgmt/team/GET_TEAMS_SUCCESS'
export const GET_TEAMS_FAILURE = 'mgmt/team/GET_TEAMS_FAILURE'

export const ADD_TEAM = 'mgmt/team/ADD_TEAM'
export const ADD_TEAM_SUCCESS = 'mgmt/team/ADD_TEAM_SUCCESS'
export const ADD_TEAM_FAILURE = 'mgmt/team/ADD_TEAM_FAILURE'

export const DELETE_TEAM = 'mgmt/team/DELETE_TEAM'
export const DELETE_TEAM_SUCCESS = 'mgmt/team/DELETE_TEAM_SUCCESS'
export const DELETE_TEAM_FAILURE = 'mgmt/team/DELETE_TEAM_FAILURE'

const initialState = {
  data: [],
  error: null,
  loading: false,
}

export default function teamsReducer(state = initialState, action) {
  if (action.type.indexOf('mgmt/team/') !== 0) {
    return state
  }
  switch (action.type) {
    case GET_TEAMS:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case GET_TEAMS_SUCCESS:
      return {
        ...state,
        data: action.result.data,
        error: null,
        loading: false,
      }
    case GET_TEAMS_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
      }
    case ADD_TEAM_SUCCESS:
      return {
        ...state,
        data: action.result.data,
        loading: false,
      }
    case ADD_TEAM_FAILURE:
    case DELETE_TEAM_SUCCESS:
    case DELETE_TEAM_FAILURE:
    default:
      return state
  }
}

const ROOT_URL = process.env.REACT_APP_API_URL

export function getTeams() {
  return {
    types: [GET_TEAMS, GET_TEAMS_SUCCESS, GET_TEAMS_FAILURE],
    promise: client => client.get(`${ROOT_URL}/manage/teams/`),
  }
}

export function saveTeam({ id, name, manager }) {
  console.debug('saveTeam: ' + name + ' - ' + manager + ' - ' + id)
  return {
    types: [ADD_TEAM, ADD_TEAM_SUCCESS, ADD_TEAM_FAILURE],
    promise: client => client.post(`${ROOT_URL}/manage/teams/`, { id, name, manager }),
  }
}

export function deleteTeam(id) {
  return {
    types: [ADD_TEAM, ADD_TEAM_SUCCESS, ADD_TEAM_FAILURE],
    promise: client => client.delete(`${ROOT_URL}/manage/teams/${id}`),
  }
}

export const actions = {
  getTeams,
  saveTeam,
  deleteTeam,
}
