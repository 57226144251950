import { push } from 'connected-react-router'
import { actions as teamsActions } from './teamsReducer'

const GET_USERS = 'mgmt/GET_USERS'
const GET_USERS_SUCCESS = 'mgmt/GET_USERS_SUCCESS'
const GET_USERS_FAILURE = 'mgmt/GET_USERS_FAILURE'

const ADD_USER = 'mgmt/ADD_USER'
const ADD_USER_SUCCESS = 'mgmt/ADD_USER_SUCCESS'
const ADD_USER_FAILURE = 'mgmt/ADD_USER_FAILURE'

const IMPORT_USERS = 'mgmt/IMPORT_USERS'
const IMPORT_USERS_SUCCESS = 'mgmt/IMPORT_USERS_SUCCESS'
const IMPORT_USERS_FAILURE = 'mgmt/IMPORT_USERS_FAILURE'
const OPEN_USER = 'mgmt/OPEN_USER'
const CLOSE_IMPORT_RESULT_DIALOG = 'mgmt/CLOSE_IMPORT_RESULT_DIALOG'

const initialState = {
  data: [],
  importResult: null,
  loading: false,
  userid: null,
}

export default function usersReducer(state = initialState, action) {
  if (action.type.indexOf('mgmt/') !== 0) {
    return state
  }
  switch (action.type) {
    case GET_USERS:
    case IMPORT_USERS:
      return {
        ...state,
        loading: true,
      }
    case GET_USERS_SUCCESS:
    case ADD_USER_SUCCESS:
      return {
        ...state,
        data: action.result.data,
        loading: false,
      }
    case IMPORT_USERS_SUCCESS: {
      return {
        ...state,
        importResult: action.result.data,
        loading: false,
      }
    }
    case OPEN_USER: {
      return {
        ...state,
        userid: action.payload,
      }
    }
    case IMPORT_USERS_FAILURE:
    case GET_USERS_FAILURE:
    case ADD_USER_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case CLOSE_IMPORT_RESULT_DIALOG: {
      return {
        ...state,
        importResult: null,
      }
    }
    default:
      return state
  }
}

const ROOT_URL = process.env.REACT_APP_API_URL

function getUsers() {
  return {
    types: [GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAILURE],
    promise: client => client.get(`${ROOT_URL}/manage/users/`),
  }
}

function addUser({ id, email, team, role, firstname, lastname }) {
  return {
    types: [ADD_USER, ADD_USER_SUCCESS, ADD_USER_FAILURE],
    promise: client =>
      client.post(`${ROOT_URL}/manage/users/`, {
        id,
        email,
        team: { id: team },
        role,
        firstname,
        lastname,
      }),
    afterSuccess: dispatch => dispatch(teamsActions.getTeams()),
  }
}

function importUsers(file) {
  const data = new FormData()
  data.append('file', file)
  return {
    types: [IMPORT_USERS, IMPORT_USERS_SUCCESS, IMPORT_USERS_FAILURE],
    promise: client => client.post(`${ROOT_URL}/manage/users/`, data),
    afterSuccess: () => {
      this.getUsers()
    },
  }
}

function closeImportResultDialog() {
  return { type: CLOSE_IMPORT_RESULT_DIALOG }
}

function openUserDetail(id) {
  return (dispatch, getState) => {
    dispatch({ type: OPEN_USER, payload: id })
    dispatch(push('/users/details'))
  }
}

export const actions = {
  addUser,
  getUsers,
  importUsers,
  closeImportResultDialog,
  openUserDetail,
}
