import { DateTime } from 'luxon'

export function formatsToIsoDate(text) {
  if (text) {
    return DateTime.fromISO(text).toISODate()
  }
}

export function formatToIsoDateWithFormat(text, format) {
  if (text && format) {
    return DateTime.fromFormat(text, format).toISODate()
  }
}
