import React from 'react'
import { Button } from '@material-ui/core'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

const StyledLink = styled(Link)`
  color: inherit;
  margin: 0 1rem;
  font-weight: 600;
`

class AuthenticationRequiredDialog extends React.Component {
  state = {
    open: this.props.display,
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Authentication required'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please sign in to access further content.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              <StyledLink to="/login">Sign in</StyledLink>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default AuthenticationRequiredDialog
