import React, { useEffect, useState } from 'react'
import { Pie } from 'react-chartjs-2'

import {
  Grid,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
} from '@material-ui/core'
import { scoreColors } from 'utils/constants'
import { DateTime } from 'luxon'
import MatrixLegend from '../../MatrixLegend'
import MatrixTable from '../../Matrix/MatrixTable'
import { fetchMonthlyReportStats } from 'services/api'
import StatBloc from '../../StatBloc.synergy'
import MonthlyReportTable from './MonthlyReportTable'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  outlinedInput: {
    padding: '5px 10px',
    minWidth: 150,
  },
  select: {
    width: 300,
  },
}))

const generateSelectableMonths = () => {
  const months = []
  for (let i = 12; i >= 1; i--) {
    months.push(DateTime.utc().minus({ month: i }))
  }
  return months
}

export default () => {
  const classes = useStyles()

  const [pieData, setPieData] = useState({ label: [], datasets: [] })
  const [currentMonthData, setCurrentMonthData] = useState({})
  const [previousMonthData, setPreviousMonthData] = useState({})
  const [selectableMonths] = useState(generateSelectableMonths())
  const [selectedMonthIndex, setSelectedMonthIndex] = useState(selectableMonths.length - 1)

  useEffect(() => {
    const selectedMonthDate = selectableMonths[selectedMonthIndex]
    fetchMonthlyReportStats(
      selectedMonthDate.startOf('month').toISODate(),
      selectedMonthDate.endOf('month').toISODate()
    ).then(({ data }) => {
      const dataForPie = {
        labels: ['critical', 'moderate', 'low'],
        datasets: [
          {
            data: [data.current.criticalCount, data.current.moderateCount, data.current.lowCount],
            backgroundColor: [scoreColors.critical, scoreColors.moderate, scoreColors.low],
            hoverOffset: 8,
          },
        ],
      }
      setCurrentMonthData({
        ...data.current,
        uncertainties: data.declarations,
        weights: data.weights,
      })
      setPreviousMonthData(data.previous)
      setPieData(dataForPie)
    })
  }, [selectableMonths, selectedMonthIndex])

  const handleChangeSelectedMonth = (evt) => {
    const selectedMonth = evt.target.value
    setSelectedMonthIndex(selectedMonth)
  }

  const generateSelectableMonthsItems = () => {
    return selectableMonths.map((m, idx) => (
      <MenuItem value={idx} key={idx}>{`${m.monthLong} (${m.startOf('month').toISODate()} - ${m
        .endOf('month')
        .toISODate()})`}</MenuItem>
    ))
  }

  return (
    <Grid container item xs={10} justifyContent="space-around" spacing={2}>
      <Grid container item alignItems="baseline" xs={12} spacing={2}>
        <Grid item>
          <Typography variant="h6">Monthly Risk Management indicators</Typography>
        </Grid>
        <Grid item>
          <Select
            value={selectedMonthIndex}
            onChange={handleChangeSelectedMonth}
            className={classes.select}
            input={
              <OutlinedInput
                id="period-selector"
                notched={false}
                classes={{ input: classes.outlinedInput }}
              />
            }
          >
            {generateSelectableMonthsItems()}
          </Select>
        </Grid>
      </Grid>
      <Grid item container xs={12} md={6} spacing={2}>
        <Grid item xs={6}>
          <StatBloc currentData={currentMonthData.declarations} title="Uncertainties declared" />
        </Grid>
        <Grid item xs={6}>
          <StatBloc
            currentData={currentMonthData.declarationsClosed}
            previousData={previousMonthData.declarationsClosed}
            title="Uncertainties closed"
          />
        </Grid>
        <Grid item xs={6}>
          <StatBloc
            currentData={currentMonthData.closedTasksCount}
            previousData={previousMonthData.closedTasksCount}
            title="Tasks Completed"
          />
        </Grid>
        <Grid item xs={6}>
          <StatBloc
            currentData={currentMonthData.averageCriticality}
            previousData={previousMonthData.averageCriticality}
            title="Average criticality"
            postAdornment="%"
          />
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Paper>
          <Pie
            data={pieData}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              aspectRatio: 1.5,
              plugins: {
                legend: {
                  position: 'right',
                },
                labels: {
                  // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
                  render: 'full',
                  fontSize: 14,
                  fontColor: '#fff',
                  fontStyle: 'bold',
                },
              },
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container justifyContent="space-around" spacing={4}>
            <Grid item xs={10}>
              <Typography variant="h6">Current overview of declared uncertainties</Typography>
            </Grid>
            <Grid item>
              <MatrixTable data={currentMonthData} />
            </Grid>
            <Grid item>
              <MatrixLegend data={currentMonthData} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">New uncertainties declared - Details</Typography>
            </Grid>
            <Grid item xs={12}>
              <MonthlyReportTable uncertainties={currentMonthData.uncertainties} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
