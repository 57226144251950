import React from 'react'
import { Button } from '@material-ui/core'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

const PaswordRequestConfirmationDialog = ({ display, onClose }) => {
  return (
    <div>
      <Dialog
        open={display}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Request successful'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your password reset request has been submitted. If your account was found, an email was
            sent with directions.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PaswordRequestConfirmationDialog
