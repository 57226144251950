import React from 'react'
import styled from 'styled-components'
import fileDownload from 'js-file-download'
import { colors, Grid, Paper } from '@material-ui/core'

import * as api from 'services/api'
import { Bloc } from 'components/common'
import DocumentList from 'components/Uncertainty/Declare/DocumentList'
import DeclaredRiskForm from './DeclaredRiskForm.synergy'

class DeclaredRiskContainer extends React.Component {
  onSubmit = (values) => {
    this.props.onSubmit(values)
  }

  handleDocumentOpen(documentId) {
    return function (evt) {
      evt.preventDefault()
      api.downloadDocument(documentId).then((res) => {
        const fileName = res.headers['x-filename']
        fileDownload(res.data, fileName, 'application/zip')
      })
    }
  }

  render() {
    const { user, assignedTo, reviewedBy } = this.props.data
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper variant="outlined">
            <DeclaredRiskForm
              toggleEditMode={this.props.toggleEditMode}
              onSubmit={this.onSubmit}
              initialValues={this.props.data}
              readOnly={this.props.readOnly}
              forConsultation={this.props.forConsultation}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Bloc
            title="Informations"
            contentProps={{
              height: '238px',
              overflowY: 'auto',
              fontSize: '16px',
              padding: '20px',
            }}
          >
            <div style={{ color: '#6e6e6e' }}>Author:</div>
            <div style={{ color: colors.main, fontWeight: 600 }}>
              {user && user.firstname} {user && user.lastname}
              {user && user.team && ', ' + user.team.name}
            </div>
            <div style={{ color: '#6e6e6e', marginTop: '1rem' }}>Reviewed by:</div>
            <div style={{ color: 'inherit', fontWeight: 600 }}>
              {reviewedBy && `${reviewedBy.firstname} ${reviewedBy.lastname}`}
            </div>
            <div style={{ color: '#6e6e6e', marginTop: '1rem' }}>Assigned to:</div>
            <div style={{ color: 'inherit', fontWeight: 600 }}>
              {(assignedTo && `${assignedTo.firstname} ${assignedTo.lastname}`) || 'Unassigned'}
              {assignedTo &&
                assignedTo.team &&
                `(${assignedTo.team.name} 
                    )`}
            </div>
          </Bloc>
        </Grid>
        <Grid item xs={6}>
          <Bloc
            title="Documents"
            contentProps={{ height: '238px', overflowY: 'auto' }}
            style={{ flex: 1 }}
          >
            <DocumentListStyled
              documents={this.props.data.documents}
              onClick={this.handleDocumentOpen}
              dense={true}
              hoverable={true}
            />
          </Bloc>
        </Grid>
      </Grid>
    )
  }
}

export const BlocContent = styled.div`
  background-color: white;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
`

const DocumentListStyled = styled(DocumentList)`
  font-size: 12px !important;
`

export default DeclaredRiskContainer
