import React from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  TextField,
  withStyles,
} from '@material-ui/core'
import { teal } from '@material-ui/core/colors'
import styled from 'styled-components'
//TODO add proptypes

const inputFontSize = '12px'
const labelFontSize = '14px'
const styles = (theme) => ({
  controlRoot: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  dateRoot: {
    width: '100%',
  },
  selectRoot: {
    // width: '100%',
    marginTop: theme.spacing(0),
  },
  textFieldRoot: {
    // width: '100%',
    fontFamily: "'Open Sans', sans-serif",
    'label + &': {
      marginTop: theme.spacing(1),
    },
  },
  textFieldInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #bdc3c7',
    fontSize: inputFontSize,
    padding: '5px 6px',
    // width: 'calc(100% - 12px)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: teal['A200'],
    },
  },
  textFieldInputError: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid ' + theme.palette.error.main,
    fontSize: inputFontSize,
    padding: '5px 6px',
    // width: 'calc(100% - 12px)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
  datePickerInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #bdc3c7',
    fontSize: inputFontSize,
    padding: '5px 6px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: teal['A200'],
    },
  },
  datePickerInputError: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid ' + theme.palette.error.main,
    fontSize: inputFontSize,
    padding: '5px 6px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
  label: {
    fontSize: labelFontSize,
  },
  labelFormControl: {
    position: 'relative',
  },
  labelShrink: {
    transform: 'none', //Prevent text from being shrunk
  },
  inputMultiline: {
    boxSizing: 'border-box',
  },
  multiline: {
    padding: 0,
  },
  formControl: {
    margin: '0.5rem',
    width: '100%',
  },
})

export const TextFieldBordered = withStyles(styles)(({ classes, label, error, ...custom }) => {
  return (
    <TextField
      multiline={true}
      label={label}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.textFieldRoot,
          input: error ? classes.textFieldInputError : classes.textFieldInput,
          multiline: classes.multiline,
          inputMultiline: classes.inputMultiline,
        },
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.label,
          shrink: classes.labelShrink,
          formControl: classes.labelFormControl,
        },
      }}
      FormHelperTextProps={{
        margin: null,
      }}
      classes={{
        root: classes.controlRoot,
      }}
      error={error}
      {...custom}
    />
  )
})

export const DatePickerFieldBordered = withStyles(styles)(
  ({ classes, label, error, ...custom }) => {
    return (
      <TextField
        multiline={false}
        label={label}
        type="date"
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.textFieldRoot,
            input: error ? classes.datePickerInputError : classes.datePickerInput,
            multiline: classes.multiline,
            inputMultiline: classes.inputMultiline,
          },
        }}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.label,
            shrink: classes.labelShrink,
            formControl: classes.labelFormControl,
          },
        }}
        FormHelperTextProps={{
          marginTop: '4px',
        }}
        classes={{
          root: classes.dateRoot,
        }}
        error={error}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ min: custom.min, max: custom.max }}
        {...custom}
      />
    )
  }
)

export const SelectBordered = withStyles(styles)(({ classes, input, children, ...rest }) => {
  return (
    <Select
      {...input}
      onChange={rest.onChange || input.onChange}
      value={rest.currentValue || input.value}
      children={children}
      classes={{
        root: classes.selectRoot,
      }}
      input={
        <Input
          disableUnderline
          classes={{
            root: classes.textFieldRoot,
            input: classes.textFieldInput,
          }}
        />
      }
      {...rest}
    />
  )
})

export const StyledFormControl = styled(FormControl)`
  width: ${(props) => (props.width ? props.widh : props.fullWidth ? '100%' : 'auto')} !important;
`
// export const StyledFormControl = withStyles(styles)(props => (
//   <FormControl className={props.classes.formControl}>{props.children}</FormControl>
// ))

export const StyledFormControlLabel = withStyles(styles)(
  ({ classes, children, component, ...rest }) => (
    <FormControlLabel {...rest}>{children}</FormControlLabel>
  )
)

export const StyledFormLabel = withStyles(styles)(({ classes, children, ...otherProps }) => (
  <FormLabel classes={{ root: classes.label }} {...otherProps}>
    {children}
  </FormLabel>
))

export const StyledButton = styled(Button)`
  && {
    width: ${(props) => (props.width ? props.width : 'auto')};
    font-weight: 600;
    font-size: 1rem;
    text-transform: none;
  }
`

export const renderTextField = ({
  input,
  label,
  meta: { touched, error, submitError },
  ...custom
}) => {
  return (
    <TextFieldBordered
      label={label}
      error={!!((touched && error) || submitError)}
      helperText={(error && touched) || submitError ? error || submitError : ''}
      {...input}
      {...custom}
    />
  )
}

export const renderDatePickerField = ({
  input,
  label,
  meta: { touched, error, submitError },
  ...custom
}) => {
  return (
    <DatePickerFieldBordered
      label={label}
      error={!!((touched && error) || submitError)}
      helperText={(error && touched) || submitError ? error || submitError : ''}
      {...input}
      {...custom}
    />
  )
}

export const renderFormControlSelect = ({
  children,
  label,
  meta,
  controlProps,
  input,
  ...rest
}) => {
  return (
    <StyledFormControl
      error={!!((meta.touched && meta.error) || meta.submitError)}
      {...controlProps}
    >
      <StyledFormLabel component="label" disabled={rest.disabled}>
        {label}
      </StyledFormLabel>
      <SelectBordered meta={meta} input={input} {...rest}>
        {children}
      </SelectBordered>
      {!!((meta.error && meta.touched) || meta.submitError) && (
        <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
      )}
    </StyledFormControl>
  )
}

const StyledFormHelperText = withStyles({
  root: {
    fontSize: '14px',
  },
})(FormHelperText)

export const renderFormErrorMessage = (errorMessage) => {
  return (
    <StyledFormHelperText error={true} filled={true}>
      {errorMessage}
    </StyledFormHelperText>
  )
}
