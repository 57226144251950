import React from 'react'
import { withRouter } from 'react-router'
import { DateTime } from 'luxon'
import { Badge, Bloc, ClickableComponent } from 'components/common'
import { Grid } from '@material-ui/core'
import ProgressBar from '@ramonak/react-progress-bar'

const formatAssignedTo = (assignee) => {
  if (assignee) {
    const { team, firstname = '', lastname = '' } = assignee
    return (
      <>
        <span>{`${firstname.charAt(0)}. ${lastname}`}</span>
        {team && <span>({team.name})</span>}
      </>
    )
  } else {
    return <></>
  }
}
class DedicatedTasksContainer extends React.PureComponent {
  openDialog = () => {
    return null
  }

  handleClickItem = (id) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.history.push(`/dashboard/tasks/${id}`)
  }
  generateTaskList = (tasks) => {
    if (!tasks || tasks.length === 0) {
      return <div>No tasks available</div>
    }
    return tasks.reverse().map((task, index) => (
      <ClickableComponent
        component={Grid}
        container
        item
        xs={12}
        key={index}
        justfyContent="space-between"
        style={{
          border: '1px solid aquablue',
          color: '#6e6e6e',
          fontSize: '13px',
          padding: '10px 0',
        }}
        spacing={1}
        onClick={this.handleClickItem(task.id)}
      >
        <Grid item xs={2}>
          <span style={{ color: '#54698d', fontSize: '14px', fontWeight: 600, minWidth: '120px' }}>
            {task.reference}
          </span>
        </Grid>
        <Grid item xs={10} container spacing={1}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={5}>
                <span>
                  <strong>Title: </strong>
                  <span>{task.title}</span>
                </span>
              </Grid>
              <Grid item xs={3}>
                <span>
                  <strong>Assigned to : </strong>
                  {formatAssignedTo(task.assignee)}
                </span>
              </Grid>
              <Grid item xs={3}>
                <span>
                  <strong>Due date : </strong>
                  {DateTime.fromISO(task.duedate).toISODate()}
                </span>
              </Grid>
              <Grid item xs={1} container justifyContent="flex-end">
                <Badge backgroundColor="rgb(29, 233, 182)">{task.status}</Badge>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} container justifyContent="space-between">
            <Grid item xs={10} container spacing={0}>
              <Grid item xs={12}>
                <strong>Description</strong>
              </Grid>
              <Grid item xs={12}>
                {task.description}
              </Grid>
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end">
              <ProgressBar
                completed={task.progress}
                height="20px"
                width="80px"
                borderRadius="4px"
                labelColor="#fff"
                baseBgColor="rgba(41, 128, 185,0.3)"
                bgColor="rgb(41, 128, 185)"
                labelSize="12px"
                labelAlignment="left"
              />
            </Grid>
          </Grid>
        </Grid>
      </ClickableComponent>
    ))
  }

  render() {
    return (
      <Bloc contentProps={{ height: '348px', overflowY: 'auto' }}>
        <Grid container>{this.generateTaskList(this.props.data)}</Grid>
      </Bloc>
    )
  }
}

export default withRouter(DedicatedTasksContainer)
