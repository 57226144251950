import React from 'react'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { Link } from 'react-router-dom'
import { Button, TextField } from '@material-ui/core'
import { Buttons, ErrorMessage, Form as StyledForm } from './.'
import PasswordStrengthMeter from './PasswordStrengthMeter'

const validate = values => {
  const errors = {}
  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = 'Required'
  }
  if (!values.password) {
    errors.password = 'Required'
  }
  if (
    values.password &&
    values.passwordConfirmation &&
    !!values.passwordConfirmation.localeCompare(values.password)
  ) {
    errors.passwordConfirmation = 'Password must match'
  }
  return errors
}

const CreatePassword = props => {
  const onSubmit = async values => {
    const error = await props.onSubmit(values)
    return { [FORM_ERROR]: error.message }
  }
  const { initialValues } = props
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({
        submitError,
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
        dirtySinceLastSubmit,
        submitFailed,
        form,
        values,
      }) => {
        // Reset form with current values to reset form "submitFailed"
        // status and remove error message
        if (dirtySinceLastSubmit) form.reset(values)
        return (
          <StyledForm
            onSubmit={handleSubmit}
            style={{ minHeight: '300px' }}
            initialValues={initialValues}
          >
            {/* Display error message */}
            {submitFailed && submitError && <ErrorMessage>{submitError}</ErrorMessage>}
            {/* <Field id="token" name="token" type="hidden" component="input" /> */}

            <Field
              name="password"
              required
              render={({ input, meta: { touched, error } }) => (
                <TextField
                  id="password"
                  type="password"
                  label="PASSWORD"
                  autoComplete="off"
                  error={!!(touched && error)}
                  helperText={touched && error ? error.toString() : ''}
                  {...input}
                  onChange={e => {
                    input.onChange(e)
                    props.passwordCheck(e)
                  }}
                />
              )}
            />
            <PasswordStrengthMeter strengthCalculator={props.passwordStrengthLevel} />
            <Field
              name="passwordConfirmation"
              required
              render={({ input, meta: { touched, error } }) => (
                <TextField
                  id="passwordConfirmation"
                  type="password"
                  label="PASSWORD CONFIRMATION"
                  autoComplete="off"
                  error={!!(touched && error)}
                  helperText={touched && error ? error.toString() : ''}
                  {...input}
                />
              )}
            />
            <Buttons>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting || pristine || hasValidationErrors}
              >
                Activate
              </Button>
              <Button color="primary" component={Link} to="/">
                Cancel
              </Button>
            </Buttons>
          </StyledForm>
        )
      }}
    />
  )
}

export default CreatePassword
