import React from 'react'
import { connect } from 'react-redux'
import { Field, formValues, formValueSelector, reduxForm, SubmissionError } from 'redux-form'
import {
  Button,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  MenuItem,
  Radio,
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { animateScroll as scroll } from 'react-scroll'
import { FormattedMessage, injectIntl } from 'react-intl'

import DocumentList from './DocumentList'
import { FlexDiv } from 'components/common'
import {
  renderCheckbox,
  renderFormRadioControl,
  renderRadioGroup,
  renderTextField,
  renderFormControlSelect,
  StyledFormControlLabel,
  StyledFormLabel,
  FormSection,
  FormSectionDesc,
  FormSectionLeftSide,
  FormSectionRightSide,
  FormSectionTitle,
  FormTitle,
  Paragraph,
  RadioBox,
  Section,
  MenuTitle,
} from './common/FormComponents'

import {
  COMPANY_PROJECTS,
  RISK_TYPES_TECHNICAL,
  RISK_TYPES_MANAGEMENT,
  RISK_TYPES_COMMERCIAL,
  RISK_TYPES_EXTERNAL,
  UCT_TYPE_TH,
  UCT_TYPE_OP,
} from 'components/Uncertainty/constants'

const validate = (values) => {
  const errors = {}
  /** Field position in array is important for the scrollToTop function */
  const requiredFields = [
    'type',
    'responsability',
    'topic',
    'description',
    'title',
    'cause',
    'occurence',
    'suggestion',
  ]
  if (!values.obCosts && !values.obGuidelines && !values.obQuality && !values.obTime) {
    errors.objectives = 'Required'
  }

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })

  if (values.obTime && !values.impact1) {
    errors.impact1 = 'Required'
  }
  if (values.obCosts && !values.impact2) {
    errors.impact2 = 'Required'
  }
  if (values.obQuality && !values.impact3) {
    errors.impact3 = 'Required'
  }
  if (values.obGuidelines && !values.impact4) {
    errors.impact4 = 'Required'
  }
  return errors
}

const FORM_NAME = 'DeclareForm'

class DeclareForm extends React.Component {
  _handleSave = (e) => {
    e.preventDefault()
    this.props.onSave().catch((err) => {
      if (err && err instanceof SubmissionError) scrollToFirstFieldInError(err.errors)
    })
  }

  _openFileDialog = (e) => this.upload.click()

  _generateCompanyProjectItems = (compProjects = []) =>
    compProjects.map((v, index) => (
      <MenuItem value={v} key={index}>
        <FormattedMessage id={`uct.projectPackage.label.${v}`} />
      </MenuItem>
    ))

  _generateRiskTypeItems = (riskTypes = []) => {
    return riskTypes.map((rT, index) => (
      <MenuItem value={rT} key={index}>
        <FormattedMessage id={`uct.types.item.label.${rT}`} />
      </MenuItem>
    ))
  }

  render() {
    const { handleSubmit, submitting, intl } = this.props
    if (this.props.initialValues)
      return (
        <form
          style={{ display: 'flex', flexFlow: 'column' }}
          onSubmit={handleSubmit}
          initialvalues={this.props.initialValues}
        >
          <Section background="#ecf0f1">
            <FormTitle>
              <FormattedMessage id={'forms.utc.declare.title'} />
            </FormTitle>
            <div>
              <Paragraph>
                <FormattedMessage id={'forms.utc.declare.intro'} />
              </Paragraph>
              <div style={{ fontWeight: 600, margin: '0.5rem' }}>
                <FormattedMessage id={'forms.utc.declare.nature.label'} />
              </div>
              <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
                <RadioBox>
                  <div className="radio">
                    <Field component={renderRadioGroup} name="type" aria-label="type">
                      <Radio
                        color="primary"
                        value={UCT_TYPE_TH}
                        aria-label="threat"
                        disabled={!this.props.isNewUncertainty}
                      />
                    </Field>
                  </div>
                  <div className="description-wrapper">
                    <span style={{ fontWeight: '600' }}>
                      <FormattedMessage id={'forms.utc.declare.nature.title.threat'} />
                    </span>
                    <div className="description">
                      <FormattedMessage id={'forms.utc.declare.nature.desc.threat'} />
                    </div>
                  </div>
                </RadioBox>
                <RadioBox>
                  <div className="radio">
                    <Field component={renderRadioGroup} name="type" aria-label="type">
                      <Radio
                        color="primary"
                        value={UCT_TYPE_OP}
                        aria-label="opportunity"
                        disabled={!this.props.isNewUncertainty}
                      />
                    </Field>
                  </div>
                  <div className="description-wrapper">
                    <span style={{ fontWeight: '600' }}>
                      <FormattedMessage id={'forms.utc.declare.nature.title.opportunity'} />
                    </span>
                    <div className="description">
                      <FormattedMessage id={'forms.utc.declare.nature.desc.opportunity'} />
                    </div>
                  </div>
                </RadioBox>
              </div>
            </div>
          </Section>
          <FormSection>
            {/* col 1 */}
            <FormSectionLeftSide>
              <FormSectionTitle>
                <FormattedMessage
                  id={`forms.utc.declare.section.describe.title.${this.props.type}`}
                />
              </FormSectionTitle>
              <FormSectionDesc>
                <FormattedMessage
                  id={`forms.utc.declare.section.describe.desc.${this.props.type}`}
                />
              </FormSectionDesc>
            </FormSectionLeftSide>
            {/* col 2 */}
            <FormSectionRightSide>
              <Field component={renderObjectives} name="objectives" />
              <FormControl component="div" style={{ margin: '0.5rem 0' }}>
                <Field
                  name="responsability"
                  component={renderFormControlSelect}
                  label={intl.formatMessage({
                    id: 'forms.utc.declare.projectpackage.label',
                  })}
                  controlProps={{
                    component: 'div',
                    margin: 'dense',
                    fullWidth: true,
                  }}
                  margin="dense"
                >
                  {this._generateCompanyProjectItems(COMPANY_PROJECTS)}
                </Field>
              </FormControl>
              <FormControl component="div" style={{ margin: '0.5rem 0' }}>
                <Field
                  name="topic"
                  component={renderFormControlSelect}
                  label={intl.formatMessage({
                    id: `forms.utc.declare.type.label.${this.props.type}`,
                  })}
                  controlProps={{
                    component: 'div',
                    margin: 'dense',
                    fullWidth: true,
                  }}
                  margin="dense"
                >
                  <MenuTitle>
                    <FormattedMessage id={`uct.types.group.label.tec`} />
                  </MenuTitle>
                  {this._generateRiskTypeItems(RISK_TYPES_TECHNICAL)}
                  <MenuTitle>
                    <FormattedMessage id={`uct.types.group.label.man`} />
                  </MenuTitle>
                  {this._generateRiskTypeItems(RISK_TYPES_MANAGEMENT)}
                  <MenuTitle>
                    <FormattedMessage id={`uct.types.group.label.com`} />
                  </MenuTitle>
                  {this._generateRiskTypeItems(RISK_TYPES_COMMERCIAL)}
                  <MenuTitle>
                    <FormattedMessage id={`uct.types.group.label.ext`} />
                  </MenuTitle>
                  {this._generateRiskTypeItems(RISK_TYPES_EXTERNAL)}
                </Field>
              </FormControl>
              <Field
                name="description"
                component={renderTextField}
                placeholder={intl.formatMessage({
                  id: `forms.utc.declare.description.placeholder.${this.props.type}`,
                })}
                minRows="4"
                maxRows="4"
                label="Description"
                margin="dense"
                multiline={true}
              />
            </FormSectionRightSide>
          </FormSection>
          <FormSection>
            <FormSectionLeftSide>
              <FormSectionTitle>
                <FormattedMessage id={`forms.utc.declare.section.name.title.${this.props.type}`} />
              </FormSectionTitle>
              <FormSectionDesc>
                <FormattedMessage id={`forms.utc.declare.section.name.desc.${this.props.type}`} />
              </FormSectionDesc>
            </FormSectionLeftSide>
            <FormSectionRightSide>
              <Field
                name="title"
                component={renderTextField}
                placeholder="Ex: Subcontractor not able to deliver on time"
                label="Pick a descriptive title"
                margin="dense"
                multiline={false}
                inputProps={{ maxLength: 100 }}
              />
            </FormSectionRightSide>
          </FormSection>
          <FormSection>
            <FormSectionLeftSide>
              <FormSectionTitle>
                <FormattedMessage id="forms.utc.declare.section.cause.title" />
              </FormSectionTitle>
              <FormSectionDesc>
                <FormattedMessage id={`forms.utc.declare.section.cause.desc.${this.props.type}`} />
              </FormSectionDesc>
            </FormSectionLeftSide>
            <FormSectionRightSide>
              <Field
                minRows="4"
                multiline
                name="cause"
                placeholder="Ex: Subcontractor operating in overcapacity"
                label={intl.formatMessage({
                  id: `forms.utc.declare.cause.label.${this.props.type}`,
                })}
                component={renderTextField}
                fullWidth
              />
            </FormSectionRightSide>
          </FormSection>
          <FormSection>
            <FormSectionLeftSide>
              <FormSectionTitle>
                <FormattedMessage id="forms.utc.declare.section.assess.title" />
              </FormSectionTitle>
              <FormSectionDesc>
                <FormattedMessage id={`forms.utc.declare.section.assess.desc.${this.props.type}`} />
              </FormSectionDesc>
            </FormSectionLeftSide>
            <FormSectionRightSide>
              <Field
                name="impactDescription"
                component={renderTextField}
                placeholder={intl.formatMessage({
                  id: `forms.utc.declare.impact.description.placeholder`,
                })}
                minRows="4"
                maxRows="4"
                $
                label={intl.formatMessage({
                  id: `forms.utc.declare.impact.description.label`,
                })}
                margin="dense"
                multiline={true}
              />
              <Field
                component={renderFormRadioControl}
                name="impact1"
                aria-label="schedule"
                label={intl.formatMessage({
                  id: 'forms.utc.declare.impact.schedule.label',
                })}
                disabled={!this.props.obTime}
              >
                <FormControlLabel
                  value="imp11"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.imp.schedule.item.label.imp11',
                  })}
                />
                <FormControlLabel
                  value="imp12"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.imp.schedule.item.label.imp12',
                  })}
                />
                <FormControlLabel
                  value="imp13"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.imp.schedule.item.label.imp13',
                  })}
                />
                <FormControlLabel
                  value="imp14"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.imp.schedule.item.label.imp14',
                  })}
                />
              </Field>
              <Field
                component={renderFormRadioControl}
                name="impact2"
                aria-label="costs"
                label={intl.formatMessage({
                  id: 'forms.utc.declare.impact.costs.label',
                })}
                disabled={!this.props.obCosts}
              >
                <FormControlLabel
                  value="imp21"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.imp.costs.item.label.imp21',
                  })}
                />
                <FormControlLabel
                  value="imp22"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.imp.costs.item.label.imp22',
                  })}
                />
                <FormControlLabel
                  value="imp23"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.imp.costs.item.label.imp23',
                  })}
                />
                <FormControlLabel
                  value="imp24"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.imp.costs.item.label.imp24',
                  })}
                />
              </Field>
              <Field
                component={renderFormRadioControl}
                name="impact3"
                aria-label="quality"
                label={intl.formatMessage({
                  id: 'forms.utc.declare.impact.quality.label',
                })}
                disabled={!this.props.obQuality}
              >
                <FormControlLabel
                  value="imp31"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.imp.quality.item.label.imp31',
                  })}
                />
                <FormControlLabel
                  value="imp32"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.imp.quality.item.label.imp32',
                  })}
                />
                <FormControlLabel
                  value="imp33"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.imp.quality.item.label.imp33',
                  })}
                />
                <FormControlLabel
                  value="imp34"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.imp.quality.item.label.imp34',
                  })}
                />
              </Field>
              <Field
                component={renderFormRadioControl}
                name="impact4"
                aria-label="HSSE"
                label={intl.formatMessage({
                  id: 'forms.utc.declare.impact.hsse.label',
                })}
                disabled={!this.props.obGuidelines}
              >
                <FormControlLabel
                  value="imp41"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.imp.hsse.item.label.imp41',
                  })}
                />
                <FormControlLabel
                  value="imp42"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.imp.hsse.item.label.imp42',
                  })}
                />
                <FormControlLabel
                  value="imp43"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.imp.hsse.item.label.imp43',
                  })}
                />
                <FormControlLabel
                  value="imp44"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.imp.hsse.item.label.imp44',
                  })}
                />
              </Field>
              <Field
                component={renderFormRadioControl}
                name="occurence"
                aria-label="occurence"
                label={intl.formatMessage({
                  id: 'forms.utc.declare.impact.occurence.label',
                })}
              >
                <FormControlLabel
                  value="o1"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.occ.item.label.o1',
                  })}
                />
                <FormControlLabel
                  value="o2"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.occ.item.label.o2',
                  })}
                />
                <FormControlLabel
                  value="o3"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.occ.item.label.o3',
                  })}
                />
                <FormControlLabel
                  value="o4"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'utc.occ.item.label.o4',
                  })}
                />
              </Field>
            </FormSectionRightSide>
          </FormSection>
          <FormSection>
            <FormSectionLeftSide>
              <FormSectionTitle>
                <FormattedMessage id="forms.utc.declare.section.suggestion.title" />
              </FormSectionTitle>
              <FormSectionDesc>
                <FormattedMessage
                  id={`forms.utc.declare.section.suggestion.desc.${this.props.type}`}
                />
              </FormSectionDesc>
            </FormSectionLeftSide>
            <FormSectionRightSide>
              <Field
                name="suggestion"
                component={renderTextField}
                placeholder="Ex: Organize workshop with contractors and production team to review production capacity"
                minRows="4"
                label={intl.formatMessage({
                  id: 'forms.utc.declare.suggestion.label',
                })}
                multiline
              />
            </FormSectionRightSide>
          </FormSection>
          <FormSection>
            <FormSectionLeftSide />
            <FormSectionRightSide>
              <FlexDiv>
                <div style={{ marginRight: '2rem' }}>Attach a document</div>
                <Fab aria-label="add_file" onClick={this._openFileDialog}>
                  <AddIcon />
                </Fab>
                <input
                  name="document"
                  type="file"
                  ref={(el) => (this.upload = el)}
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    this.props.onDocumentUpload(e.target.files[0])
                    e.target.value = ''
                  }}
                />
              </FlexDiv>
              <FlexDiv>
                <Documents onRemove={this.props.onDocumentRemove} />
              </FlexDiv>
            </FormSectionRightSide>
          </FormSection>
          <FormSection justifyContent="flex-end">
            <div style={{ flex: 1, maxWidth: '20rem' }} />
          </FormSection>
          <FormSection justifyContent="flex-end">
            <div style={{ flex: 1, maxWidth: '20rem' }} />
            <div
              style={{
                display: 'flex',
                flexFlow: 'row nowrap',
                marginLeft: '5rem',
                flex: 1,
                alignItems: 'center',
              }}
            >
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting}
              >
                <FormattedMessage id={'forms.utc.declare.buttons.submit.label'} />
              </Button>
              <Button
                size="large"
                color="primary"
                variant="outlined"
                style={{ margin: '0 2rem', backgroundColor: 'white' }}
                disabled={submitting}
                onClick={this._handleSave}
              >
                <FormattedMessage id={'forms.utc.declare.buttons.save.label'} />
              </Button>
            </div>
          </FormSection>
        </form>
      )
  }
}

const renderObjectives = ({ meta: { touched, error } }) => (
  <FormControl
    component="div"
    style={{ margin: '0 0 0.5rem' }}
    error={!!(touched && error)}
    name="objectives"
  >
    <StyledFormLabel component="label">
      <FormattedMessage id="forms.utc.declare.impacts.objectives.label" />
    </StyledFormLabel>
    <FormGroup style={{ margin: '0 0.5rem' }}>
      <StyledFormControlLabel
        control={<Field name="obTime" type="checkbox" color="primary" component={renderCheckbox} />}
        label="Finish on time"
      />
      <StyledFormControlLabel
        control={
          <Field type="checkbox" name="obCosts" color="primary" component={renderCheckbox} />
        }
        label="Budget"
      />
      <StyledFormControlLabel
        control={
          <Field type="checkbox" name="obQuality" color="primary" component={renderCheckbox} />
        }
        label="Quality"
      />
      <StyledFormControlLabel
        control={
          <Field type="checkbox" name="obGuidelines" color="primary" component={renderCheckbox} />
        }
        label="Respect HSE guidelines"
      />
    </FormGroup>
    <FormHelperText>{touched && error ? error.toString() : ' '}</FormHelperText>
  </FormControl>
)

const Documents = formValues('documents')(DocumentList)

function scrollToFirstFieldInError(errors) {
  if (!errors) return
  const fieldName = Object.keys(errors)[0]
  console.debug('fieldName in error', fieldName)
  if (document.querySelectorAll(`[name="${fieldName}"]`).length) {
    const element = document.querySelector(`[name="${fieldName}"]`)
    const position = getPosition(element)
    console.debug('element in error position', position)
    scroll.scrollTo(position.y - 110, {
      smooth: true,
      duration: 500,
    })
    element.focus({ preventScroll: true })
  }
}

function getPosition(el) {
  let xPos = 0
  let yPos = 0
  while (el) {
    // for all other non-BODY elements
    xPos += el.offsetLeft - el.scrollLeft + el.clientLeft
    yPos += el.offsetTop - el.scrollTop + el.clientTop
    el = el.offsetParent
  }
  return { x: xPos, y: yPos }
}

DeclareForm = reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
  touchOnBlur: false,
  onSubmitFail: (errors, dispatch) => {
    console.error('Submit failed. Error:', errors) // <-- always undefined
    scrollToFirstFieldInError(errors)
  },
})(DeclareForm)

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state) => ({
  type: selector(state, 'type'),
  obTime: selector(state, 'obTime'),
  obCosts: selector(state, 'obCosts'),
  obQuality: selector(state, 'obQuality'),
  obGuidelines: selector(state, 'obGuidelines'),
})

export default injectIntl(connect(mapStateToProps)(DeclareForm))
