import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import theme from './theme'

const GlobalStyles = createGlobalStyle`
	* {
	  font-family: 'Open Sans', sans-serif;
	}

  a {
      color:inherit;
      text-decoration: none;
      &:hover {
          cursor: pointer;
      }
  }

  h2 {
      font-weight: 300;
      font-size: 2.5rem;
      margin: 1rem 0 ;
  }

  h1 {
      font-size: 3rem;
      margin: 0.8rem 0 1.3rem;
  }
`

export default ({ children }) => (
  <div id="layout">
    <GlobalStyles />
    <MuiThemeProvider theme={theme}>
      <>
        <CssBaseline />
        {children}
      </>
    </MuiThemeProvider>
  </div>
)
