import axios from 'axios'

const ROOT_URL = process.env.REACT_APP_API_URL

export async function fetchUsers() {
  return axios.get(`${ROOT_URL}/manage/users/`)
}

export async function fetchTeamMembers(teamId) {
  return axios.get(`${ROOT_URL}/teams/${teamId}/members`)
}

export async function fetchTeamStatistics(teamId) {
  return axios.get(`${ROOT_URL}/manage/teams/${teamId}/statistics`)
}

export async function fetchTeamUncertainties(teamId) {
  return axios.get(`${ROOT_URL}/manage/teams/${teamId}/uncertainties`)
}

export async function addTeamMembers(teamId, memberIds) {
  return axios.post(`${ROOT_URL}/manage/teams/${teamId}/members/add`, memberIds)
}

export async function fetchCurrentCompanyTeams() {
  return axios.get(`${ROOT_URL}/teams/`)
}

export async function fetchUncertainties(id) {
  return axios.get(`${ROOT_URL}/user/${id}/uncertainties`)
}

export async function fetchManagerNotifications(type) {
  return axios.get(`${ROOT_URL}/manage/dashboard/notifications`, {
    params: {
      type: type,
    },
  })
}

export async function dismissManagerNotifications(ids) {
  return axios.post(`${ROOT_URL}/manage/dashboard/notifications`, [...ids])
}

export async function fetchKPIOverview() {
  return axios.get(`${ROOT_URL}/manage/dashboard/statistics/overview`)
}

export async function fetchKPIDetailed(periodStart, periodEnd) {
  return axios.get(
    encodeURI(
      `${ROOT_URL}/manage/dashboard/statistics/detailed?pstart=${encodeURIComponent(
        periodStart
      )}&pend=${encodeURIComponent(periodEnd)}`
    )
  )
}

export async function fetchUncertainty(id) {
  return axios.get(`${ROOT_URL}/uncertainties/${id}`)
}

export async function fetchTimeline(id) {
  return axios.get(`${ROOT_URL}/uncertainties/${id}/timeline`)
}

export async function exportUncertainties(filters) {
  return axios({
    method: 'post',
    url: `${ROOT_URL}/manage/uncertainties/export`,
    responseType: 'arraybuffer',
    data: filters,
  })
}

export async function fetchTask(id) {
  return axios.get(`${ROOT_URL}/tasks/${id}`)
}

export async function updateTaskProgress({ id, progress, comment }) {
  return axios.post(`${ROOT_URL}/tasks/${id}`, { id, progress, comment })
}

export async function deleteUncertainty(id) {
  return axios.delete(`${ROOT_URL}/uncertainties/${id}`)
}

export function activateUserAccount(values) {
  const { token, password, passwordConfirmation } = values
  return axios.post(`${ROOT_URL}/user/activation`, {
    token,
    password,
    passwordConfirmation,
  })
}

export function resetAccountPassword({ email }) {
  return axios.post(`${ROOT_URL}/user/reset?email=${email}`)
}

export function downloadDocument(documentId) {
  return axios({
    method: 'get',
    url: `${ROOT_URL}/documents/${documentId}`,
    responseType: 'arraybuffer',
  })
}

export async function fetchAssignedRisks() {
  return axios.get(`${ROOT_URL}/uncertainties/assigned`)
}

export async function assignTeamManager({ teamId, managerId }) {
  return axios.post(`${ROOT_URL}/manage/teams/${teamId}/manager/assign/${managerId}`)
}

export async function requestReleaseUncertainty(id) {
  return axios.post(`${ROOT_URL}/manage/uncertainties/${id}/release`)
}

export async function requestLockUncertainty(id) {
  return axios.post(`${ROOT_URL}/manage/uncertainties/${id}/lock`)
}

export async function fetchDetailedUncertaintiesStatistics(periodStart, periodEnd, ...projects) {
  return axios.get(
    `${ROOT_URL}/manage/dashboard/statistics/ucts?pstart=${encodeURIComponent(
      periodStart
    )}&pend=${encodeURIComponent(periodEnd)}&projects=${encodeURIComponent(projects.join(','))}`
  )
}

export async function fetchDetailedTasksStatistics(periodStart, periodEnd, ...projects) {
  return axios.get(
    `${ROOT_URL}/manage/dashboard/statistics/tsks?pstart=${encodeURIComponent(
      periodStart
    )}&pend=${encodeURIComponent(periodEnd)}&projects=${encodeURIComponent(projects.join(','))}`
  )
}

export async function fetchDetailedContributorssStatistics(periodStart, periodEnd, ...projects) {
  return axios.get(
    `${ROOT_URL}/manage/dashboard/statistics/cbtrs?pstart=${encodeURIComponent(
      periodStart
    )}&pend=${encodeURIComponent(periodEnd)}&projects=${encodeURIComponent(projects.join(','))}`
  )
}

export async function searchUsers(terms) {
  return axios.get(`${ROOT_URL}/users/autocomplete`, { params: { terms: terms } })
}

export async function fetchWeeklyReportStats(periodStart, periodEnd) {
  return axios.get(
    encodeURI(
      `${ROOT_URL}/statistics/reports/weekly?pstart=${encodeURIComponent(
        periodStart
      )}&pend=${encodeURIComponent(periodEnd)}`
    )
  )
}

export async function fetchMonthlyReportStats(periodStart, periodEnd) {
  return axios.get(
    encodeURI(
      `${ROOT_URL}/statistics/reports/monthly?pstart=${encodeURIComponent(
        periodStart
      )}&pend=${encodeURIComponent(periodEnd)}`
    )
  )
}
