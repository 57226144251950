import React from 'react'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import { FlexDiv, SortArrow } from 'components/common'
import { FormattedMessage } from 'react-intl'
import { colors } from 'layouts/colors'

class UncertaintiesList extends React.PureComponent {
  state = {
    expandedPanelId: null,
  }

  togglePanelExpansion = id => {
    this.setState(state => {
      return {
        ...state,
        expandedPanelId: this.isRowSelected(id, state) ? null : id,
      }
    })
  }

  isRowSelected(id, state) {
    return state.expandedPanelId && state.expandedPanelId === id
  }

  listImpactedObjectives(uncertainty) {
    return Object.keys(uncertainty)
      .filter(k => k.indexOf('ob') === 0)
      .map((k, index) => {
        return (
          <span key={index} style={{ paddingRight: '4px' }}>
            <FormattedMessage id={`uct.impacted.objectives.label.${k}`} />
            {';'}
          </span>
        )
      })
  }

  generateTableRow = data => {
    if (data && data.length > 0) {
      return data.map((n, index) => (
        <React.Fragment key={`fragment-${index}`}>
          <RegisterTableRow key={n.id} onClick={() => this.togglePanelExpansion(n.id)}>
            <td>{n.reference}</td>
            <td classes={{ root: this.props.classes.cellRoot }}>{n.title}</td>
            <td classes={{ root: this.props.classes.cellRoot }}>
              {DateTime.fromISO(n.created).toISODate()}
            </td>
            <td classes={{ root: this.props.classes.cellRoot }}>
              {DateTime.fromISO(n.lastModified).toISODate()}
            </td>
            <td classes={{ root: this.props.classes.cellRoot }}>
              <FormattedMessage id={`uct.status.${n.status}`} />
            </td>
            <td classes={{ root: this.props.classes.cellRoot }}>
              <FormattedMessage id={`uct.level.${n.level}`} defaultMessage=" " />
            </td>
          </RegisterTableRow>
          <ExpandableTableRow
            key={`${n.id}-1`}
            classes={{ root: this.props.classes.rowRoot }}
            expanded={
              this.state.expandedPanelId && this.state.expandedPanelId === n.id ? 'true' : undefined
            }
          >
            <td colSpan="6">
              <FlexDiv direction="column" align="start">
                <FlexDiv direction="row" margins="0.5rem" width="100%">
                  <FlexDiv maxWidth="300px">DESCRIPTION</FlexDiv>
                  <FlexDiv>{n.description}</FlexDiv>
                </FlexDiv>
                <FlexDiv direction="row" margins="0.5rem" width="100%">
                  <FlexDiv maxWidth="300px">IMPACTED OBJECTIVES</FlexDiv>
                  <FlexDiv>{this.listImpactedObjectives(n)}</FlexDiv>
                </FlexDiv>
                <FlexDiv direction="row" margins="0.5rem" width="100%">
                  <FlexDiv maxWidth="300px">PROJECT PACKAGES</FlexDiv>
                  <FlexDiv>
                    <FormattedMessage id={`uct.projectPackage.label.${n.responsability}`} />
                  </FlexDiv>
                </FlexDiv>
                <FlexDiv direction="row" margins="0.5rem" width="100%">
                  <FlexDiv maxWidth="300px">TYPE</FlexDiv>
                  <FlexDiv>
                    <FormattedMessage id={`uct.type.label.${n.type}`} />
                  </FlexDiv>
                </FlexDiv>
                <FlexDiv direction="row" margins="0.5rem" width="100%">
                  <FlexDiv maxWidth="300px">CAUSE</FlexDiv>
                  <FlexDiv>{n.cause}</FlexDiv>
                </FlexDiv>
                <FlexDiv direction="row" margins="0.5rem" width="100%">
                  <FlexDiv maxWidth="300px">AUTHORS</FlexDiv>
                  <FlexDiv>
                    {n.user.firstname} {n.user.lastname}
                  </FlexDiv>
                </FlexDiv>
                <FlexDiv direction="row" margins="0.5rem" width="100%">
                  <FlexDiv maxWidth="300px">STRATEGY</FlexDiv>
                  <FlexDiv>{n.description}</FlexDiv>
                </FlexDiv>
                <FlexDiv direction="row" margins="0.5rem" width="100%">
                  <StyledLink
                    margins="0.5rem 0"
                    color={colors.main}
                    to={`/uncertainties/${n.id}/details`}
                  >
                    {'View details and edit'}
                  </StyledLink>
                </FlexDiv>
              </FlexDiv>
            </td>
          </ExpandableTableRow>
        </React.Fragment>
      ))
    } else {
      return <tr />
    }
  }

  render() {
    const { data, onSort, sorted } = this.props
    return (
      <FlexDiv direction="column" align="start">
        <TableStyled width="100%">
          <thead>
            <tr style={{ height: '55px' }}>
              <FlexHeaderCell style={{ width: '12%' }} onClick={onSort('reference')}>
                Reference <SortArrow property="reference" sorted={sorted} />
              </FlexHeaderCell>
              <FlexHeaderCell onClick={onSort('title')}>
                Title <SortArrow property="title" sorted={sorted} />
              </FlexHeaderCell>
              <FlexHeaderCell style={{ width: '12%' }} onClick={onSort('created', 'date')}>
                Recorded <SortArrow property="created" sorted={sorted} />
              </FlexHeaderCell>
              <FlexHeaderCell style={{ width: '12%' }} onClick={onSort('lastModified', 'date')}>
                Last updated <SortArrow property="lastModified" sorted={sorted} />
              </FlexHeaderCell>
              <FlexHeaderCell style={{ width: '15%' }} onClick={onSort('status')}>
                Status <SortArrow property="status" sorted={sorted} />
              </FlexHeaderCell>
              <FlexHeaderCell style={{ width: '10%' }} onClick={onSort('level')}>
                Level <SortArrow property="level" sorted={sorted} />
              </FlexHeaderCell>
            </tr>
          </thead>
          <tbody>{this.generateTableRow(data)}</tbody>
        </TableStyled>
      </FlexDiv>
    )
  }
}

const FlexHeaderCell = styled(props => (
  <th {...props}>
    <div>{props.children}</div>
  </th>
))`
  text-align: left;
  vertical-align: middle;
  width: ${props => (props.width ? props.width : 'auto')};
  font-weight: 600;
  font-size: 14px;
  div {
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
  }
`

const styles = {
  rowHover: {
    cursor: 'pointer',
  },
  cellRoot: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
}

const StyledLink = styled(Link)`
  color: ${props => (props.color ? props.color : 'inherit')};
  margin: ${props => (props.margins ? props.margins : '0 1rem')};
  font-weight: 700;
`

const ExpandableTableRow = styled.tr`
  height: ${props => (props.expanded ? '' : '0')} !important;
  max-height: ${props => (props.expanded ? '300px' : '0')} !important;
  transition: height 0.2s ease !important;
  overflow: hidden !important;
  td,
  div {
    max-height: ${props => (props.expanded ? '' : '0')} !important;
    display: ${props => (props.expanded ? '' : 'none')};
    overflow: hidden !important;
  }
`

const RegisterTableRow = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.07);
  }
`

const TableStyled = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 13px;
  tbody {
    border: 1px solid rgba(224, 224, 224, 1);
  }
  thead {
    font-weight: 600;
  }
  tr {
    border-color: 'rgba(224, 224, 224, 1)';
  }
  td,
  th {
    vertical-align: middle;
    text-align: left;
    padding: 1rem;
  }
  td {
    border: 1px solid rgba(224, 224, 224, 1);
  }
`

export default withStyles(styles)(UncertaintiesList)
