import React from 'react'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import {
  Bloc,
  ButtonIcon,
  FlexDiv,
  MainTitle,
  SearchBar,
  StyledLink,
  withPaging,
  withSorting,
} from 'components/common'
import TeamDetailsForm from './TeamDetailForm'
import TeamDetailChart from './TeamDetailChart'
import { actions } from 'reducers/teamsDetailReducer'
import DeclarationsList from 'components/Dashboard/MyDeclarations/DeclarationsList'
import { fetchUsers, fetchTeamUncertainties, addTeamMembers } from 'services/api'
import { calculateActivityStats } from 'services/statistics'
import FullPageOverlayLoader from '../../../common/FullPageOverlayLoader'
import MembersList from './MembersList'
import { AddCircleOutlined } from '@material-ui/icons'
import AddTeamMemberDialog from './AddTeamMemberDialog'
import { contains } from 'utils/utils'

const SortingPagingList = withSorting(withPaging(DeclarationsList))
const SortingPagingMembersList = withSorting(withPaging(MembersList))

class TeamDetailPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      teamid: props.match.params.id,
      uncertainties: [],
      filteredMembers: [],
      filteredUncertainties: [],
      activity: {
        risk: 0,
        opp: 0,
        assigned: 0,
        pending: 0,
        rejected: 0,
        closed: 0,
      },
      users: [],
      displayAddMemberDialog: false,
    }
  }

  onSubmit = (values) => {
    this.props.updateDetails(this.state.teamid, values)
  }

  handleSearch = (filter) => {
    this.setState((state) => ({
      ...state,
      filteredUncertainties: state.uncertainties.filter(filter),
    }))
  }

  handleSearchMember = (filter) => {
    this.setState((state, props) => ({
      ...state,
      filteredMembers: props.details.members.filter(filter),
    }))
  }

  handleClickRow = (uncertaintyId) => (event) => {
    event.preventDefault()
    if (this.props.roles && contains('RISK_MANAGER', this.props.roles)) {
      this.props.history.push(`/uncertainties/${uncertaintyId}/details`)
    } else {
      this.props.history.push(`/dashboard/uncertainties/${uncertaintyId}`)
    }
  }

  handleClickRowMember = (userId) => (event) => {
    event.preventDefault()
    this.props.history.push(`/users/${userId}/details`)
  }

  openTeamMemberSelectionDialog = () => {
    this.setState({ displayAddMemberDialog: true })
  }

  closeTeamMemberSelectionDialog = () => {
    this.setState({ displayAddMemberDialog: false })
  }

  _getUserById = (id) => {
    return this.state.users.filter((u) => u.id === id)[0]
  }

  // _checkIfTeamMemberEligible = user => {
  //   if (user.team) {
  //     if (user.team.id !== this.props.teamId) {
  //       console.log('User is already assigned to a team, are you sure ?')
  //     }
  //     if (hasRole('TEAM_MANAGER', user)) {
  //       console.log('Cannot assign another team manager')
  //     }
  //   }
  // }

  _filterAvailableUserForSelection = () => {
    const availableUsers = this.state.users.filter(
      (user) =>
        (!user.team || user.team.id !== this.props.details.id) &&
        !contains('RISK_MANAGER', user.roles)
    )
    return availableUsers
  }

  handleAddMembers = async (memberIds) => {
    await addTeamMembers(this.state.teamid, memberIds)
    await this.props.fetchDetails(this.state.teamid)
    this.setState({ filteredMembers: this.props.details.members })
    this.closeTeamMemberSelectionDialog()
  }

  // handleSelectTeamMember = id => {
  // const selectedUser = this._getUserById(id)
  // this._checkIfTeamMemberEligible(selectedUser)
  // }

  async componentDidMount() {
    await this.props.fetchDetails(this.state.teamid)
    fetchTeamUncertainties(this.state.teamid).then((result) =>
      this.setState((state) => ({
        ...state,
        uncertainties: result.data,
        filteredUncertainties: result.data,
        activity: calculateActivityStats(result.data),
      }))
    )
    fetchUsers().then((res) => {
      this.setState({ users: res.data })
    })
    this.setState({ filteredMembers: this.props.details.members })
  }

  render() {
    const { details, loading } = this.props
    const { activity, displayAddMemberDialog, filteredUncertainties, filteredMembers, users } =
      this.state
    return (
      <React.Fragment>
        {loading && <FullPageOverlayLoader />}
        {displayAddMemberDialog && (
          <AddTeamMemberDialog
            open={displayAddMemberDialog}
            users={this._filterAvailableUserForSelection()}
            onClose={this.closeTeamMemberSelectionDialog}
            // onSelectTeamMember={this.handleSelectTeamMember}
            onConfirmTeamMembers={this.handleAddMembers}
          />
        )}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div style={{ color: '#666666', fontSize: '14px' }}>
              <StyledLink to="/users">List of teams</StyledLink>
              {` 
                  ${String.fromCharCode(187)} 
                  ${details.name}
                `}
            </div>
          </Grid>
          <Grid item xs={12}>
            <MainTitle size="medium">{`${details.name}`}</MainTitle>
          </Grid>
          <Grid item xs={6}>
            <Bloc id="details_bloc" title="Team details">
              <TeamDetailsForm
                onSubmit={this.onSubmit}
                initialValues={details}
                users={users}
                teamId={this.state.teamid}
              />
            </Bloc>
          </Grid>
          <Grid item xs={6}>
            <Bloc id="activity_bloc" title="Activity">
              <div id="activity_charts" style={{ height: '250px', width: '75%' }}>
                <TeamDetailChart height={'100px'} data={{ ...activity }} />
              </div>
              <FlexDiv id="activity_stats">
                <FlexDiv
                  direction="column"
                  style={styles.borderRounded}
                  margins="1rem"
                  height="100px"
                >
                  <FlexDiv>{activity.risk}</FlexDiv>
                  <FlexDiv>Risk declared</FlexDiv>
                </FlexDiv>
                <FlexDiv
                  direction="column"
                  style={styles.borderRounded}
                  margins="1rem"
                  height="100px"
                >
                  <FlexDiv>{activity.opp}</FlexDiv>
                  <FlexDiv>Opportunities declared</FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </Bloc>
          </Grid>
          <Grid item xs={12}>
            <Bloc title="Team members">
              {/* <FlexDiv justifyContent="space-between" align="center"> */}
              <FlexDiv justifyContent="space-between" align="center">
                <SearchBar
                  onSearch={this.handleSearchMember}
                  onFields={['firstname', 'lastname', 'email']}
                  placeholder="Search by title, status, ..."
                />

                <ButtonIcon icon={AddCircleOutlined} onClick={this.openTeamMemberSelectionDialog}>
                  Add Member
                </ButtonIcon>
              </FlexDiv>
              <SortingPagingMembersList
                initialSort={{ property: 'created', type: 'date', asc: false }}
                data={filteredMembers}
                onClickRow={this.handleClickRowMember}
              />
            </Bloc>
          </Grid>
          <Grid item xs={12}>
            <Bloc title="Declared uncertainties">
              <SearchBar
                onSearch={this.handleSearch}
                onFields={['title', 'status', 'reference']}
                placeholder="Search by title, status, ..."
              />
              <SortingPagingList
                initialSort={{ property: 'created', type: 'date', asc: false }}
                data={filteredUncertainties}
                onClickRow={this.handleClickRow}
              />
            </Bloc>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

const styles = {
  borderRounded: {
    backgroundColor: 'white',
    padding: '1rem',
    marginLeft: '1rem',
    border: '1px solid #ecf0f1',
    borderRadius: '4px',
  },
}
const mapStateToProps = (state) => ({
  loading: state.teamsDetail.loading,
  details: state.teamsDetail.details,
  roles: state.auth.user.roles,
})

export default connect(mapStateToProps, actions)(TeamDetailPage)
