import axios from 'axios'
import * as Sentry from '@sentry/browser'

const setupAxiosInterceptors = (onUnauthenticated, OnUnauthorized) => {
  const onRequestSuccess = config => {
    config.timeout = 20000
    config.withCredentials = true
    return config
  }
  const onResponseSuccess = response => response
  const onResponseError = error => {
    const status =
      error.response && error.response.status
        ? error.response.status
        : error.status || 'server unreachable'
    let errorCode = null
    try {
      errorCode = error.response.data.code
      console.error('errorCode :' + errorCode)
    } catch (e) {
      /*ignore error*/
    }
    Sentry.captureException(error)

    // If no customize error message code, we use global method
    if (!errorCode) {
      switch (status) {
        case 401:
          onUnauthenticated('authentication.required')
          break
        case 403:
          console.warn('Unauthorized access - access denied')
          OnUnauthorized()
          break
        default:
          console.warn(`Unmanaged http code: ${status}`)
      }
    }
    return Promise.reject(error)
  }
  axios.interceptors.request.use(onRequestSuccess)
  axios.interceptors.response.use(onResponseSuccess, onResponseError)
}

export { setupAxiosInterceptors }
