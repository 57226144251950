import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@material-ui/core'
import { ErrorMessage } from 'components/Login/common'
import { renderSelect, renderTextField } from 'components/Staff/common/FormComponents'
import { FlexDiv } from 'components/common'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { hasRole } from 'utils/utils'

const validate = values => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Required'
  }
  return errors
}

const generateList = users => {
  return users
    .filter(u => !hasRole('TEAM_MANAGER', u))
    .map(u => {
      return (
        <MenuItem key={u.id} value={u.id}>
          {u.firstname} {u.lastname}
        </MenuItem>
      )
    })
}

class AddTeamDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      name: '',
      manager: { id: '' },
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      id: nextProps.edit.id || '',
      name: nextProps.edit.name || '',
      manager: nextProps.edit.manager || { id: '' },
    })
  }

  render() {
    const { open, onClose, onSubmit, edit } = this.props
    return (
      <Dialog open={open}>
        <FlexDiv width="30rem" margins="0 auto">
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={edit}
            render={({
              submitError,
              handleSubmit,
              submitting,
              dirtySinceLastSubmit,
              submitFailed,
              form,
              values,
            }) => {
              // Reset form with current values to reset form "submitFailed"
              // status and remove error message
              if (dirtySinceLastSubmit) form.reset(values)
              return (
                <form onSubmit={handleSubmit} style={{ minHeight: '300px', width: '100%' }}>
                  <DialogTitle id="team-creation-dialog-title">Team Information</DialogTitle>
                  <DialogContent>
                    {/* Display error message */}
                    {submitFailed && submitError && <ErrorMessage>{submitError}</ErrorMessage>}
                    <Field id="f_team_id" name="id" type="hidden" component="input" />
                    <Field
                      id="f_team_name"
                      name="name"
                      label="Name"
                      required
                      render={renderTextField}
                    />
                    <Field
                      id="f_team_manager"
                      label="Manager"
                      name="manager"
                      required
                      render={renderSelect}
                    >
                      {generateList(this.props.users)}
                    </Field>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={onClose} color="primary">
                      Cancel
                    </Button>
                    <Button type="submit" color="primary" variant="contained" disabled={submitting}>
                      Ok
                    </Button>
                  </DialogActions>
                </form>
              )
            }}
          />
        </FlexDiv>
      </Dialog>
    )
  }
}

export default AddTeamDialog
