import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { NavLink, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import ProfilMenu from './ProfilMenu'
import { FlexDiv } from 'components/common'
import { actions } from 'reducers/authenticationReducer'

import logo from 'assets/magnifor-logo.png'
import { hasAtLeastOneRole, isCompanyManager } from 'utils/utils'
import { colors } from 'layouts/colors'

const Header = (props) => (
  <StyledDiv>
    <div
      style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        maxWidth: 1200,
        margin: 'auto',
        heigth: 80,
      }}
    >
      <Nav>
        <Link to="/">
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
            }}
          >
            <Logo width="80px" alt="Risk Focus logo" />
            <span style={{ marginTop: 10, marginLeft: 10, fontWeight: 600 }}>Risk Focus</span>
          </div>
        </Link>
        <FlexDiv justifyContent="flex-end">
          {!isCompanyManager(props.roles) && (
            <StyledNavLink
              to="/declare"
              onClick={(e) => (props.currentLocation === '/declare' ? e.preventDefault() : null)}
            >
              <FormattedMessage id="nav.menu.declare" />
            </StyledNavLink>
          )}
          <StyledNavLink
            to="/dashboard"
            onClick={(e) => (props.currentLocation === '/dashboard' ? e.preventDefault() : null)}
          >
            <FormattedMessage id="nav.menu.mydashboard" />
          </StyledNavLink>

          <SecuredNavLink
            path={{ pathname: '/uncertainties', state: { fromMenu: true } }}
            roles={props.roles}
            authorized={['RISK_MANAGER', 'ADMIN']}
          >
            <FormattedMessage id="nav.menu.riskregister" />
          </SecuredNavLink>
          <SecuredNavLink path="/users" roles={props.roles} authorized={['RISK_MANAGER', 'ADMIN']}>
            <FormattedMessage id="nav.menu.staff" />
          </SecuredNavLink>
          <SecuredNavLink
            path="/kpi"
            roles={props.roles}
            authorized={['RISK_MANAGER', 'ADMIN', 'COMPANY_MANAGER']}
          >
            <FormattedMessage id="nav.menu.kpi" />
          </SecuredNavLink>
        </FlexDiv>
      </Nav>
      <ProfilContainer id="profil">
        <ProfilMenu onSignout={props.signoutUser} />
      </ProfilContainer>
    </div>
  </StyledDiv>
)

const SecuredNavLink = (props) => {
  return (
    hasAtLeastOneRole(props.authorized, { roles: props.roles }) && (
      <StyledNavLink
        to={props.path}
        onClick={(e) => (props.currentLocation === props.path ? e.preventDefault() : null)}
      >
        {props.children}
      </StyledNavLink>
    )
  )
}
const StyledDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: inherit;
  color: ${colors.text.main};
  background-color: #fafafa;
  position: fixed;
  top: 0;
  z-index: 1;
  box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.4);
`
const Logo = styled.img.attrs((props) => ({
  src: logo,
  alt: props.alt ? props.alt : 'Logo',
  width: props.width ? props.width : 'auto',
}))`
  display: flex;
  flex: 1;
  max-width: ${(props) => props.width || '150px'};
`
Logo.propTypes = {
  alt: PropTypes.string.isRequired,
  width: PropTypes.string,
}

const Nav = styled.nav`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  height: 80px;
`
const ProfilContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  max-width: 300px;
  min-width: 150px;
  width: auto;
  height: 80px;
  padding: 0 0.5rem;
  /* border-left: 1px solid ${colors.text.main}; */
`

const StyledNavLink = styled(NavLink)`
  color: inherit;
  margin: 0 1rem;
  font-weight: 600;
  &:hover {
    color: ${colors.main};
  }
  &.active {
    color: ${colors.main};
  }
`

const mapStateToProps = (state) => ({
  roles: state.auth.user.roles,
})
export default connect(mapStateToProps, actions)(Header)
