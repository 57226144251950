import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { scoreColors } from 'utils/constants'

export default ({ data }) => {
  const classes = useStyles()
  const { weights } = data
  return (
    <table className={classes.table}>
      <tbody>
        <tr id="prob_level-4">
          <td rowSpan={4} style={{ transform: 'rotate(270deg)', width: '36px' }}>
            <Typography variant="body2">Impact</Typography>
          </td>
          <td className={classes.header}>very high</td>
          <td className={classes.moderate}>
            {weights && weights[3][0] !== 0 ? weights[3][0] : ''}
          </td>
          <td className={classes.critical}>
            {weights && weights[3][1] !== 0 ? weights[3][1] : ''}
          </td>
          <td className={classes.critical}>
            {weights && weights[3][2] !== 0 ? weights[3][2] : ''}
          </td>
          <td className={classes.critical}>
            {weights && weights[3][3] !== 0 ? weights[3][3] : ''}
          </td>
        </tr>
        <tr id="prob_level-3">
          <td className={classes.header}>significant</td>
          <td className={classes.low}>{weights && weights[2][0] !== 0 ? weights[2][0] : ''}</td>
          <td className={classes.moderate}>
            {weights && weights[2][1] !== 0 ? weights[2][1] : ''}
          </td>
          <td className={classes.critical}>
            {weights && weights[2][2] !== 0 ? weights[2][2] : ''}
          </td>
          <td className={classes.critical}>
            {weights && weights[2][3] !== 0 ? weights[2][3] : ''}
          </td>
        </tr>
        <tr id="prob_level-2">
          <td>moderate</td>
          <td className={classes.low}>{weights && weights[1][0] !== 0 ? weights[1][0] : ''}</td>
          <td className={classes.moderate}>
            {weights && weights[1][1] !== 0 ? weights[1][1] : ''}
          </td>
          <td className={classes.moderate}>
            {weights && weights[1][2] !== 0 ? weights[1][2] : ''}
          </td>
          <td className={classes.critical}>
            {weights && weights[1][3] !== 0 ? weights[1][3] : ''}
          </td>
        </tr>
        <tr id="prob_level-1">
          <td>low</td>
          <td className={classes.low}>{weights && weights[0][0] !== 0 ? weights[0][0] : ''}</td>
          <td className={classes.low}>{weights && weights[0][1] !== 0 ? weights[0][1] : ''}</td>
          <td className={classes.moderate}>
            {weights && weights[0][2] !== 0 ? weights[0][2] : ''}
          </td>
          <td className={classes.moderate}>
            {weights && weights[0][3] !== 0 ? weights[0][3] : ''}
          </td>
        </tr>
        <tr>
          <td colSpan={2} />
          <td>low</td>
          <td>moderate</td>
          <td>significant</td>
          <td>very high</td>
        </tr>
        <tr>
          <td></td>
          <td colSpan={4} style={{ textAlign: 'center' }}>
            <Typography variant="body2">Likelihood</Typography>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: 'collapse',
    '& td': {
      padding: 0,
      height: '36px',

      textAlign: 'center',
      verticalAlign: 'middle',
      fontSize: '12px',
    },
  },
  header: {
    borderColor: 'white',
    width: '96px',
  },
  critical: {
    border: '1px solid grey',
    width: '96px',
    fontWeight: 600,
    backgroundColor: scoreColors.critical,
  },
  moderate: {
    border: '1px solid grey',
    width: '96px',
    fontWeight: 600,
    backgroundColor: scoreColors.moderate,
  },
  low: {
    border: '1px solid grey',
    width: '96px',
    fontWeight: 600,
    backgroundColor: scoreColors.low,
  },
}))
