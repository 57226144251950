import React from 'react'
import PropTypes from 'prop-types'

function resolve(cur, ns) {
  let undef
  ns = ns.split('.')
  while (cur && ns[0]) cur = cur[ns.shift()]
  return !cur && cur !== 0 ? undef : cur
}

const sAsc = (property, type, fn) => (a, b) => {
  if (!a) return 1
  if (!b) return -1
  const aValue = fn ? fn(a) : resolve(a, property)
  const bValue = fn ? fn(b) : resolve(b, property)
  if (!aValue && aValue !== 0) return 1
  if (!bValue && bValue !== 0) return -1
  if (type === 'text') {
    return aValue.localeCompare(bValue)
  }
  return aValue < bValue ? -1 : 1
}
const sDesc = (property, type, fn) => (a, b) => {
  if (!a) return 1
  if (!b) return -1
  const aValue = fn ? fn(a) : resolve(a, property)
  const bValue = fn ? fn(b) : resolve(b, property)
  if (!aValue && aValue !== 0) return 1
  if (!bValue && bValue !== 0) return -1
  if (type === 'text') {
    return bValue.localeCompare(aValue)
  }
  return bValue < aValue ? -1 : 1
}

const withSorting = WrappedComponent => {
  return class extends React.PureComponent {
    state = {
      sort: this.props.initialSort,
    }

    /**
     * "fn" allows to provide a sort function. The function is actually used to extract and/or format the data before it will be sorted.
     * //TODO onSort should take an object as an argument instead of parameters (more flexible)
     */
    handleSort = (property, type, fn) => event => {
      if (event) event.preventDefault()
      this.setState(state => {
        const sort =
          state.sort.property === property
            ? { ...state.sort, asc: !state.sort.asc, type: type, fnSort: fn }
            : { ...state.sort, property, asc: true, type: type, fnSort: fn }

        return {
          ...state,
          sort: sort,
        }
      })
    }

    sortByProperty = (data = []) => {
      const { asc, property, type = 'text', fnSort } = this.state.sort
      const copy = data.slice(0)
      return asc
        ? copy.sort(sAsc(property, type, fnSort))
        : copy.sort(sDesc(property, type, fnSort))
    }

    render() {
      const { data, ...rest } = this.props
      return (
        <WrappedComponent
          {...rest}
          sorted={this.state.sort}
          onSort={this.handleSort}
          data={this.sortByProperty(data)}
        />
      )
    }
  }
}

withSorting.propTypes = {
  WrappedComponent: PropTypes.element.isRequired,
  data: PropTypes.array.isRequired,
  initialSort: PropTypes.object.isRequired,
}

withSorting.defaultProps = {
  data: [],
}

export default withSorting
