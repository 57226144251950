import React from 'react'
import { Bloc } from 'components/common'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { formatsToIsoDate } from 'utils/formatter'
import { colors } from 'layouts/colors'
import { Grid, makeStyles, Table, TableBody, TableCell, TableRow } from '@material-ui/core'

const NotificationBloc = ({ title, subtitle, notifications }) => {
  const classes = useStyles()
  const generateNotificationRow = (data) =>
    data.map((notif, idx) => {
      const el = notif.uncertainty
      return (
        <TableRow key={idx}>
          <TableCell style={{ width: 150 }}>
            <StyledLink to={`uncertainties/${el.id}/details`}>{el.reference}</StyledLink>
          </TableCell>
          <TableCell>{el.title}</TableCell>
          <TableCell style={{ width: 280 }}>{`${el.user.firstname} ${el.user.lastname}`}</TableCell>
          <TableCell align="right" style={{ width: 140 }}>
            {formatsToIsoDate(el.created)}
          </TableCell>
        </TableRow>
      )
    })
  return (
    <Bloc title={title}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div style={{ fontSize: '14px', color: '#5c5c5c' }}>
            <span style={{ color: 'black', fontWeight: 500 }}>{notifications.length}</span>{' '}
            {subtitle}
          </div>
        </Grid>
        <Grid item xs={12}>
          {notifications.length > 0 && (
            <Table className={classes.table}>
              <TableBody>{generateNotificationRow(notifications)}</TableBody>
            </Table>
          )}
        </Grid>
      </Grid>
    </Bloc>
  )
}
const useStyles = makeStyles({
  table: {
    borderCollapse: 'collapse',
    '&& td': {
      border: '1px solid rgba(224, 224, 224, 1)',
    },
  },
})

const StyledLink = styled(Link)`
  color: ${(props) => (props.color ? props.color : 'inherit')};
  margin: ${(props) => (props.margins ? props.margins : '0 1rem')};
  font-weight: 700;
  color: ${colors.main};
`

export default NotificationBloc
