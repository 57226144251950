import React from 'react'
import { FlexDiv } from 'components/common'
import styled from 'styled-components'
import PropTypes from 'prop-types'

class PasswordStrengthMeter extends React.PureComponent {
  levels = [
    { name: 'WEAK', color: '#e74c3c', message: 'too weak' },
    { name: 'AVERAGE', color: '#e67e22', message: 'average' },
    { name: 'STRONG', color: '#f1c40f', message: 'strong' },
    { name: 'VERY_STRONG', color: '#2ecc71', message: 'very strong' },
  ]

  render() {
    const levelIndex = this.props.strengthCalculator
      ? this.props.strengthCalculator()
      : this.props.levelIndex
    const levels = this.props.levels ? this.props.levels : this.levels
    return (
      <ColumnFlexDiv>
        <FlexDiv>
          {this.levels.map((lvl, index) => (
            <div
              style={{
                border: '1px solid #ecf0f1',
                width: '25px',
                height: '15px',
                backgroundColor: index <= levelIndex ? levels[levelIndex].color : '#bdc3c7',
              }}
            />
          ))}
        </FlexDiv>
        <div>{`Password strength: ${levels[levelIndex].message}`}</div>
      </ColumnFlexDiv>
    )
  }
}
const ColumnFlexDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-height: 50px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
`
PasswordStrengthMeter.propTypes = {
  levelIndex: PropTypes.number,
  strengthCalculator: PropTypes.func,
  levels: PropTypes.array,
}

export default PasswordStrengthMeter
