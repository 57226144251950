import React from 'react'
import styled from 'styled-components'
import { Avatar } from '@material-ui/core'

export default ({ picture, name, position, email }) => {
  const ContactContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 1rem 0;
  `
  const ContactDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  `
  const StyledAvatar = styled(Avatar)`
    width: 80px !important;
    height: 80px !important;
    margin-right: 2rem;
  `
  return (
    <ContactContainer>
      <StyledAvatar alt={name} src={picture} />
      <ContactDetails>
        <span>{name}</span>
        <span>{position}</span>
        <span style={{ textTransform: 'lowercase' }}>{email}</span>
      </ContactDetails>
    </ContactContainer>
  )
}
