import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import PropTypes from 'prop-types'

const PasswordSuccessAlert = ({ openIf, onClose, message, title }) => {
  return (
    <div>
      <Dialog
        open={openIf}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            Log in
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

PasswordSuccessAlert.propTypes = {
  openIf: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  message: PropTypes.string,
  title: PropTypes.string,
}

export default PasswordSuccessAlert
