import axios from 'axios'

export default function promiseMiddleware({ dispatch, getState }) {
  return next => action => {
    if (typeof action === 'undefined') {
      return false
    }
    if (typeof action === 'function') {
      return action(dispatch, getState)
    }
    if (typeof action.then === 'function') {
      return action
    }
    const { promise, types, afterSuccess, afterFailure, ...rest } = action
    if (!action.promise) {
      return next(action)
    }

    const [REQUEST, SUCCESS, FAILURE] = types
    next({ ...rest, type: REQUEST })

    const onFulfilled = result => {
      next({ ...rest, result, type: SUCCESS })
      if (afterSuccess) return afterSuccess(dispatch, getState, result)
    }
    const onRejected = error => {
      next({ ...rest, error, type: FAILURE })
      if (afterFailure) return afterFailure(dispatch, getState, error)
      else return Promise.reject(error)
    }
    return promise(axios, getState, dispatch).then(onFulfilled, onRejected)
    // .catch(error => {
    //   // catch other error (i.e. Network errors)
    //   console.error('MIDDLEWARE ERROR:', error)
    //   return onRejected(error)
    // })
  }
}
