import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { AddCircleOutlined } from '@material-ui/icons'
import PropTypes from 'prop-types'
import { ButtonIcon, SearchBar, withSorting } from 'components/common'
import AddTeamDialog from './AddTeamDialog'
import { actions as teamsActions } from 'reducers/teamsReducer'
import TeamsList from './TeamsList'
import { getTeamManagers } from 'utils/utils'
import { Grid } from '@material-ui/core'

const SortingTeamsList = withSorting(TeamsList)

class TeamsSection extends Component {
  state = {
    displayDialog: false,
    teamedit: false,
    teams: [],
  }

  static propTypes = {
    users: PropTypes.array.isRequired,
  }

  openDialog = () => {
    this.setState((state) => ({ ...state, displayDialog: true }))
  }

  closeDialog = () => {
    this.setState((state) => ({
      ...state,
      displayDialog: false,
      teamedit: false,
    }))
  }

  editTeam = (team) => {
    this.setState(
      (state) => ({ ...state, teamedit: team }),
      () => {
        this.openDialog()
      }
    )
  }

  _handleClickRow = (id) => (evt) => {
    evt.preventDefault()
    evt.stopPropagation()
    this.props.history.push(`/teams/${id}/details`)
  }

  handleSearch = (filter) => {
    this.setState((state, props) => ({
      ...state,
      teams: props.teams.filter(filter),
    }))
  }

  handleSubmit = (values) => {
    this.props
      .saveTeam(values)
      .then((res) => {
        this.closeDialog()
      })
      .catch((err) => {
        console.warn(err)
      })
  }

  handleClickRow = (team) => (evt) => {
    this.editTeam(team)
  }

  handleClickUser = (user) => (evt) => {
    evt.stopPropagation()
    if (user && user !== null) {
      this.props.history.push(`/users/${user.id}/details`)
    }
  }

  formatTeamdata(teams) {
    return teams.map((t) => {
      const manager = t.members ? getTeamManagers(t.members)[0] : undefined
      const membersCount = t.members ? t.members.length : 0
      return { ...t, manager, membersCount: membersCount }
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState((state) => ({ ...state, teams: nextProps.teams }))
  }

  render() {
    const { displayDialog, teamedit, teams } = this.state
    const { users } = this.props
    return (
      <Grid id="teams" container justifyContent="center" item xs={12}>
        {displayDialog && (
          <AddTeamDialog
            open={displayDialog}
            onClose={this.closeDialog}
            onSubmit={this.handleSubmit}
            edit={teamedit}
            users={users}
          />
        )}
        <Grid container justifyContent="space-between" alignItems="center" item xs={12}>
          <SearchBar
            onSearch={this.handleSearch}
            onFields={['name']}
            placeholder="Search for a team by name"
          />
          <ButtonIcon icon={AddCircleOutlined} onClick={this.openDialog}>
            Add Team
          </ButtonIcon>
        </Grid>
        <Grid item xs={12}>
          <SortingTeamsList
            onClickRow={this._handleClickRow}
            onClickUser={this.handleClickUser}
            initialSort={{ property: 'name', type: 'text', asc: true }}
            data={this.formatTeamdata(teams)}
          />
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(connect(null, teamsActions)(TeamsSection))
