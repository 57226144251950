import React from 'react'
import { injectIntl } from 'react-intl'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { FlexDiv, SearchBar, withPaging, withSorting } from 'components/common'
import MembersList from './MembersList'

const SortingPagingMembersList = withSorting(withPaging(MembersList))

class ChangeTeamManagerDialog extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      filteredUsers: null,
    }
  }

  static defaultProps = {
    onClick: () => null,
    onSubmit: () => null,
    initialValues: [],
  }

  _handleSearchMember = filter => {
    this.setState((state, props) => ({
      ...state,
      filteredUsers: props.users.filter(filter),
    }))
  }

  _handleSelectTeamManager = id => evt => {
    evt.preventDefault()
    evt.stopPropagation()
    return this.props.onSelectTeamManager(id)
  }

  render() {
    const { open, onClose, users } = this.props
    return (
      <Dialog open={open} fullWidth={true} maxWidth="md" fullScreen={false}>
        <FlexDiv width="40rem" margins="0 auto" direction="column">
          <div>
            <DialogTitle id="team-manager-selection-dialog-title">Select Team Manager</DialogTitle>
            {/* Display error message */}
            {/* {error && <ErrorMessage>{submitError}</ErrorMessage>} */}
            <DialogContent>
              <SearchBar
                onSearch={this._handleSearchMember}
                onFields={['firstname', 'lastname']}
                placeholder="Search by firstname or lastname"
              />
              <SortingPagingMembersList
                initialSort={{ property: 'created', type: 'date', asc: false }}
                data={this.state.filteredUsers || users}
                onSelectMember={this._handleSelectTeamManager}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </div>
        </FlexDiv>
      </Dialog>
    )
  }
}

export default injectIntl(ChangeTeamManagerDialog)
