import React from 'react'
import axios from 'axios'
import { Field, Form } from 'react-final-form'
import { MenuItem } from '@material-ui/core'
import { FlexDiv } from 'components/common'
import {
  renderTextField,
  renderFormControlSelect,
  StyledButton,
} from 'components/Uncertainty/Details/FormComponents'
import { strategies, RESPONSES_OP, RESPONSES_TH } from 'utils/constants'
import CloseUncertaintyForm from './CloseUncertaintyForm'
import { FormattedMessage } from 'react-intl'
import { AutocompleteUsers } from 'components/common/Autocomplete'

const validate = (values) => {
  const errors = {}
  const requiredFields = ['strategy', 'assignedTo']

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  return errors
}

class AssignmentForm extends React.Component {
  constructor(props) {
    super()
    this.state = {
      managers: [],
      strategy: '',
    }
    this.fetchTeamManagers()
  }

  async fetchTeamManagers() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/users/teammanagers`)
      .then((response) => this.setState((state) => ({ ...state, managers: response.data })))
  }

  generateManagerItems = (managers) => {
    if (managers && managers.length) {
      return managers.map((m, index) => (
        <MenuItem key={index} value={m.id}>
          {`${m.firstname} ${m.lastname} (${(m.team && m.team.name) || ''})`}
        </MenuItem>
      ))
    } else {
      return <MenuItem>No team manager found</MenuItem>
    }
  }

  generateReponseItems = () => {
    const responses = this.props.uncertaintyType === 'opportunity' ? RESPONSES_OP : RESPONSES_TH
    return Object.keys(responses).map((resp) => (
      <MenuItem value={resp}>
        <FormattedMessage id={`uct.strategy.responses.${resp}`} />
      </MenuItem>
    ))
  }

  validateAndSubmit = (submitFn) => (values) => {
    const errors = validate(values)
    if (Object.keys(errors).length > 0) {
      return errors
    } else return submitFn(values)
  }

  render() {
    return (
      <React.Fragment>
        <Form
          onSubmit={this.validateAndSubmit(this.props.onSubmit)}
          render={({ handleSubmit, submitting, pristine, values }) => {
            return (
              <form id="timeline_form" name="timeline" onSubmit={handleSubmit}>
                <FlexDiv direction="column" align="start" fullWidth>
                  <Field
                    name="strategy"
                    label="Select response strategy"
                    component={renderFormControlSelect}
                    controlProps={{
                      component: 'div',
                      margin: 'dense',
                      fullWidth: true,
                    }}
                    onChange={(e) => {
                      this.setState(
                        { strategy: e.target.value },
                        () => (values['strategy'] = this.state.strategy)
                      )
                    }}
                    currentValue={this.state.strategy}
                    value={this.state.strategy}
                    margin="dense"
                  >
                    {this.generateReponseItems()}
                  </Field>
                  {this.state.strategy !== strategies.REJECT && (
                    <Field
                      label=" Assign uncertainty"
                      name="assignedTo"
                      component={(props) => <AutocompleteUsers {...props} />}
                      controlProps={{
                        component: 'div',
                        margin: 'dense',
                        fullWidth: true,
                      }}
                      margin="dense"
                    />
                  )}
                  <Field
                    name="comment"
                    component={renderTextField}
                    placeholder="Add a comment"
                    minRows="4"
                    maxRows="4"
                    label=""
                    margin="dense"
                    multiline={true}
                  />
                  <FlexDiv justifyContent="flex-end" margins="0.5rem 0" fullWidth>
                    <StyledButton
                      type="submit"
                      disabled={pristine || submitting}
                      variant="contained"
                    >
                      Submit
                    </StyledButton>
                  </FlexDiv>
                </FlexDiv>
              </form>
            )
          }}
        />
        <CloseUncertaintyForm onSubmit={this.props.onCloseUncertainty} />
      </React.Fragment>
    )
  }
}

export default AssignmentForm
