import React, { useEffect, useState } from 'react'
import { Tabs, Tab, Grid } from '@material-ui/core'

import OverviewStatsTab from './Tabs/OverviewStatsTab'
import WeeklyReportTab from './Tabs/WeeklyReportTab'
import { fetchKPIOverview } from 'services/api'
import MonthlyReportTab from './Tabs/Monthly/MonthlyReportTab'

const overviewStatsInit = {
  declarations: 0,
  pending: 0,
  assigned: 0,
  closed: 0,
  criticalCount: 0,
  moderateCount: 0,
  lowCount: 0,
}

export default () => {
  const [overviewStats, setOverviewStats] = React.useState(overviewStatsInit)

  useEffect(() => {
    ;(async () => {
      try {
        setOverviewStats((await fetchKPIOverview()).data)
      } catch (err) {
        console.error(err)
      }
    })()
  }, [])

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <DashboardTabs overviewStats={overviewStats} />
      </Grid>
    </Grid>
  )
}

const DashboardTabs = ({ overviewStats }) => {
  const [tabId, setTabId] = useState(0)

  const handleTabChange = (evt, value) => {
    setTabId((tabId) => (tabId !== value ? value : tabId))
  }

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid container justifyContent="center" item xs={12}>
        <Tabs variant="fullWidth" value={tabId} onChange={handleTabChange} indicatorColor="primary">
          <Tab label="Global" />
          <Tab label="Weekly" />
          <Tab label="Monthly" />
        </Tabs>
      </Grid>
      <Grid container justifyContent="center" item xs={12}>
        {tabId === 0 && <OverviewStatsTab statistics={{ overview: overviewStats }} />}
        {tabId === 1 && <WeeklyReportTab />}
        {tabId === 2 && <MonthlyReportTab />}
      </Grid>
    </Grid>
  )
}
