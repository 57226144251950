import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { actions } from 'reducers/authenticationReducer'
import { Wing, WingContent, Container, Title, Baseline, leftFooterStyle } from './common'
import LoginForm from './LoginForm'

import logo from 'assets/magnifor-logo-small.png'
import clientLogo from 'assets/magnifor-logo.png'
import { colors } from 'layouts/colors'

class LoginPage extends React.Component {
  handleSubmit = ({ username, password }) => {
    return this.props.signinUser({ username, password })
  }

  onSubmit = async (values) => {
    try {
      await this.props.signinUser(values)
    } catch (err) {
      const error = err.message ? err : err.response.data
      if (err.isAxiosError) {
        const status = err.response ? err.response.status : 'unknown'
        if (/Network/i.test(error.message)) {
          // error message contains "Network" (ie. "Network Error")
          error.message = 'Server unreachable'
        } else if (/^40[1,3]$/.test(status)) {
          // http code 401 or 403
          error.message = 'Unknown username or invalid password'
        }
      }
      return error
    }
  }

  getRedirectPath() {
    const locationState = this.props.location.state
    if (locationState && locationState.from.pathname) {
      console.debug(`Redirect to referring url ${locationState.from.pathname}`)
      return locationState.from.pathname // redirects to referring url
    } else {
      return '/'
    }
  }

  render() {
    if (this.props.authenticated) {
      return (
        <Redirect
          to={{
            pathname: this.getRedirectPath(),
            state: {
              from: this.props.location,
            },
          }}
        />
      )
    }
    return (
      <Container>
        <Wing color={colors.text.main} justifyContent="flex-end">
          <WingContent>
            <div>
              <Title>Risk Focus</Title>
              <Baseline>Crowdsourcing risks and opportunities in your business</Baseline>
            </div>
            <div style={leftFooterStyle}>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <a href="https://www.magnifor.com/" target="_blank" rel="noopener noreferrer">
                  <Button color="primary">About</Button>
                </a>
                <a href="mailto:contact@risk-focus.com">
                  <Button color="primary">Contact</Button>
                </a>
              </div>
              <img src={logo} width="80px" alt="logo" />
            </div>
          </WingContent>
        </Wing>
        <Wing justifyContent="stretch">
          <WingContent>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <LoginForm onSubmit={this.onSubmit} />
            </div>
            <img src={clientLogo} width="100px" alt="client_logo" />
          </WingContent>
        </Wing>
      </Container>
    )
  }
}

export default connect(null, actions)(LoginPage)
