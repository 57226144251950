import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledBadge = styled.div`
  background-color: ${props => props.backgroundColor};
  width: ${props => (props.width ? props.width : 'auto')};
  border-radius: 20px;
  color: white;
  font-weight: 600;
  padding: 2px 5px;
`
const Badge = ({ backgroundColor, width, children }) => (
  <StyledBadge backgroundColor={backgroundColor} width={width}>
    {children}
  </StyledBadge>
)

Badge.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  width: PropTypes.string,
}

export default Badge
