import React from 'react'
import { Input, InputAdornment } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import PropTypes from 'prop-types'
import { FlexDiv } from 'components/common'

const handleSearch =
  (onSearch, onFields = []) =>
  (e) => {
    const inputText = e.target.value
    const regexp = new RegExp(inputText, 'i')
    const filter = (obj) => {
      return inputText && inputText.length > 1
        ? onFields.reduce((result, field) => {
            const fieldValue = obj[field]
            return (result = result || !!(fieldValue && fieldValue.match(regexp)))
          }, false)
        : true
    }
    return onSearch(filter)
  }

const SearchBar = ({ onSearch, onFields, placeholder = '', margins = '', noBorder = false }) => {
  return (
    <FlexDiv
      margins={margins ? margins : '1rem'}
      maxWidth="35rem"
      style={{
        backgroundColor: 'white',
        borderWidth: noBorder ? 0 : 1,
        borderRadius: 4,
        borderColor: 'rgba(0,0,0,0.24)',
        borderStyle: 'solid',
      }}
    >
      <Input
        id="search"
        placeholder={placeholder}
        fullWidth
        disableUnderline
        startAdornment={
          <InputAdornment position="start" style={{ color: 'grey' }}>
            <Search />
          </InputAdornment>
        }
        onChange={handleSearch(onSearch, onFields)}
      />
    </FlexDiv>
  )
}

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onFields: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  margins: PropTypes.string,
  noBorder: PropTypes.bool,
}

export default SearchBar
