import React, { useEffect, useState } from 'react'
import { Tabs, Tab } from '@material-ui/core'
import { connect } from 'react-redux'
import { DeclareButton, FlexDiv } from '../common'
import MyDeclarations from './MyDeclarations'
import MySettings from './MySettings'
import MyTasks from './MyTasks'
import MyRisks from './MyRisks'
import { useLocation } from 'react-router-dom'

const tabs = {
  declarations: 0,
  risks: 1,
  tasks: 2,
  settings: 3,
}
const MyDashboard = ({ firstname, lastname }) => {
  const [currentTab, setCurrentTab] = useState(0)
  const location = useLocation()

  const handleChangeTab = (event, value) => {
    setCurrentTab(value)
  }
  useEffect(() => {
    try {
      let { tab } = location.state
      if (tab && tabs[tab]) setCurrentTab(tabs[tab])
    } catch (e) {}
  }, [location])
  return (
    <>
      <FlexDiv>
        <FlexDiv>
          <h2 style={{ textTransform: 'capitalize' }}>
            {firstname} {lastname}
          </h2>
        </FlexDiv>
        <FlexDiv direction="column" justifyContent="center" align="flex-end">
          <DeclareButton />
        </FlexDiv>
      </FlexDiv>
      <FlexDiv direction="column" margins="3rem 0">
        <Tabs value={currentTab} onChange={handleChangeTab} indicatorColor="primary">
          <Tab style={{ textTransform: 'none !important' }} label="Uncertainties I declared" />
          <Tab label="Assigned uncertainties" />
          <Tab label="Assigned tasks" />
          <Tab label="My settings" />
        </Tabs>
        {currentTab === 0 && <MyDeclarations />}
        {currentTab === 1 && <MyRisks />}
        {currentTab === 2 && <MyTasks />}
        {currentTab === 3 && <MySettings />}
      </FlexDiv>
    </>
  )
}

const mapStateToProps = (state) => ({
  firstname: state.auth.user.firstname,
  lastname: state.auth.user.lastname,
})

export default connect(mapStateToProps)(MyDashboard)
