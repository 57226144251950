import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { actions } from 'reducers/uncertaintiesReducer'
import { SearchBar, withPaging, withSorting } from 'components/common'
import DeclarationsList from './DeclarationsList'
import { deleteUncertainty } from 'services/api'
import { injectIntl } from 'react-intl'
import { Grid } from '@material-ui/core'

const SortingDeclarationsList = withSorting(withPaging(DeclarationsList))

class MyDeclarationsPage extends React.PureComponent {
  state = {
    data: [],
  }

  handleSearch = (filter) => {
    this.setState((state) => ({
      ...state,
      data: this.props.uncertainties.filter(filter),
    }))
  }

  handleClickRow = (uncertaintyId) => (event) => {
    event.preventDefault()
    this.props.history.push(`/dashboard/uncertainties/${uncertaintyId}`, {
      tab: 'declarations',
      from: this.props.history.location.pathname,
    })
  }

  handleEdit = (uncertaintyId) => (event) => {
    event.stopPropagation()
    event.preventDefault()
    this.props.history.push(`/declare/${uncertaintyId}`)
  }

  handleDelete = (uncertaintyId) => (event) => {
    event.stopPropagation()
    event.preventDefault()
    deleteUncertainty(uncertaintyId).then(() => this.props.fetchUncertainties())
  }

  componentWillReceiveProps(nextProps) {
    this.setState((state) => {
      return {
        ...state,
        data: nextProps.uncertainties,
      }
    })
  }

  componentDidMount() {
    this.props.fetchUncertainties()
  }

  render() {
    return (
      <Grid container justifyContent="center" id="my_declarations">
        <Grid item xs={12}>
          <SearchBar
            onSearch={this.handleSearch}
            onFields={['title', 'status', 'reference']}
            placeholder={this.props.intl.formatMessage({
              id: 'forms.dashboard.mydeclarations.search.placeholder',
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <SortingDeclarationsList
            initialSort={{ property: 'created', type: 'date', asc: false }}
            data={this.state.data}
            onEdit={this.handleEdit}
            onDelete={this.handleDelete}
            onClickRow={this.handleClickRow}
          />
        </Grid>
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  return {
    uncertainties: state.uncertainties.data,
  }
}

export default withRouter(connect(mapStateToProps, actions)(injectIntl(MyDeclarationsPage)))
