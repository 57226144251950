import React from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { Button, Input, InputLabel, withStyles } from '@material-ui/core'
import { FlexDiv } from 'components/common'
import { ErrorMessage } from 'components/Staff/common/FormComponents'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { ReactTelephoneInput } from 'vendors/react-telephone-input'
import 'vendors/react-telephone-input/css/material-ui.css'
import flags from 'assets/flags.png'

const renderInput = ({ input, meta, ...rest }) => {
  const { touched, error } = meta
  return <Input {...input} {...rest} error={touched && error ? true : false} />
}

const renderPhoneInput = ({ input, meta, inputProps, ...rest }) => {
  const { touched, error } = meta
  const { value, onChange, ...otherInputProps } = input
  return (
    <ReactTelephoneInput
      defaultCountry="fr"
      preferredCountries={['fr', 'us', 'gb']}
      value={value}
      onChange={onChange}
      disabled={inputProps.disabled}
      flagsImagePath={flags}
      inputProps={{
        ...otherInputProps,
        ...rest,
        error: touched && error ? true : false,
        muiInputProps: { ...inputProps },
      }}
    />
  )
}

const StyledButton = styled(Button)`
  && {
    width: 5rem;
    font-weight: 600;
    font-size: 1rem;
    text-transform: none;
    margin: 0.5rem;
  }
`

class MySettingsForm extends React.PureComponent {
  state = {
    editing: false,
  }

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
  }
  static defaultProps = {
    minHeight: '300px',
    formMaxWidth: '50rem',
    fieldMargins: '0.5rem 0',
  }

  editForm = (e) => {
    if (!this.state.editing) {
      e.preventDefault()
    }
    this.setState((state) => ({ editing: !state.editing }))
  }

  handleCancel = (form) => (e) => {
    e.preventDefault()
    form.reset()
    this.setState((state) => ({ editing: !state.editing }))
  }

  render() {
    const { onSubmit, initialValues } = this.props
    const { status } = initialValues
    return (
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        render={({
          submitError,
          handleSubmit,
          dirtySinceLastSubmit,
          submitFailed,
          values,
          form,
        }) => {
          // Reset form with current values to reset form "submitFailed"
          // status and remove error message
          if (dirtySinceLastSubmit) form.reset(values)
          return (
            <form name="settings" onSubmit={handleSubmit} style={{ minHeight: '300px' }}>
              {/* Display error message */}
              {submitFailed && submitError && <ErrorMessage>{submitError}</ErrorMessage>}
              <FlexDiv maxWidth="50rem" direction="column" align="start">
                <FlexDiv margins="0.5rem 0" align="center">
                  <StyledInputLabel>
                    <FormattedMessage id="forms.user.label.email" />
                  </StyledInputLabel>
                  <Field
                    type="email"
                    name="email"
                    autoComplete="work email"
                    disableUnderline
                    inputProps={{ disabled: true }}
                    classes={{
                      root: this.props.classes.textFieldRoot,
                      input: this.props.classes.textFieldInput,
                    }}
                    component={renderInput}
                  />
                </FlexDiv>
                <FlexDiv margins="0.5rem 0" align="center">
                  <StyledInputLabel>
                    <FormattedMessage id="forms.user.label.firstname" />
                  </StyledInputLabel>
                  <Field
                    name="firstname"
                    autoComplete="given-name"
                    disableUnderline
                    inputProps={{ disabled: !this.state.editing }}
                    placeholder={this.state.editing ? '+33 9 51 29 33 45' : ''}
                    classes={
                      this.state.editing
                        ? {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldInputEditing,
                          }
                        : {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldInput,
                          }
                    }
                    required
                    component={renderInput}
                  />
                </FlexDiv>
                <FlexDiv margins="0.5rem 0" align="center">
                  <StyledInputLabel>
                    <FormattedMessage id="forms.user.label.lastname" />
                  </StyledInputLabel>
                  <Field
                    name="lastname"
                    autoComplete="family-name"
                    disableUnderline
                    inputProps={{ disabled: !this.state.editing }}
                    placeholder={this.state.editing ? '+33 9 51 29 33 45' : ''}
                    classes={
                      this.state.editing
                        ? {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldInputEditing,
                          }
                        : {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldInput,
                          }
                    }
                    component={renderInput}
                  />
                </FlexDiv>
                <FlexDiv margins="0.5rem 0" align="center">
                  <StyledInputLabel>
                    <FormattedMessage id="forms.user.label.landline" />
                  </StyledInputLabel>
                  <Field
                    name="landline"
                    autoComplete="work tel"
                    disableUnderline
                    inputProps={{ disabled: !this.state.editing }}
                    placeholder={this.state.editing ? '+33 9 51 29 33 45' : ''}
                    classes={
                      this.state.editing
                        ? {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldPhoneInputEditing,
                          }
                        : {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldInput,
                          }
                    }
                    component={renderPhoneInput}
                  />
                </FlexDiv>
                <FlexDiv margins="0.5rem 0" align="center">
                  <StyledInputLabel>
                    <FormattedMessage id="forms.user.label.mobile" />
                  </StyledInputLabel>
                  <Field
                    name="mobile"
                    autoComplete="mobile tel"
                    disableUnderline
                    inputProps={{ disabled: !this.state.editing }}
                    placeholder={this.state.editing ? '+33 6 51 29 33 28' : ''}
                    classes={
                      this.state.editing
                        ? {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldPhoneInputEditing,
                          }
                        : {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldInput,
                          }
                    }
                    component={renderPhoneInput}
                  />
                </FlexDiv>
                <FlexDiv margins="0.5rem 0" align="center">
                  <StyledInputLabel>
                    <FormattedMessage id="forms.user.label.location" />
                  </StyledInputLabel>
                  <Field
                    name="location"
                    autoComplete="locality"
                    disableUnderline
                    inputProps={{ disabled: !this.state.editing }}
                    classes={
                      this.state.editing
                        ? {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldInputEditing,
                          }
                        : { input: this.props.classes.textFieldInput }
                    }
                    component={renderInput}
                  />
                </FlexDiv>
                {(status === 'RISK_MANAGER' || status === 'ADMIN') && (
                  <FlexDiv margins="0.5rem 0" align="center">
                    <StyledInputLabel>
                      <FormattedMessage id="forms.user.label.status" />
                    </StyledInputLabel>
                    <Field
                      name="status"
                      disableUnderline
                      inputProps={{ disabled: true }}
                      classes={{
                        root: this.props.classes.textFieldRoot,
                        input: this.props.classes.textFieldInput,
                      }}
                      format={(v) =>
                        this.props.intl.formatMessage({
                          id: `user.role.${v}`,
                          defaultMessageFormattedMessage: ' ',
                        })
                      }
                      component={renderInput}
                    />
                  </FlexDiv>
                )}
                <FlexDiv margins="0.5rem 0" align="center">
                  <StyledInputLabel>
                    <FormattedMessage id="forms.user.label.team" />
                  </StyledInputLabel>
                  <Field
                    name="team"
                    disableUnderline
                    inputProps={{ disabled: true }}
                    classes={{
                      root: this.props.classes.textFieldRoot,
                      input: this.props.classes.textFieldInput,
                    }}
                    component={renderInput}
                  />
                </FlexDiv>
                <FlexDiv margins="0.5rem 0" align="center">
                  <StyledInputLabel>
                    <FormattedMessage id="forms.user.label.lastconnection" />
                  </StyledInputLabel>
                  <Field
                    name="lastConnection"
                    disableUnderline
                    inputProps={{ disabled: true }}
                    classes={{
                      root: this.props.classes.textFieldRoot,
                      input: this.props.classes.textFieldInput,
                    }}
                    component={renderInput}
                  />
                </FlexDiv>
                <FlexDiv justifyContent="flex-start">
                  <StyledButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={this.editForm}
                  >
                    {this.state.editing ? 'Save' : 'Edit'}
                  </StyledButton>
                  {this.state.editing ? (
                    <StyledButton
                      type="button"
                      variant="contained"
                      onClick={this.handleCancel(form)}
                    >
                      Cancel
                    </StyledButton>
                  ) : (
                    ''
                  )}
                </FlexDiv>
              </FlexDiv>
            </form>
          )
        }}
      />
    )
  }
}

const StyledInputLabel = styled(InputLabel)`
  width: 10rem;
  color: rgba(0, 0, 0, 0.54) !important;
`
const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
  },
  inputLabelFocused: {
    color: 'purple',
  },
  inputUnderline: {
    '&:after': {
      backgroundColor: 'purple',
    },
  },
  textFieldRoot: {
    padding: 0,
  },
  textFieldInput: {
    fontSize: '1rem',
    padding: '11px 13px',
    width: 'calc(30rem - 24px)',
  },
  textFieldInputEditing: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: '1rem',
    padding: '10px 12px',
    width: 'calc(30rem - 24px)',
    transition: theme.transitions.create(['border-color', 'border-width', 'box-shadow', 'padding']),
    '&:focus': {
      borderWidth: '2px',
      padding: '9px 11px',
      borderColor: theme.palette.primary.light,
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    },
  },
  textFieldPhoneInputEditing: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: '1rem',
    padding: '10px 12px',
    width: 'calc(30rem - 56px)',
    transition: theme.transitions.create(['border-color', 'border-width', 'box-shadow', 'padding']),
    '&:focus': {
      borderWidth: '2px',
      padding: '9px 11px',
      borderColor: theme.palette.primary.light,
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    },
  },
  textFieldFormLabel: {
    fontSize: 18,
  },
})

const validate = (values) => {
  const errors = {}
  const requiredFields = ['firstname', 'lastname']
  // const phoneRegex = /((?:\+|00)[17](?: )?|(?:\+|00)[1-9]\d{0,2}(?: )?|(?:\+|00)1\-\d{3}(?: )?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: )[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: )[0-9]{3}(?: )[0-9]{4})|([0-9]{7}))/g;

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  // TODO activate when error message can be displayed (Update renderInput to display error messages through FormControl ?)
  // if (values.landline && !phoneRegex.test(values.landline)) {
  //   errors.landline = 'Format is not valid';
  // }
  return errors
}

MySettingsForm = withStyles(styles)(MySettingsForm)

export default injectIntl(MySettingsForm)
