const GET_DETAILS = 'td/details/GET_DETAILS'
const GET_DETAILS_SUCCESS = 'td/details/GET_DETAILS_SUCCESS'
const GET_DETAILS_FAILURE = 'td/details/GET_DETAILS_FAILURE'

const UPDATE_DETAILS = 'td/details/UPDATE_DETAILS'
const UPDATE_DETAILS_SUCCESS = 'td/details/UPDATE_DETAILS_SUCCESS'
const UPDATE_DETAILS_FAILURE = 'td/details/UPDATE_DETAILS_FAILURE'

const initialState = {
  details: {},
  uncertainties: [],
  error: null,
  loading: false,
}

export default function usersDetailReducer(state = initialState, action) {
  if (action.type.indexOf('td/') !== 0) {
    return state
  }
  switch (action.type) {
    case GET_DETAILS:
      return {
        ...state,
        loading: true,
      }
    case GET_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.result.data,
        loading: false,
        error: null,
      }
    case UPDATE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.result.data,
        loading: false,
        error: null,
      }
    case GET_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

const ROOT_URL = process.env.REACT_APP_API_URL

function fetchDetails(id) {
  return {
    types: [GET_DETAILS, GET_DETAILS_SUCCESS, GET_DETAILS_FAILURE],
    promise: client => client.get(`${ROOT_URL}/teams/${id}/details`),
  }
}

function updateDetails(teamid, { id, name }) {
  return {
    types: [UPDATE_DETAILS, UPDATE_DETAILS_SUCCESS, UPDATE_DETAILS_FAILURE],
    promise: client =>
      client.post(`${ROOT_URL}/manage/teams/${teamid}/details`, { id: id, name: name }),
  }
}

export const actions = {
  fetchDetails,
  updateDetails,
}
