import React from 'react'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import {
  Bloc,
  FlexDiv,
  MainTitle,
  SearchBar,
  StyledLink,
  withPaging,
  withSorting,
} from 'components/common'
import UserDetailsForm from './UserDetailForm'
import UserDetailsChart from './UserDetailsChart'
import { actions } from 'reducers/usersDetailReducer'
import DeclarationsList from 'components/Dashboard/MyDeclarations/DeclarationsList'
import { fetchUncertainties, fetchCurrentCompanyTeams } from 'services/api'
import { calculateActivityStats } from 'services/statistics'
import FullPageOverlayLoader from '../../../common/FullPageOverlayLoader'

const SortingPagingList = withSorting(withPaging(DeclarationsList))

class UserDetailPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userid: props.match.params.id,
      uncertainties: [],
      filteredUncertainties: [],
      activity: {
        threat: 0,
        opp: 0,
        assigned: 0,
        pending: 0,
        rejected: 0,
        closed: 0,
      },
      teams: [],
    }
  }

  onSubmit = (values) => {
    this.props.updateDetails(this.state.userid, values)
  }

  handleSearch = (filter) => {
    this.setState((state) => ({
      ...state,
      filteredUncertainties: this.state.uncertainties.filter(filter),
    }))
  }

  componentDidMount() {
    this.props.fetchDetails(this.state.userid)
    fetchUncertainties(this.state.userid).then((result) =>
      this.setState((state) => ({
        ...state,
        uncertainties: result.data,
        filteredUncertainties: result.data,
        activity: calculateActivityStats(result.data),
      }))
    )
    fetchCurrentCompanyTeams().then((res) => {
      if (res.data)
        this.setState((state) => ({
          ...state,
          teams: res.data,
        }))
    })
  }

  handleClickRow = (uncertaintyId) => (event) => {
    event.preventDefault()
    if (this.props.roles && this.props.roles.indexOf('RISK_MANAGER') !== -1) {
      this.props.history.push(`/uncertainties/${uncertaintyId}/details`)
    } else {
      this.props.history.push(`/dashboard/uncertainties/${uncertaintyId}`)
    }
  }

  render() {
    const { details, loading } = this.props
    const { activity, filteredUncertainties, teams } = this.state
    return (
      <React.Fragment>
        {loading && <FullPageOverlayLoader />}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div style={{ color: '#666666', fontSize: '14px' }}>
              <StyledLink to="/users">List of users</StyledLink>
              {` 
                  ${String.fromCharCode(187)} 
                  ${details.firstname} ${details.lastname}
                `}
            </div>
          </Grid>
          <Grid item xs={12}>
            <MainTitle size="medium">{`${details.firstname} ${details.lastname}`}</MainTitle>
          </Grid>
          <Grid item xs={6}>
            <Bloc id="details_bloc" title="User details">
              <UserDetailsForm
                onSubmit={this.onSubmit}
                initialValues={details}
                teams={teams}
                onRevokeAccess={this.props.revokeUserAccess}
                onGrantAccess={this.props.grantUserAccess}
              />
            </Bloc>
          </Grid>
          <Grid item xs={6}>
            <Bloc id="activity_bloc" title="Activity">
              <div id="activity_charts" style={{ height: '250px', width: '75%' }}>
                <UserDetailsChart height={'100px'} data={{ ...activity }} />
              </div>
              <FlexDiv id="activity_stats">
                <FlexDiv
                  direction="column"
                  style={styles.borderRounded}
                  margins="1rem"
                  height="100px"
                >
                  <FlexDiv>{activity.threat}</FlexDiv>
                  <FlexDiv>Threats declared</FlexDiv>
                </FlexDiv>
                <FlexDiv
                  direction="column"
                  style={styles.borderRounded}
                  margins="1rem"
                  height="100px"
                >
                  <FlexDiv>{activity.opp}</FlexDiv>
                  <FlexDiv>Opportunities declared</FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </Bloc>
          </Grid>
          <Grid item xs={12}>
            <Bloc title="Declared uncertainties">
              <SearchBar
                onSearch={this.handleSearch}
                onFields={['title', 'status', 'reference']}
                placeholder="Search by title, status, ..."
              />
              <SortingPagingList
                initialSort={{ property: 'created', type: 'date', asc: false }}
                data={filteredUncertainties}
                onClickRow={this.handleClickRow}
              />
            </Bloc>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

const styles = {
  borderRounded: {
    backgroundColor: 'white',
    padding: '1rem',
    marginLeft: '1rem',
    border: '1px solid #ecf0f1',
    borderRadius: '4px',
  },
}
const mapStateToProps = (state) => ({
  loading: state.usersDetail.loading,
  details: state.usersDetail.details,
  userid: state.users.userid,
  roles: state.auth.user.roles,
})

export default connect(mapStateToProps, actions)(UserDetailPage)
