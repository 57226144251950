import React from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'
import { absoluteFloor, calculatePercentageChange, compare, formatDecimals } from 'utils/statUtils'
import styled from 'styled-components'
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons'

// interface Props {
//   currentData: number
//   previousData: number
//   lessIsBetter?: boolean
//   title: string
// }
export default ({
  currentData = 0,
  previousData = 0,
  lessIsBetter = false,
  title = '',
  postAdornment,
}) => {
  // console.debug('title', title, 'currentData', currentData)
  return (
    <Paper>
      <Grid container justifyContent="center" align="center" direction="column">
        <Grid container justifyContent="flex-end" item xs={12}>
          <PercentageDiv lessIsBetter={lessIsBetter} values={{ n: currentData, o: previousData }}>
            {displayTendancyArrow(currentData, previousData)}
            {formatPercentageEvolution(calculatePercentageChange(currentData, previousData))}
          </PercentageDiv>
        </Grid>
        <Grid item container direction="row" justifyContent="center" alignItems="baseline">
          <Typography variant="h4">{formatDecimals(currentData, 0)}</Typography>
          {postAdornment && <Typography variant="h5">{postAdornment}</Typography>}
        </Grid>
        <Grid item>
          <Typography>{title}</Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

const PercentageDiv = styled.div`
  color: #474747;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-self: flex-end;
  align-items: center;
  height: 20px;
  color: ${(props) => {
    const score = compare(props.values, props.lessIsBetter)
    return score > 0 ? 'green' : score < 0 ? 'red' : 'inherit'
  }};
`

const displayTendancyArrow = (n, o) => {
  return n > o ? (
    <ArrowDropUp style={{ color: 'green' }} />
  ) : n < o ? (
    <ArrowDropDown style={{ color: 'red' }} />
  ) : (
    ''
  )
}

const formatPercentageEvolution = (v) => {
  // console.debug('formatPercentageEvolution', v)
  if (v !== 0) return `${absoluteFloor(v)}%`
  else return '--'
}
