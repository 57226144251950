import React from 'react'
import { Field, Form } from 'react-final-form'
import { DateTime } from 'luxon'
import createDecorator from 'final-form-focus'
import { FORM_ERROR } from 'final-form'
import { FlexDiv } from 'components/common'
import {
  renderFormErrorMessage,
  renderTextField,
  StyledButton,
  renderDatePickerField,
} from 'components/Uncertainty/Details/FormComponents'
import { isObjectEmpty } from 'utils/utils'
import { AutocompleteUsers } from 'components/common/Autocomplete'

const validate = (values) => {
  const errors = {}
  const dateRegex = /^\d{4}[-](0?[1-9]|1[012])[-](0?[1-9]|[12][0-9]|3[01])$/
  const dueDate = values['duedate']
  if (dueDate) {
    if (!dueDate.match(dateRegex)) {
      errors['duedate'] = 'Invalid format'
    } else if (DateTime.fromFormat(dueDate, 'dd/MM/yyyy') < DateTime.local()) {
      errors['duedate'] = 'Wrong date'
      errors[FORM_ERROR] = 'Date cannot be in the past'
    }
  }
  const requiredFields = ['title', 'duedate', 'assignee']
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })

  return errors
}

const validateAndSubmit = (submitFn) => (values, form) => {
  const errors = validate(values)
  if (isObjectEmpty(errors)) {
    submitFn(values).then((res) => form.reset())
  }
  return errors
}

const focusOnError = createDecorator()

const TaskForm = (props) => {
  return (
    <Form
      decorators={[focusOnError]}
      onSubmit={validateAndSubmit(props.onSubmit)}
      render={({ handleSubmit, submitError, submitting }) => {
        return (
          <form id="timeline_form" name="timeline" onSubmit={handleSubmit}>
            <FlexDiv direction="column" align="center" fullWidth>
              <Field
                name="title"
                component={renderTextField}
                placeholder="Task title"
                label="Add new task"
                margin="dense"
                multiline={false}
              />
              <Field
                name="description"
                component={renderTextField}
                placeholder="Description"
                minRows="4"
                maxRows="4"
                label=""
                margin="dense"
                multiline={true}
              />
              <FlexDiv fullWidth justifyContent="space-between" align="flex-end">
                <FlexDiv margins="0 4px 0 0" flex="1">
                  <Field
                    name="duedate"
                    component={renderDatePickerField}
                    defaultValue={DateTime.utc().toISODate()}
                    min={DateTime.utc().toISODate()}
                    label="Due date"
                    margin="dense"
                  />
                </FlexDiv>
                <FlexDiv>
                  <Field
                    label="Assign task"
                    name="assignee"
                    component={(props) => (
                      <div style={{ marginBottom: 4 }}>
                        <AutocompleteUsers {...props} />
                      </div>
                    )}
                    controlProps={{
                      component: 'div',
                      margin: 'dense',
                      fullWidth: true,
                    }}
                    margin="dense"
                  />
                </FlexDiv>
              </FlexDiv>
              <FlexDiv>{submitError && renderFormErrorMessage(submitError)}</FlexDiv>
              <FlexDiv justifyContent="flex-end" margins="0.5rem 0" fullWidth>
                <StyledButton type="submit" variant="contained" disabled={submitting}>
                  Add Task
                </StyledButton>
              </FlexDiv>
            </FlexDiv>
          </form>
        )
      }}
    />
  )
}

export default TaskForm
