import React from 'react'
import styled from 'styled-components'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { DateTime } from 'luxon'
import { SortArrow } from 'components/common'

const FlexHeaderCell = styled.th`
  text-align: left;
  vertical-align: middle;
  width: ${(props) => (props.width ? props.width : 'auto')};
  font-weight: 600;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  div {
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
  }
`

const generateTableRow = (data, onClickRow) => {
  if (data) {
    return data.map((n) => {
      return (
        <ClickableTableRow key={n.id} onClick={(e) => onClickRow(n.id)(e)} hover>
          <TableCell padding="none">{DateTime.fromISO(n.created).toISODate()}</TableCell>
          <TableCell padding="none">{n.title}</TableCell>
          <TableCell padding="none">{n.reference}</TableCell>
          <TableCell padding="none">{n.status}</TableCell>
          <TableCell padding="none">{n.duedate}</TableCell>
          <TableCell padding="none" style={{ maxWidth: '30px' }} />
        </ClickableTableRow>
      )
    })
  } else {
    return <TableRow />
  }
}

export default ({ data, onSort, sorted, onClickRow }) => {
  return (
    <Table>
      <thead>
        <tr style={{ height: '55px' }}>
          <FlexHeaderCell onClick={onSort('created', 'date')} width="140px">
            <div>
              Date <SortArrow property="created" sorted={sorted} />
            </div>
          </FlexHeaderCell>
          <FlexHeaderCell onClick={onSort('title')}>
            <div>
              Title <SortArrow property="title" sorted={sorted} />
            </div>
          </FlexHeaderCell>
          <FlexHeaderCell onClick={onSort('reference')} width="140px">
            <div>
              Reference <SortArrow property="reference" sorted={sorted} />
            </div>
          </FlexHeaderCell>
          <FlexHeaderCell onClick={onSort('status')} width="140px">
            <div>
              Status <SortArrow property="status" sorted={sorted} />
            </div>
          </FlexHeaderCell>
          <FlexHeaderCell onClick={onSort('duedate')} width="140px">
            <div>
              Due Date <SortArrow property="duedate" sorted={sorted} />
            </div>
          </FlexHeaderCell>
        </tr>
      </thead>
      <TableBody>{generateTableRow(data, onClickRow)}</TableBody>
    </Table>
  )
}

const ClickableTableRow = styled(TableRow)`
  &:hover {
    cursor: pointer;
  }
`
