import { createTheme } from '@material-ui/core/styles'
import { colors } from './colors'

export default createTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif",
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'antialiased',
        },
        body: {
          fontSize: '1rem',
        },
      },
    },
    MuiButton: {
      root: {
        lineHeight: 1.5,
        fontWeight: 400,
      },
    },
    MuiIconButton: {
      root: {
        height: '36px',
        width: '36px',
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '13px',
        fontFamily: "'Open Sans', sans-serif",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: '10px',
      },
    },
    MuiTableCell: {
      root: {
        padding: 8,
      },
      paddingNone: {
        padding: 2,
      },
    },
    MuiTableRow: {
      root: {
        height: 48,
      },
    },
    MuiPaper: {
      root: {
        padding: 8,
      },
    },
  },
  palette: {
    primary: {
      light: colors.light,
      main: colors.main,
      dark: colors.main,
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    text: {
      disabled: 'rgba(0, 0, 0, 0.65)',
    },
  },
})
