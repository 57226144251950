import React from 'react'
import styled from 'styled-components'
import { DateTime } from 'luxon'

import { FlexDiv } from 'components/common'
import { Card } from '@material-ui/core'
import { teal } from '@material-ui/core/colors'

function generateActionDescription({ eventType, content }) {
  let description
  switch (eventType) {
    case 'DECLARATION':
      description = `Declaration of the ${content}`
      break
    case 'STATUS':
      description = `Status changed to "${content}"`
      break
    case 'TASK':
      // Could be just a comment.
      if (content) {
        description = `New task: "${content}"`
      }
      break
    case 'ASSIGNMENT':
      const { firstname, lastname } = JSON.parse(content)
      description = `Uncertainty assigned to ${firstname} ${lastname}`
      break
    default:
      //No description for a comment.
      break
  }
  return description
}

function generateTimelineItems(items) {
  return items
    .slice(0)
    .reverse()
    .map((item, index) => (
      <TimeLineEventBox key={index}>
        <Card
          style={{
            padding: 10,
            marginBottom: 20,
            borderRight: '1px solid #ecf0f1',
            borderLeft: '8px solid ' + teal['A700'],
          }}
        >
          <FlexDiv className="header" direction="row" align="center">
            <div
              style={{
                color: '',
                fontSize: 14,
              }}
            >
              {DateTime.fromISO(item.created).toLocaleString(DateTime.DATETIME_MED)}
            </div>
            <div
              style={{
                color: teal['A700'],
                marginLeft: 10,
                marginBottom: 2,
                fontSize: 16,
              }}
            >
              {`${item.author.firstname} ${item.author.lastname}`}
            </div>
          </FlexDiv>
          <div className="description">
            {generateActionDescription(item)}
            {item.comment && <CommentBox comment={item.comment} />}
          </div>
        </Card>
      </TimeLineEventBox>
    ))
}

const TimeLineEventBox = styled(FlexDiv)`
  font-size: 14px;
  div.header {
    width: 100%;
    font-weight: 600;
    margin-bottom: 5px;
  }
  div.description {
    font-size: 14px;
    color: #6e6e6e;
    width: 100%;
    margin-top: 5px;
  }
  div.content {
    font-style: italic;
  }
`

const CommentBox = ({ comment }) => (
  <FlexDiv direction="row" margins="10px 0 0 0">
    <div className="content">{`"${comment}"`}</div>
  </FlexDiv>
)

export default function ({ timeline }) {
  return (
    <div style={{ flex: 1, overflowY: 'auto', overflowX: 'none' }}>
      {generateTimelineItems(timeline)}
    </div>
  )
}
