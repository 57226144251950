import { createStore, applyMiddleware } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

import reducers from './reducers'
import promiseMiddleware from './utils/promiseMiddleware'

export const history = createBrowserHistory()

const initialState = {}
const middleware = [routerMiddleware(history), promiseMiddleware]

// if (process.env.NODE_ENV === "development") {
//     const devToolsExtension = window.devToolsExtension;

//     if (typeof devToolsExtension === "function") {
//         middleware.push(devToolsExtension());
//     }
// }

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
})
const store = createStore(
  reducers(history),
  initialState,
  /* preloadedState, */
  composeEnhancers(
    applyMiddleware(...middleware)
    // other store enhancers if any
  )
)

export default store
