import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { Button, TextField } from '@material-ui/core'
import {
  Wing,
  WingContent,
  Container,
  Title,
  Baseline,
  Form,
  Buttons,
  leftFooterStyle,
} from './common'
import PaswordRequestConfirmationDialog from './PaswordRequestConfirmationDialog'
import { actions } from 'reducers/authenticationReducer'
import { resetAccountPassword } from 'services/api'

import logo from '../../assets/magnifor-logo.png'

class ResetPasswordRequestPage extends React.PureComponent {
  state = {
    email: null,
    displayConfirmation: false,
  }
  handleSubmit = e => {
    e.preventDefault()
    resetAccountPassword({ email: this.state.email }).then(res =>
      this.setState({ displayConfirmation: true })
    )
    // this.setState({ displayConfirmation: true })
  }

  handleConfirmationClose = e => {
    this.setState({ displayConfirmation: false }, this.props.history.push('/login'))
  }
  handleChange = e => {
    const email = e.target.value
    this.setState(state => ({ ...state, email }))
  }
  render() {
    return (
      <Container>
        <PaswordRequestConfirmationDialog
          display={this.state.displayConfirmation}
          onClose={this.handleConfirmationClose}
        />
        <Wing color="#161616" justifyContent="flex-end">
          <WingContent>
            <div>
              <Title>Risk Focus</Title>
              <Baseline>Reset your password</Baseline>
            </div>
            <div style={leftFooterStyle}>
              <div>
                <a href="https://risk-focus.com" target="_blank" rel="noopener noreferrer">
                  <Button color="primary">About</Button>
                </a>
                <a href="mailto:contact@risk-focus.com">
                  <Button color="primary">Contact</Button>
                </a>
              </div>
              <img src={logo} width="80px" alt="logo" />
            </div>
          </WingContent>
        </Wing>
        <Wing>
          <WingContent justifyContent="center">
            <Form onSubmit={this.handleSubmit}>
              <TextField
                id="user_mail"
                name="user_mail"
                type="email"
                placeholder="user@company.com"
                label="EMAIL ADDRESS"
                autoFocus="true"
                onChange={this.handleChange}
                required
              />
              <Buttons>
                <Button variant="contained" color="primary" type="submit">
                  Reset password
                </Button>
                <Button color="primary" component={Link} to="/">
                  Cancel
                </Button>
              </Buttons>
            </Form>
          </WingContent>
        </Wing>
      </Container>
    )
  }
}

export default connect(null, actions)(withRouter(ResetPasswordRequestPage))
