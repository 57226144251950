import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

class ErrorDialog extends React.Component {
  state = {
    open: this.props.error ? true : false,
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  componentWillReceiveProps({ error }) {
    if (error && error.response) this.setState({ open: true })
  }
  render() {
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="error-dialog-title"
          aria-describedby="error-dialog-description"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="error-dialog-title">Error</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.open && <FormattedMessage id={this.props.error.response.data.code} />}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default ErrorDialog
