import React from 'react'
import PropTypes from 'prop-types'

class ClickableIcon extends React.PureComponent {
  render() {
    const { icon: Icon, style, ...rest } = this.props
    return <Icon {...rest} style={{ ...style, cursor: 'pointer' }} />
  }
}

ClickableIcon.propTypes = {
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
}

export default ClickableIcon
