import React from 'react'
import { CircularProgress } from '@material-ui/core'

const FullPageOverlayLoader = () => (
  <React.Fragment>
    <div
      style={{
        position: 'fixed',
        zIndex: 999,
        backgroundColor: 'rgba(0,0,0,0)',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress size={70} thickness={7} />
    </div>
  </React.Fragment>
)

export default FullPageOverlayLoader
