import React from 'react'
import PropTypes from 'prop-types'

class ClickableComponent extends React.PureComponent {
  render() {
    const { component: Component, style, hoverable, ...rest } = this.props
    return (
      <Component {...rest} style={{ ...style, cursor: rest.onClick ? 'pointer' : 'default' }} />
    )
  }
}

ClickableComponent.propTypes = {
  component: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
}

export default ClickableComponent
