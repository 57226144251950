import { actions as authActions } from 'reducers/authenticationReducer';

export const LOAD_SETTINGS = 'dashboard/LOAD_SETTINGS';
export const LOAD_SETTINGS_SUCCESS = 'dashboard/LOAD_SETTINGS_SUCCESS';
export const LOAD_SETTINGS_FAILURE = 'dashboard/LOAD_SETTINGS_FAILURE';

export const SAVE_SETTINGS = 'dashboard/SAVE_SETTINGS';
export const SAVE_SETTINGS_SUCCESS = 'dashboard/SAVE_SETTINGS_SUCCESS';
export const SAVE_SETTINGS_FAILURE = 'dashboard/SAVE_SETTINGS_FAILURE';

export const FETCH_TASKS = 'dashboard/FETCH_TASKS';
export const FETCH_TASKS_SUCCESS = 'dashboard/FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_FAILURE = 'dashboard/FETCH_TASKS_FAILURE';

const initialState = {
  settings: {},
  loading: false,
  tasks: [],
  error: null
};

export default function dashboardReducer(state = initialState, action) {
  if (action.type.indexOf('dashboard/') !== 0) {
    return state;
  }
  switch (action.type) {
    case LOAD_SETTINGS:
    case SAVE_SETTINGS:
    case FETCH_TASKS:
      return { ...state, loading: true };
    case LOAD_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.result.data,
        error: null,
        loading: false
      };
    case SAVE_SETTINGS_SUCCESS:
      return { ...state, error: null, loading: false };
    case FETCH_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.result.data,
        error: null,
        loading: false
      };
    case FETCH_TASKS_FAILURE:
    case LOAD_SETTINGS_FAILURE:
    case SAVE_SETTINGS_FAILURE:
      return { ...state, error: action.message, loading: false };
    default:
      return state;
  }
}

const ROOT_URL = process.env.REACT_APP_API_URL;

function fetchSettings() {
  return dispatch => {
    dispatch(authActions.validateUser());
  };
}

function saveSettings(values) {
  const { landline, firstname, lastname, mobile, location } = values;
  return {
    types: [SAVE_SETTINGS, SAVE_SETTINGS_SUCCESS, SAVE_SETTINGS_FAILURE],
    promise: client =>
      client.post(`${ROOT_URL}/user/settings`, {
        landline,
        firstname,
        lastname,
        mobile,
        location
      }),
    afterSuccess: (dispatch, getState, response) => {
      //use returned data to update user object
      dispatch(authActions.updateUser(response.data));
    }
  };
}

function fetchUserTasks() {
  return {
    types: [FETCH_TASKS, FETCH_TASKS_SUCCESS, FETCH_TASKS_FAILURE],
    promise: client => client.get(`${ROOT_URL}/user/tasks`)
  };
}

export const actions = { fetchSettings, saveSettings, fetchUserTasks };
