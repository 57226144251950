import React from 'react'
import styled from 'styled-components'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { ArrowDropUp, ArrowDropDown, Delete, Edit } from '@material-ui/icons'
import { DateTime } from 'luxon'
import { FormattedMessage } from 'react-intl'
import { Container, FlexDiv, ClickableIcon } from 'components/common'

const SortArrow = ({ property, sorted }) =>
  sorted.property === property ? sorted.asc ? <ArrowDropUp /> : <ArrowDropDown /> : ''

const FlexHeaderCell = styled.th`
  text-align: left;
  vertical-align: middle;
  width: ${(props) => (props.width ? props.width : 'auto')};
  font-weight: 600;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  div {
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
  }
`

const ClickableTableRow = styled(TableRow)`
  &:hover {
    cursor: pointer;
  }
`

const generateTableRow = (data, onDelete, onEdit, onClickRow) => {
  if (data && data.length > 0) {
    return data.map((n) => {
      const isDraft = n.status === 'DRAFT'
      return (
        <ClickableTableRow
          onClick={(e) => (!isDraft ? onClickRow(n.id)(e) : null)}
          key={n.id}
          hover
        >
          <TableCell padding="none">{DateTime.fromISO(n.created).toISODate()}</TableCell>
          <TableCell padding="none">{n.title}</TableCell>
          <TableCell padding="none">{n.reference}</TableCell>
          <TableCell padding="none">
            <FormattedMessage id={`uct.status.${n.status}`} />
          </TableCell>
          <TableCell padding="none" style={{ maxWidth: '40px' }}>
            {isDraft ? (
              <React.Fragment>
                <ClickableIcon
                  icon={Edit}
                  onClick={onEdit(n.id)}
                  style={{ margin: '0 4px', fontSize: '20px' }}
                />
                <ClickableIcon
                  icon={Delete}
                  onClick={onDelete(n.id)}
                  style={{ fontSize: '20px' }}
                />
              </React.Fragment>
            ) : (
              ''
            )}
          </TableCell>
        </ClickableTableRow>
      )
    })
  } else {
    return <TableRow />
  }
}

export default ({ data, onClickRow, onDelete, onEdit, onSort, sorted }) => {
  return (
    <Container id="my_declarations_list">
      <FlexDiv>
        <Table width="100%">
          <thead>
            <TableRow>
              <FlexHeaderCell onClick={onSort('created', 'date')} width="140px">
                <div>
                  Date <SortArrow property="created" sorted={sorted} />
                </div>
              </FlexHeaderCell>
              <FlexHeaderCell onClick={onSort('title')}>
                <div>
                  Title <SortArrow property="title" sorted={sorted} />
                </div>
              </FlexHeaderCell>
              <FlexHeaderCell onClick={onSort('reference')} width="140px">
                <div>
                  Reference <SortArrow property="reference" sorted={sorted} />
                </div>
              </FlexHeaderCell>
              <FlexHeaderCell onClick={onSort('status')} width="140px">
                <div>
                  Status <SortArrow property="status" sorted={sorted} />
                </div>
              </FlexHeaderCell>
              <FlexHeaderCell />
            </TableRow>
          </thead>
          <TableBody>{generateTableRow(data, onDelete, onEdit, onClickRow)}</TableBody>
        </Table>
      </FlexDiv>
    </Container>
  )
}
