/* eslint-disable no-restricted-globals */
import React from 'react'
import { connect } from 'react-redux'
import { Grid, Tabs, Tab } from '@material-ui/core'
import { actions } from 'reducers/registerDetailsReducer'
import { MainTitle, StyledLink } from 'components/common'
import TimelineContainer from './Timeline/TimelineContainer'
import DedicatedTasksContainer from './DedicatedTasksContainer'
import DeclaredRiskContainer from './DeclaredRisk/DeclaredRiskContainer'
import StatusBar from './StatusBar'
import FullPageOverlayLoader from '../../common/FullPageOverlayLoader'
import UncertaintyLockedDialog from 'components/common/UncertaintyLockedDialog'
import UncertaintyLockTimeoutDialog from 'components/common/UncertaintyLockTimeoutDialog'
import { requestReleaseUncertainty, requestLockUncertainty } from 'services/api'
import throttle from 'lodash/throttle'

class UncertaintyDetailsPage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      uncertaintyId: props.match.params.id,
      tabId: 0,
      showInactionWarning: false,
      readOnly: true,
    }
  }
  inactionWarningInterval

  onSubmitUncertaintyDetails = (values) => {
    if (values.assignedTo) {
      values.assignedTo = { id: values.assignedTo.id }
    }
    if (values.company) {
      values.company = { id: values.company.id }
    }
    values.user = { id: values.user.id }
    this.props.updateUncertainty(this.state.uncertaintyId, values).then(this.toggleReadOnly)
  }

  handleTabChange = (evt, value) => {
    this.setState((state) => {
      if (state.tabId !== value) return { ...state, tabId: value }
      else return null
    })
  }

  toggleReadOnly = async () => {
    const { readOnly } = this.state
    if (readOnly) {
      try {
        await requestLockUncertainty(this.state.uncertaintyId)
        this.resetInactionWarningInterval()
        window.addEventListener('click', this.resetInactionWarningInterval)
      } catch (err) {
        this.setState({ locked: err })
        return null
      }
    } else {
      try {
        await requestReleaseUncertainty(this.state.uncertaintyId)
        window.removeEventListener('click', this.resetInactionWarningInterval)
        this.clearInactionWarningInterval()
      } catch (err) {
        // we ignore errors when trying to release for the moment.
        console.error(err)
      }
    }
    this.setState((state) => ({ ...state, readOnly: !state.readOnly }))
  }

  /**
   * Throttle function execution to every 20 seconds
   * This prevent it to be executed at every click
   * (see global 'onClick' listener in 'componentDid[Un-]Mount methods)
   */
  resetInactionWarningInterval = throttle(
    () => {
      this.clearInactionWarningInterval()
      console.debug('about to set interval')
      this.inactionWarningInterval = setInterval(() => {
        this.setState({ showInactionWarning: true })
      }, 180000)
    },
    20000,
    { leading: true }
  )

  clearInactionWarningInterval = () => {
    try {
      console.debug('about to clear interval')
      clearInterval(this.inactionWarningInterval)
    } catch (err) {
      console.error(err)
    }
  }

  async componentDidMount() {
    this.props.fetchTimeline(this.state.uncertaintyId)
    await this.props.fetchUncertainty(this.state.uncertaintyId)
  }

  componentWillUnmount() {
    requestReleaseUncertainty(this.state.uncertaintyId)
      .then(() => console.debug('Uncertainty successfully released'))
      .catch((err) => console.error(err))
  }

  render() {
    const { reference, status, title } = this.props.uncertainty
    const { tabId } = this.state
    return (
      <Grid container justifyContent="center" spacing={1}>
        {this.props.loading && <FullPageOverlayLoader />}
        {this.state.locked && <UncertaintyLockedDialog error={this.state.locked} />}
        {this.state.showInactionWarning && (
          <UncertaintyLockTimeoutDialog
            open={this.state.showInactionWarning}
            onTimeout={() => this.props.history.replace(`/uncertainties`)}
            onConfirm={() =>
              this.setState({ showInactionWarning: false }, () =>
                this.resetInactionWarningInterval()
              )
            }
          />
        )}
        <Grid container item xs={10}>
          <Grid item xs={12}>
            <div style={{ color: '#666666', fontSize: '14px' }}>
              <StyledLink to="/uncertainties">Risk Register</StyledLink>
              {` ${String.fromCharCode(187)} `}
              {reference}: {title}
            </div>
          </Grid>
          <Grid item xs={12}>
            <MainTitle size="medium">
              {reference}: {title}
            </MainTitle>
          </Grid>
          <Grid item xs={12}>
            <StatusBar status={status} />
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Tabs value={tabId} onChange={this.handleTabChange} indicatorColor="primary">
            <Tab style={{ textTransform: 'none !important' }} label="Timeline" />
            <Tab label="Tasks" disabled={status && status.indexOf('PENDING') === 0} />
            <Tab label="Risk details" />
          </Tabs>
        </Grid>
        <Grid item xs={10}>
          {tabId === 0 && (
            <TimelineContainer
              uncertainty={this.props.uncertainty}
              fetchData={this.props.fetchTimeline}
            />
          )}
          {tabId === 1 && <DedicatedTasksContainer data={this.props.uncertainty.tasks} />}
          {tabId === 2 && (
            <DeclaredRiskContainer
              onSubmit={this.onSubmitUncertaintyDetails}
              data={this.props.uncertainty}
              editable={true}
              readOnly={this.state.readOnly}
              toggleEditMode={this.toggleReadOnly}
            />
          )}
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => ({
  uncertainty: state.registerDetails.data,
})

export default connect(mapStateToProps, actions)(UncertaintyDetailsPage)
