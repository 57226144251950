import React from 'react';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';

export default ({ property, sorted }) =>
  sorted.property === property ? (
    sorted.asc ? (
      <ArrowDropUp />
    ) : (
      <ArrowDropDown />
    )
  ) : (
    ''
  );
