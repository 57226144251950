import React from 'react'
import { Button, withTheme } from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import PropTypes from 'prop-types'
import { FlexDiv } from 'components/common'
import { Wing, WingContent, Container, Title, Baseline, leftFooterStyle } from './.'
import NewPasswordForm from './NewPasswordForm'

import logo from 'assets/magnifor-logo.png'

const PasswordPage = ({ baseline, onSubmit, theme }) => {
  const [hasUpper, setHasUpper] = React.useState(false)
  const [hasLower, setHasLower] = React.useState(false)
  const [nbChar, setNbChar] = React.useState(0)
  const [hasSpecial, setHasSpecial] = React.useState(false)
  const [hasNumber, setHasNumber] = React.useState(false)

  const handlePasswordChange = (evt) => {
    const input = evt.target.value
    setNbChar(input.length)
    for (let i = 0; i < input.length; i++) {
      const char = input.charAt(i)
      if (!Number.isNaN(Number.parseInt(char, 10))) setHasNumber(true)
      else if (char.match(/[@#$;%[_\]]/g)) setHasSpecial(true)
      else if (char === char.toUpperCase()) setHasUpper(true)
      else if (char === char.toLowerCase()) setHasLower(true)
    }
    return evt
  }

  const calculatePasswordStrength = () => (password) => {
    let strenghLevel = 0
    if (hasUpper && hasLower && hasNumber) {
      if (nbChar >= 8 && !hasSpecial && nbChar < 10) {
        strenghLevel = 1
      } else if (nbChar >= 10 && hasSpecial) {
        strenghLevel = 3
      } else if (nbChar >= 10 || (nbChar >= 8 && hasSpecial)) {
        strenghLevel = 2
      }
    }
    return strenghLevel
  }

  return (
    <Container>
      <Wing color="#161616" justifyContent="flex-end">
        <WingContent>
          <div>
            <Title>Risk Focus</Title>
            <Baseline>{baseline}</Baseline>
            <div style={{ color: 'white', fontSize: '14px', marginTop: '60px' }}>
              <p>
                Your password <strong>must</strong> contains at least:
              </p>
              <div>
                <FlexDiv>
                  {nbChar >= 8 ? (
                    <CheckBox style={{ color: theme.palette.primary.main }} />
                  ) : (
                    <CheckBoxOutlineBlank />
                  )}
                  &nbsp; eight characters ({nbChar}
                  /8)
                </FlexDiv>
                <FlexDiv>
                  {hasUpper ? (
                    <CheckBox style={{ color: theme.palette.primary.main }} />
                  ) : (
                    <CheckBoxOutlineBlank />
                  )}
                  &nbsp; ONE UPPERCASE LETTER
                </FlexDiv>
                <FlexDiv>
                  {hasLower ? (
                    <CheckBox style={{ color: theme.palette.primary.main }} />
                  ) : (
                    <CheckBoxOutlineBlank />
                  )}
                  &nbsp; one lowercase letter
                </FlexDiv>
                <FlexDiv>
                  {hasNumber ? (
                    <CheckBox style={{ color: theme.palette.primary.main }} />
                  ) : (
                    <CheckBoxOutlineBlank />
                  )}
                  &nbsp; one number
                </FlexDiv>
              </div>
              <p>We highly recommend at least :</p>
              <FlexDiv>
                {nbChar >= 10 ? (
                  <CheckBox style={{ color: theme.palette.primary.main }} />
                ) : (
                  <CheckBoxOutlineBlank />
                )}
                &nbsp; {`ten characters (${nbChar}/10)`}
              </FlexDiv>
              <FlexDiv>
                {hasSpecial ? (
                  <CheckBox style={{ color: theme.palette.primary.main }} />
                ) : (
                  <CheckBoxOutlineBlank />
                )}
                &nbsp; one spec!al char@cters, such as:&nbsp;
                <strong style={{ fontStyle: '16px' }}>!@#&$%[_]</strong>
              </FlexDiv>
            </div>
          </div>
          <div style={leftFooterStyle}>
            <div>
              <a href="https://risk-focus.com" target="_blank" rel="noopener noreferrer">
                <Button color="primary">About</Button>
              </a>
              <a href="mailto:contact@risk-focus.com">
                <Button color="primary">Contact</Button>
              </a>
            </div>
            <img src={logo} width="80px" alt="logo" />
          </div>
        </WingContent>
      </Wing>
      <Wing>
        <WingContent justifyContent="center">
          <NewPasswordForm
            onSubmit={onSubmit}
            passwordCheck={handlePasswordChange}
            passwordStrengthLevel={calculatePasswordStrength()}
          />
        </WingContent>
      </Wing>
    </Container>
  )
}

PasswordPage.propTypes = {
  baseline: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default withTheme(PasswordPage)
