import React from 'react'
import styled from 'styled-components'
// import { ArrowUpward, ArrowDownward } from '@material-ui/icons'
import { BlocContent } from 'components/common'
import { calculatePercentageChange, compare, formatPercentageEvolution } from 'utils/statUtils'

export default ({ values, desc, lessIsBetter = false }) => {
  const { n, o } = values
  return (
    <BlocContent style={{ marginTop: 0, marginBottom: '10px', width: '300px' }}>
      <StyledFirstLine>
        <CounterDiv>{n ? n : '-'}</CounterDiv>
        <DescriptionDiv>{desc}</DescriptionDiv>
        {/* <TrendDiv>{displayTendancyArrow(n, o)}</TrendDiv> */}
      </StyledFirstLine>
      {o != null && (
        <PercentageDiv values={values} lessIsBetter={lessIsBetter}>
          {formatPercentageEvolution(calculatePercentageChange(n, o))}
        </PercentageDiv>
      )}
    </BlocContent>
  )
}

const PercentageDiv = styled.div`
  color: #474747;
  font-size: 12px;
  display: flex;
  align-self: flex-end;
  color: ${(props) => {
    const score = compare(props.values, props.lessIsBetter)
    return score > 0 ? 'green' : score < 0 ? 'red' : 'inherit'
  }};
`

// const displayTendancyArrow = (n, o) => {
//   return n > o ? (
//     <ArrowUpward style={{ color: 'green' }} />
//   ) : n < o ? (
//     <ArrowDownward style={{ color: 'red' }} />
//   ) : (
//     ''
//   )
// }

const StyledFirstLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 24px;
`

// const TrendDiv = styled.div`
//   text-align: right;
// `
const CounterDiv = styled.div`
  color: #373737;
  font-size: 24px;
  margin: 0.5rem 0;
  margin-right: 1rem;
`
const DescriptionDiv = styled.div`
  color: #474747;
  font-size: 16px;
`
