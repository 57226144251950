import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // Typography,
} from '@material-ui/core'

let countDownInterval
let noAnswerTimeout

const UncertaintyLockTimeoutDialog = ({ open, onConfirm, onTimeout, timeToTimeout = 60000 }) => {
  const [isOpen, setOpen] = React.useState(!!open)
  const [timeLeft, setTimeLeft] = React.useState(timeToTimeout)

  React.useEffect(() => {
    setOpen(!!open)
    if (!!open) {
      clearCountDown()
      noAnswerTimeout = setTimeout(() => {
        onTimeout()
      }, timeToTimeout)
      // Update the count down every 1 second
      countDownInterval = setInterval(() => {
        setTimeLeft(prevTimeLeft => {
          if (prevTimeLeft <= 0) {
            clearInterval(countDownInterval)
          }
          return prevTimeLeft - 1000
        })
      }, 1000)
    }
  }, [onTimeout, open, timeToTimeout])

  // const initCountDown = () => {
  //   clearCountDown()
  //   noAnswerTimeout = setTimeout(() => {
  //     onTimeout()
  //   }, timeToTimeout)
  //   // Update the count down every 1 second
  //   countDownInterval = setInterval(() => {
  //     setTimeLeft(prevTimeLeft => {
  //       if (prevTimeLeft <= 0) {
  //         clearInterval(countDownInterval)
  //       }
  //       return prevTimeLeft - 1000
  //     })
  //   }, 1000)
  // }

  const clearCountDown = () => {
    console.debug('About to clear timers')
    clearTimeout(noAnswerTimeout)
    console.debug('[clearCOuntDown]About to clear countDownInterval')
    clearInterval(countDownInterval)
  }

  const handleClose = e => {
    e.stopPropagation()
    clearCountDown()
    setOpen(false)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="uct-lock-dialog-title"
      aria-describedby="uct-lock-dialog-description"
      fullWidth={true}
      maxWidth="md"
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      onBackdropClick={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
    >
      <DialogTitle id="uct-lock-dialog-title">Are you still there ? </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {isOpen &&
            'Please confirm that you are still editing the current uncertainty. Otherwise, your changes will be lost.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <Typography variant="button" color="primary">
          {timeLeft / 1000}
        </Typography> */}
        <Button
          onClick={() => {
            clearCountDown()
            onConfirm()
          }}
          color="primary"
        >
          Confirm - {timeLeft / 1000}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(UncertaintyLockTimeoutDialog)
