import React from 'react'
import { injectIntl } from 'react-intl'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@material-ui/core'
import { renderTextField, renderSelect } from 'components/Staff/common/FormComponents'
import { FlexDiv } from 'components/common'
import { ErrorMessage } from 'components/Login/common'

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Required'
  }
  if (!values.role) {
    errors.role = 'Required'
  }
  return errors
}

const generateList = elements => {
  return elements.map((n, index) => {
    return (
      <MenuItem key={index} value={n.id}>
        {n.name}
      </MenuItem>
    )
  })
}

class AddUserDialog extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      email: '',
      team: { id: '' },
      role: '',
      firstname: '',
      lastname: '',
    }
    this.roles = [{ id: 'BASIC_USER', name: 'User' }, { id: 'TEAM_MANAGER', name: 'Team Manager' }]
  }

  static defaultProps = {
    onClick: () => null,
    onSubmit: () => null,
    initialValues: [],
  }

  _handleSubmit = async values => {
    try {
      await this.props.onSubmit(values)
      this.props.onClose()
    } catch (err) {
      return { [FORM_ERROR]: this.props.intl.formatMessage({ id: err.response.data.code }) }
    }
  }

  render() {
    const { open, onClose, initialValues } = this.props
    return (
      <Dialog open={open}>
        <FlexDiv width="30rem" margins="0 auto" align="flex-start">
          <Form
            onSubmit={this._handleSubmit}
            validate={validate}
            initialValues={initialValues}
            render={({
              submitError,
              handleSubmit,
              submitting,
              dirtySinceLastSubmit,
              form,
              values,
            }) => {
              // Reset form with current values to reset form "submitFailed"
              // status and remove error message
              if (dirtySinceLastSubmit) form.reset(values)
              return (
                <form onSubmit={handleSubmit} style={{ minHeight: '300px' }}>
                  <DialogTitle id="user-creation-dialog-title">User Information</DialogTitle>
                  {/* Display error message */}
                  {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
                  <DialogContent>
                    <Field id="f_id" name="id" type="hidden" component="input" />
                    <Field
                      id="f_email"
                      name="email"
                      label="Email"
                      required
                      render={renderTextField}
                    />
                    <Field
                      id="f_firstname"
                      name="firstname"
                      label="Firstname"
                      required
                      render={renderTextField}
                    />
                    <Field
                      id="f_lastname"
                      name="lastname"
                      label="Lastname"
                      required
                      render={renderTextField}
                    />
                    <Field id="f_team" name="team" label="Team" render={renderSelect}>
                      {generateList(this.props.teams)}
                    </Field>
                    <Field id="f_role" name="role" label="Role" required render={renderSelect}>
                      {generateList(this.roles)}
                    </Field>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={onClose} color="primary">
                      Cancel
                    </Button>
                    <Button type="submit" color="primary" variant="contained" disabled={submitting}>
                      Ok
                    </Button>
                  </DialogActions>
                </form>
              )
            }}
          />
        </FlexDiv>
      </Dialog>
    )
  }
}

export default injectIntl(AddUserDialog)
