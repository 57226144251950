import React from 'react'
import qs from 'qs'
import PasswordPage from './common/PasswordPage'
import { activateUserAccount } from 'services/api'
import PasswordSuccessAlert from './common/PasswordSuccessAlert'

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      token: qs.parse(props.location.search, { ignoreQueryPrefix: true }).token,
      displayModal: false,
    }
  }

  handleCloseModal = () => {
    this.props.history.push('/login')
  }

  handleSubmit = async values => {
    let error = {}
    try {
      await activateUserAccount({
        ...values,
        token: this.state.token,
      })
      this.setState({ displayModal: true })
    } catch (err) {
      console.error(err)
      error.message = this.props.intl.formatMessage({ id: err.response.data.code })
      console.error(error.message)
    }
    return Promise.resolve(error)
  }

  render() {
    return (
      <React.Fragment>
        <PasswordSuccessAlert
          openIf={this.state.displayModal}
          onClose={this.handleCloseModal}
          title="Successful modification"
          message="Your password has been successfully changed."
        />
        <PasswordPage baseline="Change your password" onSubmit={this.handleSubmit} />
      </React.Fragment>
    )
  }
}

export default ResetPasswordPage
