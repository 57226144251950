import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { FlexDiv } from 'components/common'
import { UsersSection } from './Users'
import { actions as usersActions } from 'reducers/usersReducer'
import { actions as teamsActions } from 'reducers/teamsReducer'
import { TeamsSection } from './Teams'
import FullPageOverlayLoader from '../common/FullPageOverlayLoader'

//TODO export component
const MainTitle = styled.h1`
  font-size: ${(props) => {
    switch (props.size) {
      case 'small':
        return '1rem'
      case 'medium':
        return '2rem'
      case 'large':
      default:
        return '3rem'
    }
  }};
`

class StaffPage extends React.PureComponent {
  constructor(props) {
    super(props)
    props.getUsers()
    props.getTeams()
  }

  render() {
    return (
      <React.Fragment>
        {this.props.loading && <FullPageOverlayLoader />}
        <FlexDiv fullWidth justifyContent="flex-start">
          <MainTitle size="small">Users</MainTitle>
        </FlexDiv>
        <FlexDiv
          direction="column"
          margins="1rem 0"
          background="#fff"
          style={{
            border: '1px solid #bdc3c7',
            padding: '0 1rem',
            borderRadius: '4px',
          }}
        >
          <UsersSection users={this.props.users} teams={this.props.teams} />
        </FlexDiv>
        <FlexDiv fullWidth justifyContent="flex-start">
          <MainTitle size="small">Teams</MainTitle>
        </FlexDiv>
        <FlexDiv
          direction="column"
          margins="1rem 0"
          background="#fff"
          style={{
            border: '1px solid #bdc3c7',
            padding: '0 1rem',
            borderRadius: '4px',
          }}
        >
          {this.props.roles &&
            this.props.roles.filter((role) => role.indexOf('RISK_MANAGER') > -1).length > 0 && (
              <TeamsSection teams={this.props.teams} users={this.props.users} />
            )}
        </FlexDiv>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.users.loading || state.teams.loading,
  users: state.users.data,
  teams: state.teams.data,
  roles: state.auth.user.roles,
})

export default connect(mapStateToProps, { ...usersActions, ...teamsActions })(StaffPage)
