import { makeStyles } from '@material-ui/core'
import React from 'react'
import { scoreColors } from 'utils/constants'
import { FormattedMessage } from 'react-intl'

export default ({ uncertainties = [] }) => {
  const classes = useStyles()

  const generateRows = (uct) => {
    return (
      <>
        <tr key={`first-${uct.id}`}>
          <td colSpan={2} className={classes.title}>
            {uct.title}
          </td>
          <td className={classes[`level-${uct.level}`]}>
            <FormattedMessage id={`uct.level.${uct.level}`} defaultMessage=" " />
          </td>
        </tr>
        <tr key={`second-${uct.id}`}>
          <td className={classes.description}>Description</td>
          <td colSpan={2}>{uct.description}</td>
        </tr>
      </>
    )
  }
  return (
    <table className={classes.table}>
      <tbody>{uncertainties.map(generateRows)}</tbody>
    </table>
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    '& td': {
      padding: 6,
      height: 45,
      border: '1px solid black',
      verticalAlign: 'middle',
      fontSize: '12px',
    },
  },
  description: {
    width: 120,
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 14,
  },
  'level-HIGH': {
    width: 130,
    textAlign: 'center',
    backgroundColor: scoreColors.critical,
    fontWeight: 600,
    fontSize: 14,
  },
  'level-MODERATE': {
    width: 130,
    textAlign: 'center',
    backgroundColor: scoreColors.moderate,
    fontWeight: 600,
    fontSize: 14,
  },
  'level-LOW': {
    width: 130,
    textAlign: 'center',
    backgroundColor: scoreColors.low,
    fontWeight: 600,
    fontSize: 14,
  },
  header: {
    borderColor: 'white',
    width: '96px',
  },
  critical: {
    border: '1px solid grey',
    width: '96px',
    fontWeight: 600,
    backgroundColor: scoreColors.critical,
  },
  moderate: {
    border: '1px solid grey',
    width: '96px',
    fontWeight: 600,
    backgroundColor: scoreColors.moderate,
  },
  low: {
    border: '1px solid grey',
    width: '96px',
    fontWeight: 600,
    backgroundColor: scoreColors.low,
  },
  title: {
    fontSize: '14px !important',
    fontWeight: 600,
  },
}))
