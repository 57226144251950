import React from 'react'
import { Field, Form } from 'react-final-form'
import { Button, Input, InputLabel, withStyles } from '@material-ui/core'
import styled from 'styled-components'
import { injectIntl, FormattedMessage } from 'react-intl'
import { FlexDiv } from 'components/common'
import { ErrorMessage } from 'components/Staff/common/FormComponents'
import { Search } from '@material-ui/icons'
import { hasRole } from 'utils/utils'
import ChangeTeamManagerDialog from './ChangeTeamManager/ChangeTeamManagerDialog'
import { assignTeamManager } from 'services/api'
import ConfirmSelectionDialog from './ChangeTeamManager/ConfirmSelectionDialog'
import SelectionAlertDialog from './ChangeTeamManager/SelectionAlertDialog'
import { connect } from 'react-redux'
import { actions } from 'reducers/teamsDetailReducer'

const validate = (values) => {
  const errors = {}
  const requiredFields = ['name']

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  return errors
}

class TeamDetailForm extends React.Component {
  state = {
    editing: false,
    users: [],
    selectedTeamManager: null,
    displaySearchTeamManagerDialog: false,
    displaySelectionAlert: false,
    displaySelectionConfirmation: false,
  }

  editForm = (e) => {
    if (!this.state.editing) {
      e.preventDefault()
    }
    this.setState((state) => ({ editing: !state.editing }))
  }

  handleCancel = (form) => (e) => {
    e.preventDefault()
    form.reset()
    this.setState((state) => ({ editing: !state.editing }))
  }

  _formatTeamManagerField = (value) => {
    if (this.props.users && this.props.users.length > 0) {
      const manager = this.getUserById(value)
      return manager ? `${manager.firstname} ${manager.lastname}` : 'No team manager selected yet'
    }
  }
  _closeTeamManagerSelectionDialog = () => {
    this.setState({ displaySearchTeamManagerDialog: false })
  }

  handleSelectTeamManager = (id) => {
    const selectedManager = this.getUserById(id)
    this.setState({ selectedTeamManager: selectedManager }, () =>
      this._checkIfTeamManagerEligible(selectedManager)
    )
  }

  _checkIfTeamManagerEligible = (user) => {
    let message
    if (!user.team) {
      message =
        'This user is not currently part of any team. Assign to current team as team manager ?'
      this._askToConfirmSelection(message)
    } else {
      if (hasRole('TEAM_MANAGER', user)) {
        message = 'Another team manager cannot be assigned.'
        this._displayTeamManagerAlertDialogAndCancelSelection(message)
      } else if (user.team.id !== this.props.teamId) {
        message = 'This user has a different team. Assign to current team as team manager ?'
        this._askToConfirmSelection(message)
      } else {
        this.confirmSelectedTeamManager()
      }
    }
  }

  _searchForTeamManager = () => {
    this.setState({ displaySearchTeamManagerDialog: true })
  }

  _filterAvailableTeamManagers = () => {
    return this.props.users.filter(
      (user) =>
        user.roles.indexOf('RISK_MANAGER') === -1 && user.id !== this.props.initialValues.manager
    )
  }

  _askToConfirmSelection = (message) => {
    this.setState({ displaySelectionConfirmation: message })
  }

  _closeTeamManagerConfirmationDialog = () => {
    this.setState({ displaySelectionConfirmation: null })
  }

  _displayTeamManagerAlertDialogAndCancelSelection = (message) => {
    this.setState({ displaySelectionAlert: message, selectedTeamManager: null })
  }

  confirmSelectedTeamManager = async () => {
    await assignTeamManager({
      teamId: this.props.teamId,
      managerId: this.state.selectedTeamManager.id,
    })
    this._closeTeamManagerConfirmationDialog()
    this._closeTeamManagerSelectionDialog()
    await this.props.fetchDetails(this.props.teamId)
  }

  _closeTeamManagerAlertDialog = () => {
    this.setState({ displaySelectionAlert: null })
  }

  getUserById = (id) => {
    return this.props.users.filter((u) => u.id === id)[0]
  }

  render() {
    const { onSubmit, initialValues, intl } = this.props
    const { displaySearchTeamManagerDialog, displaySelectionAlert, displaySelectionConfirmation } =
      this.state
    return (
      <React.Fragment>
        {displaySelectionAlert && (
          <SelectionAlertDialog
            message={displaySelectionAlert}
            onCancel={this._closeTeamManagerAlertDialog}
          />
        )}
        {displaySelectionConfirmation && (
          <ConfirmSelectionDialog
            message={displaySelectionConfirmation}
            onCancel={this._closeTeamManagerConfirmationDialog}
            onConfirm={this.confirmSelectedTeamManager}
          />
        )}
        {displaySearchTeamManagerDialog && (
          <ChangeTeamManagerDialog
            open={displaySearchTeamManagerDialog}
            users={this._filterAvailableTeamManagers()}
            onClose={this._closeTeamManagerSelectionDialog}
            onSelectTeamManager={this.handleSelectTeamManager}
          />
        )}
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
          render={({
            submitError,
            handleSubmit,
            dirtySinceLastSubmit,
            submitFailed,
            submitting,
            form,
            values,
          }) => {
            // Reset form with current values to reset form "submitFailed"
            // status and remove error message
            if (dirtySinceLastSubmit) form.reset(values)
            return (
              <form onSubmit={handleSubmit} style={{ minHeight: '300px' }}>
                {/* Display error message */}
                {submitFailed && submitError && <ErrorMessage>{submitError}</ErrorMessage>}
                <FlexDiv maxWidth="30rem" direction="column" align="start">
                  <FlexDiv margins={this.fieldMargins} align="center">
                    <StyledInputLabel>Name</StyledInputLabel>
                    <Field
                      name="name"
                      disableUnderline
                      inputProps={{ disabled: !this.state.editing }}
                      autoComplete="off"
                      classes={
                        this.state.editing
                          ? {
                              root: this.props.classes.textFieldRoot,
                              input: this.props.classes.textFieldInputEditing,
                            }
                          : {
                              root: this.props.classes.textFieldRoot,
                              input: this.props.classes.textFieldInput,
                            }
                      }
                      required
                      render={renderInput}
                    />
                  </FlexDiv>
                  <FlexDiv margins={this.fieldMargins} align="center">
                    <StyledInputLabel>Team manager</StyledInputLabel>
                    <Field
                      name="manager"
                      disableUnderline
                      inputProps={{ disabled: true }}
                      autoComplete="off"
                      classes={{
                        root: this.props.classes.textFieldRoot,
                        input: this.props.classes.textFieldInput,
                      }}
                      format={(value) => this._formatTeamManagerField(value)}
                      render={renderInput}
                    />
                    {this.state.editing ? (
                      <div>
                        <Button
                          variant="extendedFab"
                          aria-label="Change"
                          onClick={this._searchForTeamManager}
                        >
                          <Search />
                        </Button>
                      </div>
                    ) : null}
                  </FlexDiv>
                  <FlexDiv margins={this.fieldMargins} align="center">
                    <StyledInputLabel>Number of members</StyledInputLabel>
                    <Field
                      name="members"
                      disableUnderline
                      inputProps={{ disabled: true }}
                      autoComplete="off"
                      classes={{
                        root: this.props.classes.textFieldRoot,
                        input: this.props.classes.textFieldInput,
                      }}
                      format={(value) => value && value.length}
                      render={renderInput}
                    />
                  </FlexDiv>

                  <FlexDiv align="start">
                    <StyledButton
                      type="submit"
                      disabled={this.state.editing ? submitting : false}
                      variant="contained"
                      onClick={this.editForm}
                    >
                      {this.state.editing
                        ? intl.formatMessage({ id: 'buttons.label.save' })
                        : intl.formatMessage({ id: 'buttons.label.edit' })}
                    </StyledButton>
                    {this.state.editing ? (
                      <StyledButton
                        type="button"
                        variant="contained"
                        onClick={this.handleCancel(form)}
                      >
                        <FormattedMessage id="buttons.label.cancel" />
                      </StyledButton>
                    ) : (
                      <div />
                    )}
                  </FlexDiv>
                </FlexDiv>
              </form>
            )
          }}
        />
      </React.Fragment>
    )
  }
}

const StyledInputLabel = styled(InputLabel)`
  min-width: 10rem;
  width: 10rem;
`

const StyledButton = styled(Button)`
  && {
    width: ${(props) => (props.width ? props.width : '5rem')};
    font-weight: 600;
    font-size: 1rem;
    text-transform: none;
    margin: 0.5rem;
  }
`

const styles = (theme) => ({
  container: {
    fontSize: '14 !important',
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
  },
  inputLabelFocused: {
    color: 'purple',
  },
  inputUnderline: {
    '&:after': {
      backgroundColor: 'purple',
    },
  },
  textFieldRoot: {
    padding: 0,
  },
  textFieldInput: {
    fontSize: 14,
    padding: '11px 13px',
    width: 'calc(20rem - 24px)',
  },
  textFieldInputEditing: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '10px 12px',
    width: 'calc(20rem - 24px)',
    transition: theme.transitions.create(['border-color', 'border-width', 'box-shadow', 'padding']),
    '&:focus': {
      borderWidth: '1px',
      padding: '10px 12px',
      borderColor: theme.palette.primary.light,
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    },
  },
  textFieldPhoneInputEditing: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: '1rem',
    padding: '10px 12px',
    width: 'calc(20rem - 58px)',
    transition: theme.transitions.create(['border-color', 'border-width', 'box-shadow', 'padding']),
    '&:focus': {
      borderWidth: '1px',
      padding: '10px 12px',
      borderColor: theme.palette.primary.light,
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    },
  },
  textFieldFormLabel: {
    fontSize: 14,
  },
})

const renderInput = ({ input, meta, ...rest }) => {
  const { touched, error } = meta
  return <Input {...input} {...rest} error={touched && error ? true : false} />
}

TeamDetailForm = connect(null, actions)(TeamDetailForm)
export default injectIntl(withStyles(styles)(TeamDetailForm))
