import React from 'react'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  withStyles,
} from '@material-ui/core'
import { teal } from '@material-ui/core/colors'
import styled from 'styled-components'
//TODO add proptypes

const inputFontSize = '12px'
const labelFontSize = '14px'
const styles = (theme) => ({
  controlRoot: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  selectRoot: {
    width: '20rem',
    marginTop: theme.spacing(1),
  },
  textFieldRoot: {
    width: '100%',
    'label + &': {
      marginTop: theme.spacing(0),
    },
  },
  textFieldInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #bdc3c7',
    fontSize: inputFontSize,
    padding: '5px 6px',
    width: 'calc(100% - 12px)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: teal['A200'],
    },
    multiline: {
      padding: `${theme.spacing(1) - 1}px 0 ${theme.spacing(1) - 1}px`,
    },
  },
  textFieldInputError: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid ' + theme.palette.error.main,
    fontSize: inputFontSize,
    padding: '5px 6px',
    width: 'calc(100% - 12px)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: teal['A200'],
    },
    multiline: {
      padding: `${theme.spacing(1) - 1}px 0 ${theme.spacing(1) - 1}px`,
    },
  },
  label: {
    fontSize: labelFontSize,
  },
  labelFormControl: {
    position: 'relative',
  },
  labelShrink: {
    transform: 'none', //Prevent text from being shrunk
  },
})

export const SelectBordered = withStyles(styles)(({ classes, input, children, ...custom }) => {
  return (
    <Select
      {...input}
      // onChange={input.onChange}
      onChange={custom.onChange || input.onChange}
      value={custom.currentValue || input.value}
      children={children}
      classes={{ root: classes.selectRoot }}
      input={
        <Input
          disableUnderline
          classes={{
            root: classes.textFieldRoot,
            input: classes.textFieldInput,
          }}
        />
      }
      {...custom}
    />
  )
})

export const StyledFormControl = styled(FormControl)`
  width: ${(props) => (props.width ? props.widh : props.fullWidth ? '100%' : 'auto')};
`

export const StyledFormControlLabel = withStyles(styles)(
  ({ classes, children, component, ...rest }) => (
    <FormControlLabel {...rest}>{children}</FormControlLabel>
  )
)

export const StyledFormLabel = withStyles(styles)((props) => (
  <FormLabel classes={{ root: props.classes.label }}>{props.children}</FormLabel>
))

export const renderFormControlSelect = ({ children, label, meta, controlProps, ...rest }) => {
  return (
    <StyledFormControl error={!!(meta.touched && meta.error)} {...controlProps}>
      {!!label && <StyledFormLabel component="label">{label}</StyledFormLabel>}
      <SelectBordered meta={meta} {...rest}>
        {children}
      </SelectBordered>
      <FormHelperText>{!!(meta.touched && meta.error) && meta.error.toString()}</FormHelperText>
    </StyledFormControl>
  )
}
