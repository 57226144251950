import React from 'react'
import { DateTime } from 'luxon'

import { Bloc } from 'components/common'
import { fetchKPIOverview, fetchKPIDetailed } from 'services/api'
import OverviewBloc from '../OverviewBloc'
import DetailedStatistics from '../DetailedStatistics'

class SummaryTab extends React.Component {
  state = {
    reviewNotifications: [],
    assignmentNotifications: [],
    notifications: [],
    statistics: { overview: {}, detailed: {} },
  }

  fetchStatistics = async () => {
    let statsOverview,
      statsDetailed = {}
    try {
      statsOverview = (await fetchKPIOverview()).data
    } catch (err) {
      console.error(err)
    }
    try {
      statsDetailed = (await fetchKPIDetailed(
        DateTime.local()
          .minus({ days: 30 })
          .toISO({ suppressMilliseconds: true }),
        DateTime.local().toISO({ suppressMilliseconds: true })
      )).data
    } catch (err) {
      console.error(err)
    }
    this.setState(state => ({
      ...state,
      statistics: { ...state.statistics, overview: statsOverview, detailed: statsDetailed },
    }))
  }

  fetchStatsDetailed = async (dateStart, dateEnd) => {
    try {
      dateStart = DateTime.fromJSDate(dateStart).toISO({ suppressMilliseconds: true })
      dateEnd = DateTime.fromJSDate(dateEnd).toISO({ suppressMilliseconds: true })
      const statsDetailed = (await fetchKPIDetailed(dateStart, dateEnd)).data
      this.setState(state => ({
        ...state,
        statistics: { ...state.statistics, detailed: statsDetailed },
      }))
    } catch (err) {
      console.error(err)
    }
  }

  componentDidMount() {
    this.fetchStatistics()
  }
  render() {
    const { statistics } = this.state
    return (
      <React.Fragment>
        <OverviewBloc statistics={statistics.overview} />
        <Bloc title="Details">
          <DetailedStatistics
            statistics={statistics}
            initialPeriod={1}
            handleStatsUpdate={this.fetchStatsDetailed}
          />
        </Bloc>
      </React.Fragment>
    )
  }
}

export default SummaryTab
