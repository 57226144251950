import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Grid, Paper } from '@material-ui/core'

export const BlocContent = styled.div`
  background-color: white;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
`
export const BlocContainer = styled.div`
  padding-top: 1rem;
`
// const Bloc = ({ title, children, contentProps = {}, ...rest }) => {
//   return (
//     <BlocContainer style={rest.style} {...rest}>
//       {title || '\u00A0'}
//       <BlocContent style={contentProps}>{children}</BlocContent>
//     </BlocContainer>
//   )
// }

const Bloc = ({ title, children, contentProps = {}, ...rest }) => {
  return (
    <Grid container item xs={12} spacing={1}>
      {title && (
        <Grid item xs={12}>
          {title || '\u00A0'}
        </Grid>
      )}
      <Grid item xs={12}>
        <Paper variant="outlined" style={contentProps}>
          {children}
        </Paper>
      </Grid>
    </Grid>
  )
}

Bloc.propTypes = {
  title: PropTypes.string,
  contentProps: PropTypes.object,
}

export { Bloc }
