import React from 'react'
import { injectIntl } from 'react-intl'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { FlexDiv, SearchBar, withPaging, withSorting } from 'components/common'
import MembersList from './MembersList'
import ErrorDialog from 'components/common/ErrorDialog'

const SortingPagingMembersList = withSorting(withPaging(MembersList))

class AddTeamMemberDialog extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      filteredUsers: null,
      error: null,
      selectedUsers: [],
    }
  }

  static defaultProps = {
    onClick: () => null,
    onSubmit: () => null,
    initialValues: [],
  }

  _handleSearchMember = filter => {
    this.setState((state, props) => ({
      ...state,
      filteredUsers: props.users.filter(filter),
    }))
  }

  _getUserById = id => {
    return this.props.users.filter(u => u.id === id)[0]
  }

  _addUserToSelection = (userId, state) => {
    return { ...state, selectedUsers: [...state.selectedUsers, userId] }
  }

  _removeUserFromSelection = (userId, state) => {
    const selectedUsers = Array.from(state.selectedUsers)
    selectedUsers.splice(selectedUsers.indexOf(userId), 1)
    return { ...state, selectedUsers: selectedUsers }
  }

  _handleSelectTeamMember = id => evt => {
    evt.preventDefault()
    evt.stopPropagation()
    const user = this._getUserById(id)
    if (user)
      this.setState(state => {
        if (state.selectedUsers.indexOf(id) === -1) {
          return this._addUserToSelection(id, state)
        } else {
          return this._removeUserFromSelection(id, state)
        }
      })
  }

  _handleSubmitTeamMembers = () => {
    this.props.onConfirmTeamMembers(this.state.selectedUsers)
  }

  render() {
    const { open, onClose, users } = this.props
    // const availableUsers
    return (
      <Dialog open={open} fullWidth={true} maxWidth="md" fullScreen={false}>
        <ErrorDialog error={this.state.error} />
        <FlexDiv width="40rem" margins="0 auto" direction="column">
          <div>
            <DialogTitle id="team-manager-selection-dialog-title">Add Team Member(s)</DialogTitle>
            {/* Display error message */}
            {/* {error && <ErrorMessage>{submitError}</ErrorMessage>} */}
            <DialogContent>
              <SearchBar
                onSearch={this._handleSearchMember}
                onFields={['firstname', 'lastname']}
                placeholder="Search by firstname or lastname"
              />
              <SortingPagingMembersList
                initialSort={{ property: 'created', type: 'date', asc: false }}
                data={this.state.filteredUsers || users}
                onSelectMember={this._handleSelectTeamMember}
                selectedUsers={this.state.selectedUsers}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={this._handleSubmitTeamMembers}
                color="primary"
                variant="contained"
                disabled={this.state.selectedUsers.length === 0}
              >
                Ok
              </Button>
            </DialogActions>
          </div>
        </FlexDiv>
      </Dialog>
    )
  }
}

export default injectIntl(AddTeamMemberDialog)
