import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { statusColors } from 'utils/constants'
import { injectIntl } from 'react-intl'

const generateChartData = ({ assigned, pending, closed }, intl) => ({
  datasets: [
    {
      data: [assigned, pending, closed],
      backgroundColor: [statusColors.assigned, statusColors.pending, statusColors.closed],
    },
  ],

  // These labels appear in the legend and in the tooltips when hovering different arcs
  labels: [
    intl.formatMessage({ id: 'legend.status.pending' }),
    intl.formatMessage({ id: 'legend.status.assigned' }),
    intl.formatMessage({ id: 'legend.status.closed' }),
  ],
})

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: 0,
  },
  plugins: {
    legend: {
      position: 'right',
      labels: {
        boxWidth: 20,
        padding: 20,
      },
    },
  },
}
const UserDetailsChart = ({ data, intl }) => (
  <Doughnut data={generateChartData(data, intl)} options={chartOptions} />
)

export default injectIntl(UserDetailsChart)
