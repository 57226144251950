import { push, replace } from 'connected-react-router'

const UPDATE_AUTH_USER = 'auth/UPDATE_AUTH_USER'
const AUTH_ERROR = 'auth/auth_error'

const SIGNIN_USER = 'auth/SIGNIN_USER'
const SIGNIN_USER_SUCCESS = 'auth/SIGNIN_USER_SUCCESS'
const SIGNIN_USER_FAILURE = 'auth/SIGNIN_USER_FAILURE '

const GET_SESSION = 'auth/GET_SESSION'
const GET_SESSION_SUCCESS = 'auth/GET_SESSION_SUCCESS'
const GET_SESSION_FAILURE = 'auth/GET_SESSION_FAILURE'

const CRED_ERROR = 'auth/CRED_ERROR'
const CRED_ERROR_RESET = 'auth/CRED_ERROR_RESET'

const SIGNOUT_USER = 'auth/SIGNOUT_USER'
const SIGNOUT_USER_SUCCESS = 'auth/SIGNOUT_USER_SUCCESS'
const SIGNOUT_USER_FAILURE = 'auth/SIGNOUT_USER_FAILURE'

const initialState = {
  authenticated: false,
  error: null,
  user: {},
  expiredSession: false,
  hasCredentials: true,
  loading: false,
  ready: false,
}

export default function authenticationReducer(state = initialState, action) {
  if (action.type.indexOf('auth/') !== 0) {
    return state
  }
  switch (action.type) {
    case GET_SESSION:
      return {
        ...state,
        loading: true,
      }
    case SIGNIN_USER:
      return {
        ...state,
        loading: true,
      }
    case GET_SESSION_FAILURE:
      return {
        ...state,
        loading: false,
        ready: true,
      }
    case GET_SESSION_SUCCESS:
    case UPDATE_AUTH_USER:
    case SIGNIN_USER_SUCCESS:
      return {
        ...state,
        authenticated: true,
        user: action.payload || action.result.data,
        error: null,
        expiredSession: false,
        loading: false,
        ready: true,
      }
    case SIGNOUT_USER_SUCCESS:
      return {
        ...state,
        authenticated: false,
        user: {},
        error: null,
        expiredSession: false,
      }
    case SIGNIN_USER_FAILURE:
    case AUTH_ERROR:
      return {
        ...state,
        authenticated: false,
        user: {},
        error: action.payload,
        expiredSession: state.authenticated ? true : false, //displayed only when user was previously authenticated
        loading: false,
      }
    case CRED_ERROR:
      return {
        ...state,
        hasCredentials: false,
      }
    case CRED_ERROR_RESET:
      return {
        ...state,
        hasCredentials: true,
      }
    default:
      return state
  }
}

const ROOT_URL = process.env.REACT_APP_API_URL

function signinUser(values) {
  const { username, password } = values
  return {
    types: [SIGNIN_USER, SIGNIN_USER_SUCCESS, SIGNIN_USER_FAILURE],
    promise: client => client.post(`${ROOT_URL}/auth/signin`, { username, password }),
    afterSuccess: dispatch => dispatch(replace('/login')),
  }
}

function signoutUser() {
  return {
    types: [SIGNOUT_USER, SIGNOUT_USER_SUCCESS, SIGNOUT_USER_FAILURE],
    promise: client => client.get(`${ROOT_URL}/auth/signout`),
    afterSuccess: dispatch => dispatch(replace('/login')),
  }
}

function validateUser() {
  return {
    types: [GET_SESSION, GET_SESSION_SUCCESS, GET_SESSION_FAILURE],
    promise: client => client.get(`${ROOT_URL}/auth/session`),
  }
}

function updateUser(data) {
  return {
    type: UPDATE_AUTH_USER,
    payload: data,
  }
}

function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error,
  }
}

function redirectToLoginWithMessage(messageKey) {
  console.debug(messageKey)
  return dispatch => dispatch(authError(messageKey))
}

function credentialsError() {
  return { type: CRED_ERROR }
}

function resetCredentialsError() {
  return dispatch => {
    dispatch({ type: CRED_ERROR_RESET })
    dispatch(push('/'))
  }
}

export const actions = {
  signinUser,
  signoutUser,
  validateUser,
  redirectToLoginWithMessage,
  credentialsError,
  resetCredentialsError,
  updateUser,
}
