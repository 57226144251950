import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { actions } from 'reducers/dashboardReducer'
import { Container, SearchBar, withPaging, withSorting } from 'components/common'
import TasksList from './MyTasksList'
import { injectIntl } from 'react-intl'

const SortingTasksList = withSorting(withPaging(TasksList))

class MyTasksPage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tasks: [],
    }
  }

  handleSearch = filter => {
    this.setState(state => ({
      ...state,
      tasks: this.props.tasks.filter(filter),
    }))
  }

  onClickRow = taskId => event => {
    event.preventDefault()
    this.props.history.push(`dashboard/tasks/${taskId}`)
  }

  componentDidMount() {
    this.props.fetchUserTasks()
  }

  componentWillReceiveProps() {
    this.setState((state, props) => {
      return {
        ...state,
        tasks: props.tasks,
      }
    })
  }

  render() {
    return (
      <Container id="my_tasks">
        <SearchBar
          onSearch={this.handleSearch}
          onFields={['title', 'status', 'reference']}
          placeholder={this.props.intl.formatMessage({
            id: 'forms.dashboard.mytasks.search.placeholder',
          })}
        />
        <SortingTasksList
          initialSort={{ property: 'duedate', type: 'date', asc: true }}
          data={this.state.tasks}
          onClickRow={this.onClickRow}
        />
      </Container>
    )
  }
}

function mapStateToProps(state) {
  return {
    tasks: state.dashboard.tasks,
  }
}

export default withRouter(connect(mapStateToProps, actions)(injectIntl(MyTasksPage)))
