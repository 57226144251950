import React from 'react'
import { Tabs, Tab } from '@material-ui/core'
import SummaryTab from './Tabs/SummaryTab'
import UncertaintiesTab from './Tabs/UncertaintiesTab'
import TasksTab from './Tabs/TasksTab'
import ContributorsTab from './Tabs/ContributorsTab'

const TrmDashboardPage = () => {
  const [tabId, setTabId] = React.useState(0)

  const handleTabChange = (evt, value) => {
    setTabId(tabId => (tabId !== value ? value : tabId))
  }

  return (
    <React.Fragment>
      <Tabs value={tabId} onChange={handleTabChange} indicatorColor="primary">
        <Tab label="Summary" />
        <Tab label="Uncertainties" />
        <Tab label="Tasks" />
        <Tab label="Contributors" />
      </Tabs>
      {tabId === 0 && <SummaryTab />}
      {tabId === 1 && <UncertaintiesTab />}
      {tabId === 2 && <TasksTab />}
      {tabId === 3 && <ContributorsTab />}
    </React.Fragment>
  )
}

export default React.memo(TrmDashboardPage)
